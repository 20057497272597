import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { FaTools, FaUsers } from 'react-icons/fa';
import { PropagateLoader } from 'react-spinners';

import BreadCrumb from '~/components/BreadCrumb';
import DefaultInput from '~/components/Inputs/Default';
import DataLoadButton from '~/components/Buttons/DataLoad';
import EditButton from '~/components/Buttons/Edit';
import Pagination from '~/components/Pagination';

import { getFormattedCnpj } from '~/utils';

import api from '~/services/api';

import { Container, FilterContent, Table, Td } from './styles';

interface ILocalClient {
  id: string;
  code?: string;
  name?: string;
  company_name?: string;
  document?: string;
  address?: string;
  city?: string;
  state?: string;
  local_representative?: {
    code: string;
    name: string;
  };
  spons_account?: {
    code: string;
    description: string;
  };
  rebate_spons_account?: {
    code: string;
    description: string;
  };
}

const AMOUNT_PER_PAGE = 5;

const List: React.FC = () => {
  const [page, setPage] = useState(0);
  const [count, setCount] = useState(0);
  const [filter, setFilter] = useState('');
  const [localClients, setLocalClients] = useState<ILocalClient[]>([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const loadLocalClients = async () => {
      await api
        .get(
          `/localclients?page=${page}&filter=${filter}&amount=${AMOUNT_PER_PAGE}`,
        )
        .then(response => {
          setLocalClients(response.data[0]);
          setCount(response.data[1]);
          setLoading(false);
        });
    };

    loadLocalClients();
  }, [page, filter]);

  return (
    <Container>
      <BreadCrumb
        main="Administração"
        path="/management/main"
        firstChild="Clientes"
        mainIcon={FaTools}
        firstChildIcon={FaUsers}
        mainColored
      />

      <div
        style={{
          display: 'flex',
          flexWrap: 'wrap',
          columnGap: '1rem',
          rowGap: '1rem',
          justifyContent: 'space-between',
          alignItems: 'end',
        }}
      >
        <FilterContent>
          <DefaultInput
            name="search"
            labelText="Pesquisar"
            type="text"
            onChange={event => {
              setFilter(event.target.value);
              setPage(0);
            }}
          />
        </FilterContent>

        <Link to="/management/localclients/dataload">
          <DataLoadButton />
        </Link>
      </div>

      <Table>
        <thead>
          <tr>
            <th style={{ width: '10%' }}>Código</th>
            <th style={{ width: '30%' }}>Nome</th>
            <th style={{ width: '15%' }}>CNPJ</th>
            <th style={{ width: '20%' }}>Cidade</th>
            <th style={{ width: '15%' }}>Estado</th>
            <th style={{ width: '10%' }} />
          </tr>
        </thead>

        <tbody>
          {loading ? (
            <tr>
              <Td colSpan={5} style={{ padding: '3rem', textAlign: 'center' }}>
                <PropagateLoader color="#276c8d" />
              </Td>
            </tr>
          ) : localClients.length > 0 ? (
            localClients.map(localClient => (
              <tr key={localClient.id}>
                <Td heading="CÓDIGO" style={{ width: '10%' }}>
                  {localClient.code}
                </Td>
                <Td heading="NOME" style={{ width: '30%' }}>
                  {localClient.company_name}
                </Td>
                <Td heading="CNPJ" style={{ width: '15%' }}>
                  {getFormattedCnpj(localClient.document || '')}
                </Td>
                <Td heading="CIDADE" style={{ width: '20%' }}>
                  {localClient.city}
                </Td>
                <Td heading="ESTADO" style={{ width: '15%' }}>
                  {localClient.state}
                </Td>
                <Td>
                  <Link
                    to={{
                      pathname: `/management/localclients/edit/${localClient.id}`,
                      state: localClient,
                    }}
                  >
                    <EditButton />
                  </Link>
                </Td>
              </tr>
            ))
          ) : (
            <tr>
              <Td colSpan={5} style={{ textAlign: 'center' }}>
                Nenhuma informação disponível
              </Td>
            </tr>
          )}
        </tbody>
      </Table>

      <div style={{ display: 'flex', marginTop: '2rem' }}>
        <Pagination
          page={page}
          count={count}
          amountPerPage={AMOUNT_PER_PAGE}
          setPage={setPage}
        />
      </div>
    </Container>
  );
};

export default List;
