import React, { useCallback, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { FaProjectDiagram, FaListAlt } from 'react-icons/fa';
import { v4 as uuidv4 } from 'uuid';

import BreadCrumb from '~/components/BreadCrumb';
import ProjectInfo from '~/components/ProjectInfo/Default';
import TextArea from '~/components/TextArea';
import UploadFiles from '~/components/UploadFiles';
import SaveButton from '~/components/Buttons/Save';
import ExitButton from '~/components/Buttons/Exit';
import ConfirmProcedureDialog from '~/components/Dialogs/ConfirmProcedure';
import ConfirmExitDialog from '~/components/Dialogs/ConfirmExit';

import { useAuth } from '~/hooks/auth';
import { useToast } from '~/hooks/toast';

import api from '~/services/api';

import { Container, Content, Wrapper, ButtonContainer } from './styles';

interface IFormProcedure2 {
  type: string;
  code: string;
  validation_type: string;
  project_procedure_id: string;
  record_description: string;
  record_note: string;
  project_temp_id: string;
}

interface ILocation {
  id: string;
  type: string;
  code: string;
  erp_rep_code: string;
  status: string;
  project_procedure_id: string;
}

const Procedure2: React.FC = () => {
  const history = useHistory();
  const { user } = useAuth();
  const { addToast } = useToast();
  const { state } = useLocation<ILocation>();
  const [projectTempFilled, setProjectTempFilled] = useState(false);
  const [projectTemp, setProjectTemp] = useState('');
  const [isOpenConfirmProcedure, setIsOpenConfirmProcedure] = useState(false);
  const [titleConfirmProcedure, setTitleConfirmProcedure] = useState('');
  const [isOpenExit, setIsOpenExit] = useState(false);
  const [isSaving, setIsSaving] = useState(false);

  const { handleSubmit, register, setValue, errors } =
    useForm<IFormProcedure2>();

  useEffect(() => {
    if (state === undefined) {
      addToast({
        type: 'error',
        title: 'Operação inválida!',
        description: 'Projeto não localizado',
      });

      history.push('/projects/adversities');
    } else if (state.status.toUpperCase() === 'CANCELADO') {
      addToast({
        type: 'error',
        title: 'Operação inválida!',
        description: 'Projeto Cancelado',
      });

      history.push('/projects/adversities');
    } else if (state.status.toUpperCase() === 'FINALIZADO') {
      addToast({
        type: 'error',
        title: 'Operação inválida!',
        description: 'Projeto Finalizado',
      });

      history.push('/projects/adversities');
    } else {
      register('validation_type');
      register('project_temp_id');
      register('type');
      register('code');
      register('record_description');
      register('project_procedure_id');

      setValue('type', 'ADVERSIDADE');
      setValue('code', state.code);
      setValue('project_procedure_id', state.project_procedure_id);

      if (!projectTempFilled) {
        setProjectTempFilled(true);
        setProjectTemp(uuidv4());
      }
    }
  }, [addToast, history, state, user, projectTempFilled, register, setValue]);

  useEffect(() => {
    setValue('project_temp_id', projectTemp);
  }, [projectTemp, setValue]);

  const submitForm = useCallback(
    async (data: IFormProcedure2) => {
      try {
        setIsSaving(true);

        await api.put(`/projects/${state.id}`, data);

        addToast({
          type: 'success',
          title: 'Projeto atualizado!',
          description: 'Projeto atualizado com sucesso.',
        });

        history.push('/projects/adversities');
      } catch (error: any) {
        setIsSaving(false);

        addToast({
          type: 'error',
          title: 'Ocorreu um erro!',
          description: error.response.data.message,
        });
      }
    },
    [addToast, history, state],
  );

  function onConfirmProcedure(): void {
    setValue('validation_type', 'APROVADO');
    setValue('record_description', `Finalização do projeto`);

    handleSubmit(submitForm)();
  }

  const onExit = useCallback(() => {
    api.delete(`projectfilesreceived/projecttempid/${projectTemp}`);

    history.push('/projects/adversities');
  }, [history, projectTemp]);

  return (
    <Container>
      <BreadCrumb
        main="Solicitações"
        path="/projects/main"
        firstChildPath="/projects/adversities"
        firstChild="Problemas"
        secondChild="Atualização Reporte"
        mainIcon={FaProjectDiagram}
        firstChildIcon={FaListAlt}
        secondChildIcon={FaListAlt}
        mainColored
        firstChildColored
      />

      <ProjectInfo project_id={state.id} />

      <form onSubmit={handleSubmit(submitForm)}>
        <Content>
          <Wrapper>
            <TextArea
              name="record_note"
              labelFor="record_note"
              labelText="Observação"
              error={errors?.record_note?.message}
              ref={register}
              maxLength={1000}
            />
          </Wrapper>
        </Content>

        <Content>
          <UploadFiles
            type="anexo"
            project_temp_id={projectTemp}
            project_type="ADVERSIDADE"
            sequence={2}
            user_id={user.id}
          />
        </Content>

        <ButtonContainer>
          <SaveButton
            label="Finalizar"
            type="button"
            processing={isSaving}
            disabled={isSaving}
            onClick={() => {
              setIsOpenConfirmProcedure(true);
              setTitleConfirmProcedure(
                `Confirma finalização do projeto ${state.code}?`,
              );
            }}
          />

          <ExitButton
            style={{ marginLeft: 10 }}
            type="button"
            disabled={isSaving}
            onClick={() => setIsOpenExit(true)}
          />
        </ButtonContainer>
      </form>

      <ConfirmProcedureDialog
        isOpen={isOpenConfirmProcedure}
        setIsOpen={setIsOpenConfirmProcedure}
        onConfirm={onConfirmProcedure}
        title={titleConfirmProcedure}
        subTitle="O projeto será finalizado"
        opConfirm="Sim"
      />

      <ConfirmExitDialog
        isOpen={isOpenExit}
        setIsOpen={setIsOpenExit}
        onExit={onExit}
      />
    </Container>
  );
};

export default Procedure2;
