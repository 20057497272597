import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

import { useAuth } from '~/hooks/auth';

import api from '~/services/api';

import {
  AnimatedContainer,
  AnimatedCard,
  Navigation,
  AnimatedCardIcon,
  AnimatedCardWrapper,
  AnimatedCardAmount,
  AnimatedCardInfo,
} from './styles';
import { CONTAINER_ANIMATION, CARDS_ANIMATION } from './animation';

import { DEFAULT_TRANSITION, CARDS_NAVIGATION } from '~/constants';

const NavigationCards: React.FC = () => {
  const { user } = useAuth();
  const [projects, setProjects] = useState<{ type: string }[]>([]);

  useEffect(() => {
    api.get(`/projects/usertypependingprojects`).then(response => {
      setProjects(response.data);
    });
  }, [user]);

  return (
    <AnimatedContainer variants={CONTAINER_ANIMATION}>
      <Navigation>
        {Object.entries(CARDS_NAVIGATION).map(([key, value]) => (
          <Link key={`link-${key}`} to={value.link}>
            <AnimatedCard
              key={`card-${key}`}
              variants={CARDS_ANIMATION}
              transition={DEFAULT_TRANSITION}
              whileHover={{ y: -2, transition: DEFAULT_TRANSITION }}
              whileTap={{ y: 2, transition: DEFAULT_TRANSITION }}
            >
              <AnimatedCardIcon>{value.icon}</AnimatedCardIcon>
              <AnimatedCardWrapper>
                <AnimatedCardAmount>
                  {
                    projects.filter(project => project.type === value.type)
                      .length
                  }
                </AnimatedCardAmount>
                <AnimatedCardInfo>{key}</AnimatedCardInfo>
              </AnimatedCardWrapper>
            </AnimatedCard>
          </Link>
        ))}
      </Navigation>
    </AnimatedContainer>
  );
};

export default NavigationCards;
