import React, { useState, useEffect, useCallback } from 'react';
import { Link } from 'react-router-dom';
import { FcCheckmark, FcCancel } from 'react-icons/fc';
import { FaBoxes, FaListAlt } from 'react-icons/fa';
import { PropagateLoader } from 'react-spinners';

import BreadCrumb from '~/components/BreadCrumb';
import DefaultInput from '~/components/Inputs/Default';
import EditButton from '~/components/Buttons/Edit';
import DeleteButton from '~/components/Buttons/Delete';
import Pagination from '~/components/Pagination';
import NewButton from '~/components/Buttons/New';
import ConfirmDeleteDialog from '~/components/Dialogs/ConfirmDelete';

import { useToast } from '~/hooks/toast';

import api from '~/services/api';

import {
  Container,
  FilterContent,
  Table,
  Thead,
  Tbody,
  Th,
  TbodyTr,
  Td,
  ButtonsContent,
} from './styles';

interface IAdversity {
  id: string;
  name: string;
  active: boolean;
}

const AMOUNT_PER_PAGE = 5;

const List: React.FC = () => {
  const { addToast } = useToast();
  const [page, setPage] = useState(0);
  const [count, setCount] = useState(0);
  const [filter, setFilter] = useState('');
  const [lastRefresh, setLastRefresh] = useState(new Date());
  const [adversities, setAdversities] = useState<IAdversity[]>();
  const [loading, setLoading] = useState<boolean>(true);
  const [adversityIdDelete, setAdversityIdDelete] = useState('');
  const [isOpenDelete, setIsOpenDelete] = useState(false);
  const [titleDelete, setTitleDelete] = useState('');
  const [subTitleDelete, setSubTitleDelete] = useState('');

  useEffect(() => {
    const loadAdversities = async () => {
      await api
        .get(
          `/items/adversities?page=${page}&filter=${filter}&amount=${AMOUNT_PER_PAGE}`,
        )
        .then(response => {
          setAdversities(response.data[0]);
          setCount(response.data[1]);
          setLoading(false);
        });
    };

    loadAdversities();
  }, [page, filter, lastRefresh]);

  const onDelete = useCallback(
    async (id: string) => {
      try {
        await api.delete(`/items/adversities/${id}`).then(() => {
          setPage(0);
          setLastRefresh(new Date());

          addToast({
            type: 'success',
            title: 'Adversidade excluída!',
            description: 'Adversidade excluída com sucesso.',
          });
        });
      } catch (error: any) {
        addToast({
          type: 'error',
          title: 'Ocorreu um erro!',
          description: error.response.data.message,
        });
      }
      setAdversityIdDelete('');
      setTitleDelete('');
      setSubTitleDelete('');
    },
    [addToast],
  );

  function handleTable(): React.ReactNode {
    if (adversities) {
      if (adversities.length > 0) {
        return (
          <Table>
            <Thead>
              <tr>
                <Th width="60%" textAlign="left">
                  Nome
                </Th>
                <Th width="20%" textAlign="center">
                  Situação
                </Th>
                <Th width="20%" textAlign="center" />
              </tr>
            </Thead>
            <Tbody>
              {loading && (
                <tr>
                  <Td colSpan={4} padding="1rem" textAlign="center">
                    <PropagateLoader color="#276c8d" />
                  </Td>
                </tr>
              )}
              {adversities?.map(adversity => (
                <TbodyTr key={adversity.id}>
                  <Td width="60%" textAlign="left" heading="NOME">
                    {adversity.name}
                  </Td>
                  <Td width="20%" textAlign="center" heading="SITUAÇÃO">
                    {adversity.active ? (
                      <FcCheckmark size={22} />
                    ) : (
                      <FcCancel size={22} />
                    )}
                  </Td>
                  <Td
                    width="20%"
                    textAlign="center"
                    display="flex"
                    heading={' '}
                  >
                    <Link
                      to={{
                        pathname: `/items/adversities/edit/${adversity.id}`,
                        state: adversity,
                      }}
                    >
                      <EditButton />
                    </Link>

                    <DeleteButton
                      style={{ marginLeft: '0.5rem' }}
                      onClick={() => {
                        setAdversityIdDelete(adversity.id);
                        setIsOpenDelete(true);
                        setTitleDelete(
                          `Deseja realmente excluir o item ${adversity.name}?`,
                        );
                        setSubTitleDelete(
                          'Esta operação não poderá ser desfeita!',
                        );
                      }}
                    />
                  </Td>
                </TbodyTr>
              ))}
            </Tbody>
          </Table>
        );
      }
    }

    return (
      <Table>
        <Thead>
          <tr>
            <Th width="100%" textAlign="center" />
          </tr>
        </Thead>
        <Tbody>
          <TbodyTr>
            <Td width="100%" textAlign="center">
              Nenhuma informação disponível
            </Td>
          </TbodyTr>
        </Tbody>
      </Table>
    );
  }

  return (
    <Container style={{ minHeight: 600 }}>
      <BreadCrumb
        main="Itens"
        path="/items/main"
        firstChild="Problemas"
        mainIcon={FaBoxes}
        firstChildIcon={FaListAlt}
        mainColored
      />

      <FilterContent>
        <DefaultInput
          name="search"
          labelText="Pesquisar"
          type="text"
          onChange={event => {
            setFilter(event.target.value);
            setPage(0);
          }}
        />
      </FilterContent>

      {handleTable()}

      <ButtonsContent>
        <Pagination
          page={page}
          count={count}
          amountPerPage={AMOUNT_PER_PAGE}
          setPage={setPage}
        />

        <Link to="/items/adversities/new">
          <NewButton />
        </Link>
      </ButtonsContent>

      <ConfirmDeleteDialog
        isOpen={isOpenDelete}
        title={titleDelete}
        subTitle={subTitleDelete}
        setIsOpen={setIsOpenDelete}
        onClose={() => {
          onDelete(adversityIdDelete);
        }}
      />
    </Container>
  );
};

export default List;
