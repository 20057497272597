import React from 'react';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';

import { FaAngleDown } from 'react-icons/fa';

import InfoInput from '~/components/Inputs/Info';
import { MainContainer, Label, Container, Wrapper } from './styles';

interface IEventInfoProps {
  event_date?: Date;
  estimated_event_participants_number?: number;
  total_event_costs_price?: number;
  price_req_by_client?: number;
  generates_sponsorship?: boolean;
}

const EventInfo: React.FC<IEventInfoProps> = ({
  event_date,
  estimated_event_participants_number,
  total_event_costs_price,
  price_req_by_client,
  generates_sponsorship,
}) => {
  return (
    <MainContainer>
      <Accordion>
        <AccordionSummary expandIcon={<FaAngleDown />}>
          <Label>Informações do Evento</Label>
        </AccordionSummary>

        <AccordionDetails>
          <Container>
            <div
              style={{ display: 'flex', flexWrap: 'wrap', gap: '2rem 1rem' }}
            >
              <Wrapper style={{ width: 180 }}>
                <InfoInput
                  name="event_date"
                  labelFor="event_date"
                  labelText="Data Evento"
                  type="text"
                  value={
                    event_date !== undefined
                      ? new Date(event_date).toLocaleDateString()
                      : 'Indefinido'
                  }
                  disabled
                />
              </Wrapper>

              <Wrapper style={{ width: 200 }}>
                <InfoInput
                  name="estimated_event_participants_number"
                  labelFor="estimated_event_participants_number"
                  labelText="Número Est. Participantes"
                  type="text"
                  defaultValue={
                    estimated_event_participants_number &&
                    new Intl.NumberFormat('pt-BR').format(
                      estimated_event_participants_number,
                    )
                  }
                  disabled
                />
              </Wrapper>

              <Wrapper style={{ width: 200 }}>
                <InfoInput
                  name="total_event_costs_price"
                  labelFor="total_event_costs_price"
                  labelText="Valor Custos"
                  type="text"
                  defaultValue={
                    total_event_costs_price &&
                    new Intl.NumberFormat('pt-BR', {
                      style: 'currency',
                      currency: 'BRL',
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    }).format(total_event_costs_price)
                  }
                  disabled
                />
              </Wrapper>

              <Wrapper style={{ width: 200 }}>
                <InfoInput
                  name="price_req_by_client"
                  labelFor="price_req_by_client"
                  labelText="Cota Solicitada Pelo Cliente"
                  type="text"
                  defaultValue={
                    price_req_by_client &&
                    new Intl.NumberFormat('pt-BR', {
                      style: 'currency',
                      currency: 'BRL',
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    }).format(price_req_by_client)
                  }
                  disabled
                />
              </Wrapper>

              <Wrapper style={{ width: 220 }}>
                <InfoInput
                  name="generates_sponsorship"
                  labelFor="generates_sponsorship"
                  labelText="Gera Solicitação de Pagamento?"
                  type="text"
                  defaultValue={generates_sponsorship ? 'Sim' : 'Não'}
                  disabled
                />
              </Wrapper>
            </div>
          </Container>
        </AccordionDetails>
      </Accordion>
    </MainContainer>
  );
};

export default EventInfo;
