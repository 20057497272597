import React, { useState, useEffect, useCallback } from 'react';
import { Link } from 'react-router-dom';
import { FcCheckmark, FcCancel } from 'react-icons/fc';
import { FaCogs, FaTruck } from 'react-icons/fa';
import { PropagateLoader } from 'react-spinners';

import BreadCrumb from '~/components/BreadCrumb';
import DefaultInput from '~/components/Inputs/Default';
import EditButton from '~/components/Buttons/Edit';
import DeleteButton from '~/components/Buttons/Delete';
import Pagination from '~/components/Pagination';
import NewButton from '~/components/Buttons/New';
import ConfirmDeleteDialog from '~/components/Dialogs/ConfirmDelete';

import { useToast } from '~/hooks/toast';

import api from '~/services/api';

import {
  Container,
  FilterContent,
  Table,
  Thead,
  Tbody,
  Th,
  TbodyTr,
  Td,
  ButtonsContent,
} from './styles';

interface IThirdPartyLogisticsProvider {
  id: string;
  name: string;
  email: string;
  active: boolean;
  send_quotation: boolean;
}

const AMOUNT_PER_PAGE = 5;

const List: React.FC = () => {
  const { addToast } = useToast();
  const [page, setPage] = useState(0);
  const [count, setCount] = useState(0);
  const [filter, setFilter] = useState('');
  const [lastRefresh, setLastRefresh] = useState(new Date());
  const [thirdPartyLogisticsProviders, setThirdPartyLogisticsProviders] =
    useState<IThirdPartyLogisticsProvider[]>();
  const [loading, setLoading] = useState<boolean>(true);
  const [
    thirdPartyLogisticsProviderIdDelete,
    setThirdPartyLogisticsProviderIdDelete,
  ] = useState('');
  const [isOpenDelete, setIsOpenDelete] = useState(false);
  const [titleDelete, setTitleDelete] = useState('');
  const [subTitleDelete, setSubTitleDelete] = useState('');

  useEffect(() => {
    const loadThirdPartyLogisticsProviders = async () => {
      await api
        .get(
          `/configurations/thirdpartylogisticsproviders?page=${page}&filter=${filter}&amount=${AMOUNT_PER_PAGE}`,
        )
        .then(response => {
          setThirdPartyLogisticsProviders(response.data[0]);
          setCount(response.data[1]);
          setLoading(false);
        });
    };

    loadThirdPartyLogisticsProviders();
  }, [page, filter, lastRefresh]);

  const onDelete = useCallback(
    async (id: string) => {
      try {
        await api
          .delete(`/configurations/thirdpartylogisticsproviders/${id}`)
          .then(() => {
            setPage(0);
            setLastRefresh(new Date());

            addToast({
              type: 'success',
              title: 'Operador logístico excluído!',
              description: 'Operador logístico excluído com sucesso.',
            });
          });
      } catch (error: any) {
        addToast({
          type: 'error',
          title: 'Ocorreu um erro!',
          description: error.response.data.message,
        });
      }
      setThirdPartyLogisticsProviderIdDelete('');
      setTitleDelete('');
      setSubTitleDelete('');
    },
    [addToast],
  );

  function handleTable(): React.ReactNode {
    if (thirdPartyLogisticsProviders) {
      if (thirdPartyLogisticsProviders.length > 0) {
        return (
          <Table>
            <Thead>
              <tr>
                <Th width="35%" textAlign="left">
                  Nome
                </Th>
                <Th width="35%" textAlign="left">
                  E-mail
                </Th>
                <Th width="10%" textAlign="center">
                  Situação
                </Th>
                <Th width="10%" textAlign="center">
                  Env. Cotação
                </Th>
                <Th width="10%" textAlign="center" />
              </tr>
            </Thead>
            <Tbody>
              {loading && (
                <tr>
                  <Td colSpan={4} padding="1rem" textAlign="center">
                    <PropagateLoader color="#276c8d" />
                  </Td>
                </tr>
              )}
              {thirdPartyLogisticsProviders?.map(
                thirdPartyLogisticsProvider => (
                  <TbodyTr key={thirdPartyLogisticsProvider.id}>
                    <Td width="35%" textAlign="left" heading="NOME">
                      {thirdPartyLogisticsProvider.name}
                    </Td>
                    <Td width="35%" textAlign="left" heading="E-MAIL">
                      {thirdPartyLogisticsProvider.email}
                    </Td>
                    <Td width="10%" textAlign="center" heading="SITUAÇÃO">
                      {thirdPartyLogisticsProvider.active ? (
                        <FcCheckmark size={22} />
                      ) : (
                        <FcCancel size={22} />
                      )}
                    </Td>
                    <Td width="10%" textAlign="center" heading="ENV. COTAÇÃO">
                      {thirdPartyLogisticsProvider.send_quotation ? (
                        <FcCheckmark size={22} />
                      ) : (
                        <FcCancel size={22} />
                      )}
                    </Td>
                    <Td
                      width="10%"
                      textAlign="center"
                      display="flex"
                      heading={' '}
                    >
                      <Link
                        to={{
                          pathname: `/configurations/thirdpartylogisticsproviders/edit/${thirdPartyLogisticsProvider.id}`,
                          state: thirdPartyLogisticsProvider,
                        }}
                      >
                        <EditButton />
                      </Link>

                      <DeleteButton
                        marginLeft="0.5rem"
                        onClick={() => {
                          setThirdPartyLogisticsProviderIdDelete(
                            thirdPartyLogisticsProvider.id,
                          );
                          setIsOpenDelete(true);
                          setTitleDelete(
                            `Deseja realmente excluir o operador ` +
                              `logístico ${thirdPartyLogisticsProvider.name}?`,
                          );
                          setSubTitleDelete(
                            'Esta operação não poderá ser desfeita!',
                          );
                        }}
                      />
                    </Td>
                  </TbodyTr>
                ),
              )}
            </Tbody>
          </Table>
        );
      }
    }

    return (
      <Table>
        <Thead>
          <tr>
            <Th width="100%" textAlign="center" />
          </tr>
        </Thead>
        <Tbody>
          <TbodyTr>
            <Td width="100%" textAlign="center">
              Nenhuma informação disponível
            </Td>
          </TbodyTr>
        </Tbody>
      </Table>
    );
  }

  return (
    <Container style={{ minHeight: 600 }}>
      <BreadCrumb
        main="Configurações"
        path="/configurations/main"
        firstChild="Operadores Logísticos"
        mainIcon={FaCogs}
        firstChildIcon={FaTruck}
        mainColored
      />

      <FilterContent>
        <DefaultInput
          name="search"
          labelText="Pesquisar"
          type="text"
          onChange={event => {
            setFilter(event.target.value);
            setPage(0);
          }}
        />
      </FilterContent>

      {handleTable()}

      <ButtonsContent>
        <Pagination
          page={page}
          count={count}
          amountPerPage={AMOUNT_PER_PAGE}
          setPage={setPage}
        />

        <Link to="/configurations/thirdpartylogisticsproviders/new">
          <NewButton />
        </Link>
      </ButtonsContent>

      <ConfirmDeleteDialog
        isOpen={isOpenDelete}
        title={titleDelete}
        subTitle={subTitleDelete}
        setIsOpen={setIsOpenDelete}
        onClose={() => {
          onDelete(thirdPartyLogisticsProviderIdDelete);
        }}
      />
    </Container>
  );
};

export default List;
