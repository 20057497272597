import React, { ButtonHTMLAttributes } from 'react';

import { Container } from './styles';

interface IOptionProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  label: string;
  isActive: boolean;
}

const Option: React.FC<IOptionProps> = ({ label, isActive, ...rest }) => {
  return (
    <Container isActive={isActive} {...rest}>
      {label}
    </Container>
  );
};

export default Option;
