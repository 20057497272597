interface IUser {
  permissions: {
    feature: {
      name: string;
    };
  }[];
}

export default function getPermByPrefix(prefix: string, user: IUser): boolean {
  return user.permissions.some(p => p.feature.name.startsWith(prefix));
}
