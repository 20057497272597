import React from 'react';
import { FiBarChart2 } from 'react-icons/fi';

import { getPermByPrefix } from '~/utils';

import { useAuth } from '~/hooks/auth';

import Accordion from './Accordion';
import AccountProjects from './AccountProjects';

import { DEFAULT_TRANSITION, SIDE_BAR_NAVIGATION } from '~/constants';

import { AnimatedContainer, PendingInfo, Wrapper } from './styles';

const animation = {
  unMounted: { opacity: 0, y: -50 },
  mounted: {
    opacity: 1,
    y: 0,
    transition: { delay: 0.5, ...DEFAULT_TRANSITION },
  },
};

const SideBar: React.FC = () => {
  const { user } = useAuth();

  return (
    <Wrapper>
      <AnimatedContainer variants={animation}>
        <AccountProjects />

        <PendingInfo>
          Todos Pendentes Comigo
          <FiBarChart2 size={16} />
        </PendingInfo>

        {Object.entries(SIDE_BAR_NAVIGATION).map(
          ([key, value]) =>
            (key === 'Início' || getPermByPrefix(value.prefix, user)) && (
              <Accordion
                key={key}
                icon={value.icon}
                link={value.link}
                sectionName={key}
              />
            ),
        )}
      </AnimatedContainer>
    </Wrapper>
  );
};

export default SideBar;
