import styled, { css } from 'styled-components';
import Switch from 'react-switch';

export const Container = styled.div`
  min-width: 10rem;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const Label = styled.label`
  ${({ theme }) => css`
    font-size: ${theme.fontSizes.label};
    color: ${theme.colors.labelInput};
    margin-bottom: 1rem;
  `}
`;

export const ToggleButton = styled(Switch)`
  ${({ theme }) => css`
    border: 0;
    border-radius: ${theme.radius.small};
  `};
`;
