import React from 'react';

import Sponsorship06 from './Types/Sponsorships/06';

interface ISpecificProps {
  project_id: string;
  type: string;
}

const Specific: React.FC<ISpecificProps> = ({ project_id, type }) => {
  function handleType(): React.ReactNode | null {
    if (type === 'sponsorship_06') {
      return <Sponsorship06 project_id={project_id} />;
    }

    return null;
  }

  return <>{handleType()}</>;
};

export default Specific;
