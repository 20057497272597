import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import moment from 'moment';
import { FaProjectDiagram, FaBoxOpen } from 'react-icons/fa';
import { v4 as uuidv4 } from 'uuid';

import BreadCrumb from '~/components/BreadCrumb';
import ProjectInfo from '~/components/ProjectInfo/Default';
import TableDefaultInput from '~/components/TableInputs/Default';
import TableDateInput from '~/components/TableInputs/Date';
import TableCurrencyInput from '~/components/TableInputs/Currency';
import TableWholeNumberInput from '~/components/TableInputs/WholeNumber';
import TableSelect from '~/components/TableSelect';
import LockButton from '~/components/Buttons/Lock';
import UnlockButton from '~/components/Buttons/Unlock';
import AddRowButton from '~/components/Buttons/AddRow';
import DeleteRowButton from '~/components/Buttons/DeleteRow';
import DeletePreviousButton from '~/components/Buttons/DeletePrevious';
import TextArea from '~/components/TextArea';
import UploadFiles from '~/components/UploadFiles';
import SaveButton from '~/components/Buttons/Save';
import ExitButton from '~/components/Buttons/Exit';
import ConfirmProcedureDialog from '~/components/Dialogs/ConfirmProcedure';
import ConfirmExitDialog from '~/components/Dialogs/ConfirmExit';
import DeleteModal from '~/components/Modals/Delete';
import NoticeDialog from '~/components/Dialogs/Notice';

import { getCurrencyFormatValue, getNumberFormatValue } from '~/utils';

import { useAuth } from '~/hooks/auth';
import { useToast } from '~/hooks/toast';

import api from '~/services/api';

import {
  Container,
  Content,
  ContentAddRow,
  Wrapper,
  LabelContainer,
  Label,
  ItemContainer,
  ItemSequence,
  ItemSequenceLabel,
  ItemContent,
  ItemWrapper,
  ButtonContainer,
} from './styles';

interface IProjectSentInvoice {
  id: string;
  number: string;
  series: string;
  issue_date: Date;
  net_price: number;
  amount: number;
  sequence: number;
  sample_id?: string;
}

interface INewProjectSentInvoice extends IProjectSentInvoice {
  sample: {
    value: string;
  };
  project_record_reason: string;
  ext_used_invoice_error: string | undefined;
  internal_invoice_conf_error: string | undefined;
  issue_date_error: string | undefined;
  amount_error: string | undefined;
  previous: boolean;
  fields_enabled: boolean;
  deleted: boolean;
}

interface IProjectLabel {
  control: string;
  packaging: string;
  city: string;
  state: string;
  invoice_series: string;
  invoice_number: string;
  invoice_issue_date: Date;
  invoice_net_price: number;
  document: string;
  item_erp_code: string;
  batch: string;
  sequence: number;
}

interface INewProjectLabel extends IProjectLabel {
  ext_used_invoice_error: string | undefined;
  invoice_issue_date_error: string | undefined;
  conflict_error: string | undefined;
}

interface IProjectSample {
  id: string;
  total_amount: number;
  pending_amount: number;
  sent_amount: number;
  sample: {
    id: string;
    erp_code?: string;
    name: string;
  };
}

interface IFormProcedure3 {
  record_note: string;
}

interface ILocation {
  id: string;
  type: string;
  code: string;
  price: number;
  erp_rep_code: string;
  status: string;
  project_procedure_id: string;
  local_client: {
    code: string;
    name: string;
    document: string;
    city: string;
    state: string;
  };
}

const Procedure3: React.FC = () => {
  const history = useHistory();
  const { user } = useAuth();
  const { addToast } = useToast();
  const { state } = useLocation<ILocation>();
  const projectTemp = useRef(uuidv4());
  const [projectSentInvoices, setProjectSentInvoices] = useState<
    INewProjectSentInvoice[]
  >([]);
  const [previousProjectLabels, setPreviousProjectLabels] = useState<
    IProjectLabel[]
  >([]);
  const [projectLabels, setProjectLabels] = useState<INewProjectLabel[]>([]);
  const projectSamples = useRef<IProjectSample[]>([]);
  const [sampleOptions, setSampleOptions] = useState<IProjectSample[]>([]);
  const sentInvoicesTriggeredByEffect = useRef(false);
  const labelsTriggeredByEffect = useRef(false);
  const [isOpenConfirmProcedure, setIsOpenConfirmProcedure] = useState(false);
  const [procConfirmation, setProcConfirmation] = useState<{
    type: string;
    title: string;
  }>({
    type: '',
    title: '',
  });
  const [isOpenExit, setIsOpenExit] = useState(false);
  const [notice, setNotice] = useState<{
    title: string;
    subtitle: string;
  }>({
    title: '',
    subtitle: '',
  });
  const [isOpenNotice, setIsOpenNotice] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [sentInvoiceToDelete, setSentInvoiceToDelete] = useState<{
    open: boolean;
    title: string;
    sequence: number;
    reason: string;
  }>({
    open: false,
    title: '',
    sequence: 0,
    reason: '',
  });

  const { handleSubmit, register, errors } = useForm<IFormProcedure3>();

  useEffect(() => {
    if (state === undefined) {
      addToast({
        type: 'error',
        title: 'Operação inválida!',
        description: 'Solicitação não localizada',
      });

      history.push('/projects/samples');
    } else if (state.status.toUpperCase() === 'CANCELADO') {
      addToast({
        type: 'error',
        title: 'Operação inválida!',
        description: 'Solicitação Cancelada',
      });

      history.push('/projects/samples');
    } else if (state.status.toUpperCase() === 'FINALIZADO') {
      addToast({
        type: 'error',
        title: 'Operação inválida!',
        description: 'Projeto Finalizado',
      });

      history.push('/projects/samples');
    } else {
      api
        .get(`/projectsamples/allbyproject?project_id=${state.id}`)
        .then(response => {
          projectSamples.current = response.data;
          setSampleOptions(response.data);
        });

      api
        .get(`/projectsentinvoices/projectall?project_id=${state.id}`)
        .then(response => {
          const prevProjectSentInvoices: IProjectSentInvoice[] = response.data;

          if (prevProjectSentInvoices.length > 0) {
            setProjectSentInvoices(
              prevProjectSentInvoices
                .map(prevProjSentInv => ({
                  id: prevProjSentInv.id,
                  number: prevProjSentInv.number,
                  series: prevProjSentInv.series,
                  issue_date: new Date(prevProjSentInv.issue_date),
                  net_price: prevProjSentInv.net_price,
                  amount: prevProjSentInv.amount,
                  sequence: prevProjSentInv.sequence,
                  sample: {
                    value: prevProjSentInv.sample_id || '',
                  },
                  project_record_reason: '',
                  ext_used_invoice_error: undefined,
                  internal_invoice_conf_error: undefined,
                  issue_date_error: undefined,
                  amount_error: undefined,
                  previous: true,
                  fields_enabled: false,
                  deleted: false,
                }))
                .sort((a, b) => a.sequence - b.sequence),
            );
          } else {
            setProjectSentInvoices([
              {
                id: '',
                number: '',
                series: '',
                issue_date: new Date(),
                net_price: 0,
                amount: 0,
                sequence: 1,
                sample: {
                  value: '',
                },
                project_record_reason: '',
                ext_used_invoice_error: undefined,
                internal_invoice_conf_error: undefined,
                issue_date_error: undefined,
                amount_error: undefined,
                previous: false,
                fields_enabled: true,
                deleted: false,
              },
            ]);
          }
        });

      api
        .get(`/projectlabels/allbyproject?project_id=${state.id}`)
        .then(response => {
          setPreviousProjectLabels(response.data);

          setProjectLabels([
            {
              control: '',
              packaging: '',
              city: state.local_client.city,
              state: state.local_client.state,
              invoice_series: '',
              invoice_number: '',
              invoice_issue_date: new Date(new Date().setHours(0, 0, 0, 0)),
              invoice_net_price: 0,
              document: state.local_client.document,
              item_erp_code: '',
              batch: '',
              sequence:
                response.data && response.data.length > 0
                  ? response.data[response.data.length - 1].sequence + 1
                  : 1,
              ext_used_invoice_error: undefined,
              invoice_issue_date_error: undefined,
              conflict_error: undefined,
            },
          ]);
        });
    }
  }, [addToast, history, state, user]);

  const submitForm = useCallback(
    async (data: IFormProcedure3) => {
      try {
        setIsSaving(true);

        await api.put(`/projects/${state.id}`, {
          ...data,
          code: state.code,
          project_labels: projectLabels,
          project_procedure_id: state.project_procedure_id,
          project_samples: projectSamples.current,
          project_sent_invoices: projectSentInvoices,
          project_temp_id: projectTemp.current,
          record_description:
            projectSamples.current.filter(
              projSample => projSample.pending_amount > 0,
            ).length > 0
              ? 'Atualização da solicitação'
              : 'Finalização da solicitação',
          type: 'AMOSTRA',
          validation_type: 'APROVADO',
        });

        addToast({
          type: 'success',
          title: 'Solicitação atualizada!',
          description: 'Solicitação atualizada com sucesso.',
        });

        history.push('/projects/samples');
      } catch (error: any) {
        setIsSaving(false);

        addToast({
          type: 'error',
          title: 'Ocorreu um erro!',
          description: error.response.data.message,
        });
      }
    },
    [
      addToast,
      history,
      projectLabels,
      projectSamples,
      projectSentInvoices,
      projectTemp,
      state,
    ],
  );

  async function onValidateConfirmProcedure(): Promise<void> {
    if (projectSentInvoices.length <= 0) {
      setNotice({
        title: 'Notas fiscais inválidas!',
        subtitle: 'Necessário informar notas fiscais!',
      });
      setIsOpenNotice(true);
    } else if (
      projectSentInvoices.filter(
        projSentInvoice => projSentInvoice.sample.value === '',
      ).length > 0
    ) {
      setNotice({
        title: 'Itens inválidos em notas fiscais!',
        subtitle: 'Necessário selecionar item em notas fiscais!',
      });
      setIsOpenNotice(true);
    } else if (
      projectSentInvoices.filter(
        projSentInvoice => projSentInvoice.number === '',
      ).length > 0
    ) {
      setNotice({
        title: 'Existem notas fiscais sem número!',
        subtitle:
          'Não é possível enviar solicitação com notas fiscais sem número!',
      });
      setIsOpenNotice(true);
    } else if (
      projectSentInvoices.filter(
        projSentInvoice =>
          projSentInvoice.ext_used_invoice_error !== undefined ||
          projSentInvoice.internal_invoice_conf_error !== undefined,
      ).length > 0
    ) {
      setNotice({
        title: 'Existem notas fiscais inválidas!',
        subtitle:
          'Não é possível enviar solicitação com notas fiscais inválidas!',
      });
      setIsOpenNotice(true);
    } else if (
      projectSentInvoices.filter(
        projSentInvoice => projSentInvoice.issue_date_error !== undefined,
      ).length > 0
    ) {
      setNotice({
        title: 'Datas de emissão inválidas em notas fiscais!',
        subtitle:
          'Não é possível enviar solicitação com notas ' +
          'fiscais possuindo datas de emissão inválidas!',
      });
      setIsOpenNotice(true);
    } else if (
      projectSentInvoices.filter(
        projSentInvoice => !(projSentInvoice.net_price > 0),
      ).length > 1
    ) {
      setNotice({
        title: 'Valores inválidos em notas fiscais!',
        subtitle:
          'Não é possível enviar solicitação com ' +
          'notas fiscais sem valor informado!',
      });
      setIsOpenNotice(true);
    } else if (
      projectSentInvoices.filter(
        projSentInvoice => !(projSentInvoice.amount > 0),
      ).length > 0
    ) {
      setNotice({
        title: 'Quantidades inválidas em notas fiscais!',
        subtitle:
          'Não é possível enviar solicitação com ' +
          'notas fiscais sem quantidade informada!',
      });
      setIsOpenNotice(true);
    } else if (
      projectSentInvoices.filter(
        projSentInvoice => projSentInvoice.amount_error !== undefined,
      ).length > 0
    ) {
      setNotice({
        title: 'Quantidades inválidas em notas fiscais por item!',
        subtitle:
          'Não é possível enviar solicitação com ' +
          'quantidade total por item acima do requisitado!',
      });
      setIsOpenNotice(true);
    } else if (
      projectLabels.filter(projLabel => projLabel.conflict_error !== undefined)
        .length > 0
    ) {
      setNotice({
        title: 'Existem etiquetas iguais!',
        subtitle: 'Não é possível enviar solicitação com etiquetas iguais!',
      });
      setIsOpenNotice(true);
    } else if (
      projectLabels.filter(
        projLabel => projLabel.ext_used_invoice_error !== undefined,
      ).length > 0
    ) {
      setNotice({
        title: 'Notas fiscais inválidas em Etiquetas!',
        subtitle:
          'Não é possível enviar solicitação com ' +
          'notas fiscais inválidas em Etiquetas!',
      });
      setIsOpenNotice(true);
    } else {
      setProcConfirmation({
        type: '',
        title: `Confirma gravação da solicitação ${state.code}?`,
      });

      setIsOpenConfirmProcedure(true);
    }
  }

  useEffect(() => {
    projectSamples.current = projectSamples.current.map(projSample => {
      const sentAmount = projectSentInvoices
        .filter(
          p_s_i =>
            !p_s_i.deleted && p_s_i.sample.value === projSample.sample.id,
        )
        .reduce((acc, p_s_i) => acc + p_s_i.amount, 0);

      return {
        ...projSample,
        sent_amount: sentAmount,
        pending_amount: projSample.total_amount - sentAmount,
      };
    });

    if (sentInvoicesTriggeredByEffect.current) {
      sentInvoicesTriggeredByEffect.current = false;

      return;
    }

    const handleSentInvoicesChange = () => {
      setProjectSentInvoices(prevProjSentInvoices =>
        prevProjSentInvoices.map(prevProjSentInv => {
          sentInvoicesTriggeredByEffect.current = true;

          if (!prevProjSentInv.deleted) {
            return {
              ...prevProjSentInv,
              internal_invoice_conf_error:
                prevProjSentInvoices.find(
                  p_p_s_i =>
                    p_p_s_i.sequence !== prevProjSentInv.sequence &&
                    !p_p_s_i.deleted &&
                    p_p_s_i.number === prevProjSentInv.number &&
                    p_p_s_i.series === prevProjSentInv.series &&
                    moment(p_p_s_i.issue_date).format('DD/MM/YYYY') ===
                      moment(prevProjSentInv.issue_date).format('DD/MM/YYYY'),
                ) !== undefined
                  ? 'Nota fiscal já utilizada'
                  : undefined,
              amount_error:
                projectSamples.current.find(
                  p_s =>
                    p_s.sample.id === prevProjSentInv.sample.value &&
                    p_s.sent_amount > p_s.total_amount,
                ) !== undefined
                  ? 'Quantidade acima do permitido'
                  : undefined,
            };
          }

          return { ...prevProjSentInv };
        }),
      );
    };

    if (projectSentInvoices.length > 0) {
      handleSentInvoicesChange();
    }
  }, [projectSentInvoices]);

  const handleSentInvoiceValAndChange = async (
    receivedProjSentInv: INewProjectSentInvoice,
  ) => {
    let invalid = false;

    setProjectSentInvoices(prevProjSentInvoices =>
      prevProjSentInvoices.map(prevProjSentInv => {
        if (prevProjSentInv.sequence === receivedProjSentInv.sequence) {
          return {
            ...prevProjSentInv,
            number: receivedProjSentInv.number,
            series: receivedProjSentInv.series,
            issue_date: receivedProjSentInv.issue_date,
            issue_date_error: receivedProjSentInv.issue_date_error,
          };
        }

        return { ...prevProjSentInv };
      }),
    );

    if (moment(receivedProjSentInv.issue_date).isValid()) {
      await api
        .get(
          `/projectsentinvoices/valid?` +
            `series=${receivedProjSentInv.series}&` +
            `number=${receivedProjSentInv.number}&` +
            `issue_date=${receivedProjSentInv.issue_date}`,
        )
        .then(response => {
          invalid =
            response.data.id && response.data.id !== receivedProjSentInv.id;
        });
    }

    setProjectSentInvoices(prevProjSentInvoices =>
      prevProjSentInvoices.map(prevProjSentInv => {
        if (prevProjSentInv.sequence === receivedProjSentInv.sequence) {
          return {
            ...prevProjSentInv,
            ext_used_invoice_error: invalid
              ? 'Nota fiscal já utilizada em outra solicitação'
              : undefined,
          };
        }

        return { ...prevProjSentInv };
      }),
    );
  };

  const handleDeleteSentInvoice = (reason: string) => {
    if (sentInvoiceToDelete.sequence !== 0) {
      setProjectSentInvoices(prevProjSentInvoices =>
        prevProjSentInvoices.map(prevProjSentInv =>
          prevProjSentInv.sequence === sentInvoiceToDelete.sequence
            ? {
                ...prevProjSentInv,
                deleted: true,
                project_record_reason: reason,
                ext_used_invoice_error: undefined,
                internal_invoice_conf_error: undefined,
                issue_date_error: undefined,
                amount_error: undefined,
              }
            : { ...prevProjSentInv },
        ),
      );

      setSentInvoiceToDelete({
        open: false,
        title: '',
        sequence: 0,
        reason: '',
      });
    }
  };

  useEffect(() => {
    if (labelsTriggeredByEffect.current) {
      labelsTriggeredByEffect.current = false;

      return;
    }

    const handleLabelsChange = () => {
      setProjectLabels(prevProjLabels =>
        prevProjLabels.map(prevProjLabel => {
          labelsTriggeredByEffect.current = true;

          return {
            ...prevProjLabel,
            conflict_error:
              prevProjLabels.find(
                p_p_l =>
                  p_p_l.sequence !== prevProjLabel.sequence &&
                  p_p_l.control === prevProjLabel.control &&
                  p_p_l.packaging === prevProjLabel.packaging &&
                  p_p_l.city === prevProjLabel.city &&
                  p_p_l.state === prevProjLabel.state &&
                  p_p_l.invoice_series === prevProjLabel.invoice_series &&
                  p_p_l.invoice_number === prevProjLabel.invoice_number &&
                  p_p_l.document === prevProjLabel.document &&
                  p_p_l.item_erp_code === prevProjLabel.item_erp_code &&
                  p_p_l.batch === prevProjLabel.batch,
              ) !== undefined ||
              previousProjectLabels.find(
                p_p_l =>
                  p_p_l.sequence !== prevProjLabel.sequence &&
                  p_p_l.control === prevProjLabel.control &&
                  p_p_l.packaging === prevProjLabel.packaging &&
                  p_p_l.city === prevProjLabel.city &&
                  p_p_l.state === prevProjLabel.state &&
                  p_p_l.invoice_series === prevProjLabel.invoice_series &&
                  p_p_l.invoice_number === prevProjLabel.invoice_number &&
                  p_p_l.document === prevProjLabel.document &&
                  p_p_l.item_erp_code === prevProjLabel.item_erp_code &&
                  p_p_l.batch === prevProjLabel.batch,
              ) !== undefined
                ? 'Etiqueta já registrada'
                : undefined,
          };
        }),
      );
    };

    if (projectLabels.length > 0) {
      handleLabelsChange();
    }
  }, [previousProjectLabels, projectLabels]);

  const handleLabelInvoiceValAndChange = async (
    receivedProjLabel: INewProjectLabel,
  ) => {
    let invalid = false;

    setProjectLabels(prevProjLabels =>
      prevProjLabels.map(prevProjLabel =>
        prevProjLabel.sequence === receivedProjLabel.sequence
          ? {
              ...prevProjLabel,
              invoice_number: receivedProjLabel.invoice_number,
              invoice_series: receivedProjLabel.invoice_series,
              invoice_issue_date: receivedProjLabel.invoice_issue_date,
              invoice_issue_date_error:
                receivedProjLabel.invoice_issue_date_error,
            }
          : { ...prevProjLabel },
      ),
    );

    if (moment(receivedProjLabel.invoice_issue_date).isValid()) {
      await api
        .get(
          `/projectlabels/valid?` +
            `invoice_series=${receivedProjLabel.invoice_series}&` +
            `invoice_number=${receivedProjLabel.invoice_number}&` +
            `invoice_issue_date=${receivedProjLabel.invoice_issue_date}`,
        )
        .then(response => {
          invalid =
            response.data.project_id && response.data.project_id !== state.id;
        });
    }

    setProjectLabels(prevProjLabels =>
      prevProjLabels.map(prevProjLabel =>
        prevProjLabel.sequence === receivedProjLabel.sequence
          ? {
              ...prevProjLabel,
              ext_used_invoice_error: invalid
                ? 'Nota fiscal já utilizada em outra solicitação'
                : undefined,
            }
          : { ...prevProjLabel },
      ),
    );
  };

  const handleLabelConflictValAndChange = (
    receivedProjLabel: INewProjectLabel,
  ) => {
    setProjectLabels(prevProjLabels =>
      prevProjLabels.map(prevProjLabel =>
        prevProjLabel.sequence === receivedProjLabel.sequence
          ? {
              ...prevProjLabel,
              control: receivedProjLabel.control,
              packaging: receivedProjLabel.packaging,
              item_erp_code: receivedProjLabel.item_erp_code,
              batch: receivedProjLabel.batch,
            }
          : { ...prevProjLabel },
      ),
    );
  };

  const onExit = () => {
    api.delete(`projectfilesreceived/projecttempid/${projectTemp.current}`);

    history.push('/projects/samples');
  };

  return (
    <Container>
      <BreadCrumb
        main="Solicitações"
        path="/projects/main"
        firstChildPath="/projects/samples"
        firstChild="Amostras"
        secondChild="Atualização Solicitação"
        mainIcon={FaProjectDiagram}
        firstChildIcon={FaBoxOpen}
        secondChildIcon={FaBoxOpen}
        mainColored
        firstChildColored
      />

      <ProjectInfo project_id={state.id} />

      <form onSubmit={handleSubmit(submitForm)}>
        <LabelContainer>
          <Label>Notas Fiscais</Label>
        </LabelContainer>

        <Content>
          {projectSentInvoices &&
            projectSentInvoices.length > 0 &&
            sampleOptions.length > 0 && (
              <ItemContainer>
                {projectSentInvoices?.map(
                  projectSentInvoice =>
                    !projectSentInvoice.deleted && (
                      <div key={projectSentInvoice.sequence}>
                        <ItemSequence>
                          <ItemSequenceLabel>
                            {`SEQUÊNCIA: ${projectSentInvoice.sequence}`}
                          </ItemSequenceLabel>
                        </ItemSequence>

                        <ItemContent>
                          <ItemWrapper>
                            <TableDefaultInput
                              type="text"
                              labelText="Número"
                              upperCaseLabel
                              defaultValue={projectSentInvoice.number}
                              error={
                                projectSentInvoice.ext_used_invoice_error ||
                                projectSentInvoice.internal_invoice_conf_error
                              }
                              maxLength={7}
                              disabled={
                                projectSentInvoice.previous &&
                                !projectSentInvoice.fields_enabled
                              }
                              onChange={e => {
                                handleSentInvoiceValAndChange({
                                  ...projectSentInvoice,
                                  number: e.target.value,
                                });
                              }}
                            />
                          </ItemWrapper>

                          <ItemWrapper>
                            <TableDefaultInput
                              type="text"
                              labelText="Série"
                              upperCaseLabel
                              defaultValue={projectSentInvoice.series}
                              maxLength={3}
                              disabled={
                                projectSentInvoice.previous &&
                                !projectSentInvoice.fields_enabled
                              }
                              onChange={e => {
                                handleSentInvoiceValAndChange({
                                  ...projectSentInvoice,
                                  series: e.target.value,
                                });
                              }}
                            />
                          </ItemWrapper>

                          <ItemWrapper>
                            <TableDateInput
                              type="text"
                              labelText="Data Emissão"
                              upperCaseLabel
                              defaultValue={projectSentInvoice.issue_date.toLocaleDateString()}
                              error={projectSentInvoice.issue_date_error}
                              disabled={
                                projectSentInvoice.previous &&
                                !projectSentInvoice.fields_enabled
                              }
                              onChange={e => {
                                const issueDate = moment(
                                  e.target.value,
                                  'DD/MM/YYYY',
                                ).toDate();

                                const isInvalidDate =
                                  !moment(
                                    e.target.value,
                                    'DD/MM/YYYY',
                                  ).isValid() ||
                                  issueDate.setHours(0, 0, 0, 0) >
                                    new Date().setHours(0, 0, 0, 0);

                                handleSentInvoiceValAndChange({
                                  ...projectSentInvoice,
                                  issue_date: issueDate,
                                  issue_date_error: isInvalidDate
                                    ? 'Data inválida'
                                    : undefined,
                                });
                              }}
                            />
                          </ItemWrapper>
                        </ItemContent>

                        <ItemContent>
                          <ItemWrapper>
                            <TableSelect
                              label="Item"
                              options={sampleOptions.map(option => ({
                                value: option.sample.id,
                                label: `${
                                  option.sample.erp_code
                                    ? `(${option.sample.erp_code}) - `
                                    : ''
                                }${option.sample.name}`,
                              }))}
                              defaultValue={{
                                value: projectSentInvoice.sample.value,
                                label: sampleOptions.find(
                                  option =>
                                    option.sample.id ===
                                    projectSentInvoice.sample.value,
                                )?.sample.name,
                              }}
                              disabled={projectSentInvoice.previous}
                              onSet={(e: any) => {
                                setProjectSentInvoices(prevProjSentInvoices =>
                                  prevProjSentInvoices.map(prevProjSentInv =>
                                    prevProjSentInv.sequence ===
                                    projectSentInvoice.sequence
                                      ? {
                                          ...prevProjSentInv,
                                          sample: { value: e.value },
                                        }
                                      : { ...prevProjSentInv },
                                  ),
                                );
                              }}
                            />
                          </ItemWrapper>

                          <ItemWrapper>
                            <TableCurrencyInput
                              type="text"
                              labelText="Valor NF"
                              upperCaseLabel
                              defaultValue={projectSentInvoice.net_price}
                              value={projectSentInvoice.net_price}
                              disabled={
                                projectSentInvoice.previous &&
                                !projectSentInvoice.fields_enabled
                              }
                              onSet={e => {
                                setProjectSentInvoices(prevProjSentInvoices =>
                                  prevProjSentInvoices.map(prevProjSentInv =>
                                    prevProjSentInv.sequence ===
                                    projectSentInvoice.sequence
                                      ? {
                                          ...prevProjSentInv,
                                          net_price: getCurrencyFormatValue(
                                            e.target.value,
                                          ),
                                        }
                                      : { ...prevProjSentInv },
                                  ),
                                );
                              }}
                            />
                          </ItemWrapper>

                          <ItemWrapper>
                            <TableWholeNumberInput
                              type="text"
                              labelText="Quantidade"
                              upperCaseLabel
                              defaultValue={projectSentInvoice.amount}
                              error={projectSentInvoice.amount_error}
                              disabled={
                                projectSentInvoice.previous &&
                                !projectSentInvoice.fields_enabled
                              }
                              onSet={e => {
                                setProjectSentInvoices(prevProjSentInvoices =>
                                  prevProjSentInvoices.map(prevProjSentInv =>
                                    prevProjSentInv.sequence ===
                                    projectSentInvoice.sequence
                                      ? {
                                          ...prevProjSentInv,
                                          amount: getNumberFormatValue(
                                            e.target.value,
                                          ),
                                        }
                                      : { ...prevProjSentInv },
                                  ),
                                );
                              }}
                            />
                          </ItemWrapper>
                        </ItemContent>

                        <div
                          style={{
                            display: 'flex',
                            justifyContent: 'flex-end',
                            padding: '10px 5px 10px 10px',
                          }}
                        >
                          {projectSentInvoice.previous && (
                            <>
                              {projectSentInvoice.fields_enabled ? (
                                <UnlockButton
                                  iconSize={20}
                                  type="button"
                                  onClick={() => {
                                    setProjectSentInvoices(
                                      prevProjSentInvoices =>
                                        prevProjSentInvoices.map(
                                          prevProjSentInv =>
                                            prevProjSentInv.sequence ===
                                            projectSentInvoice.sequence
                                              ? {
                                                  ...prevProjSentInv,
                                                  fields_enabled: false,
                                                }
                                              : { ...prevProjSentInv },
                                        ),
                                    );
                                  }}
                                />
                              ) : (
                                <LockButton
                                  iconSize={20}
                                  type="button"
                                  onClick={() => {
                                    setProjectSentInvoices(
                                      prevProjSentInvoices =>
                                        prevProjSentInvoices.map(
                                          prevProjSentInv =>
                                            prevProjSentInv.sequence ===
                                            projectSentInvoice.sequence
                                              ? {
                                                  ...prevProjSentInv,
                                                  fields_enabled: true,
                                                }
                                              : { ...prevProjSentInv },
                                        ),
                                    );
                                  }}
                                />
                              )}

                              <DeletePreviousButton
                                style={{
                                  marginTop: '0.8rem',
                                  marginLeft: '1rem',
                                }}
                                type="button"
                                onClick={() => {
                                  setSentInvoiceToDelete({
                                    open: true,
                                    title:
                                      `Deseja realmente excluir a NF ${projectSentInvoice.number} ` +
                                      `previamente registrada?`,
                                    sequence: projectSentInvoice.sequence,
                                    reason: '',
                                  });
                                }}
                              />
                            </>
                          )}

                          {!projectSentInvoice.previous &&
                            projectSentInvoices.length ===
                              projectSentInvoice.sequence && (
                              <DeleteRowButton
                                type="button"
                                onClick={() => {
                                  setProjectSentInvoices(prevProjSentInvoices =>
                                    prevProjSentInvoices.filter(
                                      prevProjSentInv =>
                                        prevProjSentInv.sequence !==
                                        projectSentInvoice.sequence,
                                    ),
                                  );
                                }}
                              />
                            )}
                        </div>
                      </div>
                    ),
                )}
              </ItemContainer>
            )}
        </Content>

        <ContentAddRow>
          <AddRowButton
            type="button"
            onClick={() => {
              setProjectSentInvoices(prevProjSentInvoices => [
                ...prevProjSentInvoices,
                {
                  id: '',
                  number: '',
                  series: '',
                  issue_date: new Date(),
                  net_price: 0,
                  amount: 0,
                  sequence: projectSentInvoices.length + 1,
                  sample: {
                    value: '',
                  },
                  project_record_reason: '',
                  ext_used_invoice_error: undefined,
                  internal_invoice_conf_error: undefined,
                  issue_date_error: undefined,
                  amount_error: undefined,
                  previous: false,
                  fields_enabled: true,
                  deleted: false,
                },
              ]);
            }}
          />
        </ContentAddRow>

        <LabelContainer>
          <Label>Etiquetas</Label>
        </LabelContainer>

        <Content>
          {projectLabels && projectLabels.length > 0 && (
            <ItemContainer>
              {projectLabels?.map(projectLabel => (
                <div key={projectLabel.sequence}>
                  <ItemSequence>
                    <ItemSequenceLabel>
                      {`${'Sequência'.toUpperCase()}: ${projectLabel.sequence}`}
                    </ItemSequenceLabel>
                  </ItemSequence>

                  <ItemContent>
                    <ItemWrapper>
                      <TableDefaultInput
                        type="text"
                        labelText="Controle"
                        upperCaseLabel
                        defaultValue={projectLabel.control}
                        maxLength={20}
                        error={projectLabel.conflict_error}
                        onChange={e => {
                          handleLabelConflictValAndChange({
                            ...projectLabel,
                            control: e.target.value,
                          });
                        }}
                      />
                    </ItemWrapper>

                    <ItemWrapper>
                      <TableDefaultInput
                        type="text"
                        labelText="Embalagem"
                        upperCaseLabel
                        defaultValue={projectLabel.packaging}
                        maxLength={20}
                        onChange={e => {
                          handleLabelConflictValAndChange({
                            ...projectLabel,
                            packaging: e.target.value,
                          });
                        }}
                      />
                    </ItemWrapper>
                  </ItemContent>

                  <ItemContent>
                    <ItemWrapper>
                      <TableDefaultInput
                        type="text"
                        labelText="Cliente"
                        upperCaseLabel
                        defaultValue={state.local_client?.name}
                        maxLength={60}
                        disabled
                      />
                    </ItemWrapper>

                    <ItemWrapper>
                      <TableDefaultInput
                        type="text"
                        labelText="Cidade"
                        upperCaseLabel
                        defaultValue={state.local_client?.city}
                        maxLength={50}
                        disabled
                      />
                    </ItemWrapper>
                  </ItemContent>

                  <ItemContent>
                    <ItemWrapper>
                      <TableDefaultInput
                        type="text"
                        labelText="Estado"
                        upperCaseLabel
                        defaultValue={state.local_client?.state}
                        maxLength={30}
                        disabled
                      />
                    </ItemWrapper>

                    <ItemWrapper>
                      <TableDefaultInput
                        type="text"
                        labelText="Série NF"
                        upperCaseLabel
                        defaultValue={projectLabel.invoice_series}
                        maxLength={3}
                        onChange={e => {
                          handleLabelInvoiceValAndChange({
                            ...projectLabel,
                            invoice_series: e.target.value,
                          });
                        }}
                      />
                    </ItemWrapper>

                    <ItemWrapper>
                      <TableDefaultInput
                        type="text"
                        labelText="Número NF"
                        upperCaseLabel
                        defaultValue={projectLabel.invoice_number}
                        error={projectLabel.ext_used_invoice_error}
                        maxLength={7}
                        onChange={e => {
                          handleLabelInvoiceValAndChange({
                            ...projectLabel,
                            invoice_number: e.target.value,
                          });
                        }}
                      />
                    </ItemWrapper>
                  </ItemContent>

                  <ItemContent>
                    <ItemWrapper>
                      <TableDateInput
                        type="text"
                        labelText="Data Emissão NF"
                        upperCaseLabel
                        defaultValue={projectLabel.invoice_issue_date.toLocaleDateString()}
                        error={projectLabel.invoice_issue_date_error}
                        onChange={e => {
                          const issueDate = moment(
                            e.target.value,
                            'DD/MM/YYYY',
                          ).toDate();

                          const isInvalidDate =
                            !moment(e.target.value, 'DD/MM/YYYY').isValid() ||
                            issueDate.setHours(0, 0, 0, 0) >
                              new Date().setHours(0, 0, 0, 0);

                          handleLabelInvoiceValAndChange({
                            ...projectLabel,
                            invoice_issue_date: issueDate,
                            invoice_issue_date_error: isInvalidDate
                              ? 'Data inválida'
                              : undefined,
                          });
                        }}
                      />
                    </ItemWrapper>

                    <ItemWrapper>
                      <TableCurrencyInput
                        type="text"
                        labelText="Valor Total NF"
                        upperCaseLabel
                        value={projectLabel.invoice_net_price}
                        onSet={e => {
                          setProjectLabels(prevProjLabels =>
                            prevProjLabels.map(prevProjLabel =>
                              prevProjLabel.sequence === projectLabel.sequence
                                ? {
                                    ...prevProjLabel,
                                    invoice_net_price: getCurrencyFormatValue(
                                      e.target.value,
                                    ),
                                  }
                                : { ...prevProjLabel },
                            ),
                          );
                        }}
                      />
                    </ItemWrapper>

                    <ItemWrapper>
                      <TableDefaultInput
                        type="text"
                        labelText="CNPJ"
                        upperCaseLabel
                        defaultValue={state.local_client?.document}
                        maxLength={18}
                        disabled
                      />
                    </ItemWrapper>
                  </ItemContent>

                  <ItemContent>
                    <ItemWrapper>
                      <TableSelect
                        label="Item"
                        options={sampleOptions.map(option => ({
                          value: option.sample.erp_code,
                          label: `${
                            option.sample.erp_code
                              ? `(${option.sample.erp_code}) - `
                              : ''
                          }${option.sample.name}`,
                        }))}
                        onSet={(e: any) => {
                          handleLabelConflictValAndChange({
                            ...projectLabel,
                            item_erp_code: e.value,
                          });
                        }}
                      />
                    </ItemWrapper>

                    <ItemWrapper>
                      <TableDefaultInput
                        type="text"
                        labelText="Lote"
                        upperCaseLabel
                        defaultValue={projectLabel.batch}
                        maxLength={20}
                        onChange={e => {
                          handleLabelConflictValAndChange({
                            ...projectLabel,
                            batch: e.target.value,
                          });
                        }}
                      />
                    </ItemWrapper>
                  </ItemContent>

                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'flex-end',
                      padding: '10px 5px 10px 10px',
                    }}
                  >
                    {previousProjectLabels.length + projectLabels.length ===
                      projectLabel.sequence && (
                      <DeleteRowButton
                        type="button"
                        onClick={() => {
                          setProjectLabels(prevProjLabels =>
                            prevProjLabels.filter(
                              prevProjLabel =>
                                prevProjLabel.sequence !==
                                projectLabel.sequence,
                            ),
                          );
                        }}
                      />
                    )}
                  </div>
                </div>
              ))}
            </ItemContainer>
          )}
        </Content>

        <ContentAddRow>
          <AddRowButton
            type="button"
            onClick={() => {
              setProjectLabels(prevProjLabels => [
                ...prevProjLabels,
                {
                  control: '',
                  packaging: '',
                  city: state.local_client.city,
                  state: state.local_client.state,
                  invoice_series: '',
                  invoice_number: '',
                  invoice_issue_date: new Date(new Date().setHours(0, 0, 0, 0)),
                  invoice_net_price: 0,
                  document: state.local_client.document,
                  item_erp_code: '',
                  batch: '',
                  sequence:
                    previousProjectLabels.length + projectLabels.length + 1,
                  invoice_net_price_enabled: false,
                  ext_used_invoice_error: undefined,
                  invoice_issue_date_error: undefined,
                  conflict_error: undefined,
                },
              ]);
            }}
          />
        </ContentAddRow>

        <Content>
          <Wrapper>
            <TextArea
              name="record_note"
              labelFor="record_note"
              labelText="Observação"
              error={errors?.record_note?.message}
              ref={register}
              maxLength={1000}
            />
          </Wrapper>
        </Content>

        <Content>
          <UploadFiles
            type="anexo"
            project_temp_id={projectTemp.current}
            project_type="AMOSTRA"
            sequence={3}
            user_id={user.id}
          />
        </Content>

        <ButtonContainer>
          <SaveButton
            type="button"
            processing={isSaving}
            disabled={isSaving}
            onClick={() => onValidateConfirmProcedure()}
          />

          <ExitButton
            style={{ marginLeft: 10 }}
            type="button"
            disabled={isSaving}
            onClick={() => setIsOpenExit(true)}
          />
        </ButtonContainer>
      </form>

      <ConfirmProcedureDialog
        isOpen={isOpenConfirmProcedure}
        setIsOpen={setIsOpenConfirmProcedure}
        onConfirm={() => handleSubmit(submitForm)()}
        title={procConfirmation.title}
        subTitle="A solicitação será enviada para o próximo procedimento"
        opConfirm="Sim"
        type={procConfirmation.type}
      />

      <ConfirmExitDialog
        isOpen={isOpenExit}
        setIsOpen={setIsOpenExit}
        onExit={onExit}
      />

      <DeleteModal
        isOpen={sentInvoiceToDelete.open}
        title={sentInvoiceToDelete.title}
        subTitle="A exclusão apenas será efetivada ao salvar o procedimento!"
        onDelete={handleDeleteSentInvoice}
        onClose={() => {
          setSentInvoiceToDelete({
            open: false,
            title: '',
            sequence: 0,
            reason: '',
          });
        }}
      />

      <NoticeDialog
        isOpen={isOpenNotice}
        type="error"
        title={notice.title}
        subTitle={notice.subtitle}
        setIsOpen={setIsOpenNotice}
      />
    </Container>
  );
};

export default Procedure3;
