import React from 'react';

import {
  FaHome,
  FaProjectDiagram,
  FaBoxes,
  FaStore,
  FaUser,
  FaCogs,
  FaTools,
} from 'react-icons/fa';

export default {
  Início: {
    icon: <FaHome />,
    link: '/dashboard',
    prefix: '',
  },
  Solicitações: {
    icon: <FaProjectDiagram />,
    link: '/projects/main',
    prefix: 'proj',
  },
  Itens: {
    icon: <FaBoxes />,
    link: '/items/main',
    prefix: 'items',
  },
  Lojas: {
    icon: <FaStore />,
    link: '/storerelationships',
    prefix: 'store',
  },
  Usuários: {
    icon: <FaUser />,
    link: '/users',
    prefix: 'users',
  },
  Configurações: {
    icon: <FaCogs />,
    link: '/configurations/main',
    prefix: 'conf',
  },
  Administração: {
    icon: <FaTools />,
    link: '/management/main',
    prefix: 'mgmt',
  },
} as const;
