import React, { useCallback, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useForm, Controller } from 'react-hook-form';
import { FaStore, FaListUl, FaBox, FaArrowRight } from 'react-icons/fa';

import BreadCrumb from '~/components/BreadCrumb';
import StoreRelationshipInfo from '~/components/StoreRelationshipInfo';
import Select from '~/components/Select';
import LockButton from '~/components/Buttons/Lock';
import UnlockButton from '~/components/Buttons/Unlock';
import RefreshAltButton from '~/components/Buttons/RefreshAlt';
import DefaultInput from '~/components/Inputs/Default';
import PhoneInput from '~/components/Inputs/Phone';
import TextArea from '~/components/TextArea';
import SaveButton from '~/components/Buttons/Save';
import ExitButton from '~/components/Buttons/Exit';
import ConfirmProcedureDialog from '~/components/Dialogs/ConfirmProcedure';
import ConfirmExitDialog from '~/components/Dialogs/ConfirmExit';
import ConfirmWarningDialog from '~/components/Dialogs/ConfirmWarning';

import { useAuth } from '~/hooks/auth';
import { useToast } from '~/hooks/toast';

import api from '~/services/api';

import {
  Container,
  Content,
  Wrapper,
  HalfWrapper,
  ThreeFourthsWrapper,
  TwoFifthsWrapper,
  ThreeFifthsWrapper,
  InfoWrapper,
  ButtonHalfWrapper,
  ButtonOneFourthWrapper,
  InfoContent,
  LabelContainer,
  Label,
  ExtrasContainer,
  ExtrasButtonWrapper,
  ExtrasButton,
  ExtrasButtonIcon,
  ExtrasButtonType,
  ExtrasButtonGoIcon,
  ButtonContainer,
} from './styles';

interface IFormProcedure1 {
  erp_client_code: string;
  erp_rep_code: string;
  company_name: string;
  trade_name: string;
  address: string;
  city: string;
  state: string;
  client_contact_name: string;
  client_contact_phone: string;
  client_contact_email: string;
  note: string;
  user_inclusion_id: string;
  user_update_id: string;
  store_relationship_category: {
    value: string;
  };
  record_description: string;
}

interface IStoreRelationship {
  id: string;
  code: string;
  erp_client_code: string;
  erp_rep_code: string;
  company_name: string;
  trade_name: string;
  address: string;
  city: string;
  state: string;
  client_contact_name: string;
  client_contact_phone: string;
  client_contact_email: string;
  note: string;
  store_relationship_category_id: string;
  created_at: string;
}

interface ILocation {
  code: string;
  rep_code: string;
}

const Procedure1: React.FC = () => {
  const history = useHistory();
  const { user } = useAuth();
  const { addToast } = useToast();
  const { state } = useLocation<ILocation>();
  const [storeRelationship, setStoreRelationship] =
    useState<IStoreRelationship>();
  const [storeRelationshipRedirect, setStoreRelationshipRedirect] =
    useState<IStoreRelationship>();
  const [storeRelationshipLoaded, setStoreRelationshipLoaded] =
    useState<boolean>(false);
  const [storeRelationshipCategories, setStoreRelationshipCategories] =
    useState<
      {
        id: string;
        name: string;
      }[]
    >();
  const [client, setClient] = useState<{
    code: string;
    name: string;
    company_name: string;
    address: string;
    city: string;
    state: string;
  }>();
  const [loadingCategories, setLoadingCategories] = useState<boolean>(true);
  const [categoryEnabled, setCategoryEnabled] = useState(false);
  const [clientInfoEnabled, setClientInfoEnabled] = useState(false);
  const [clientContactEnabled, setClientContactEnabled] = useState(false);
  const [noteEnabled, setNoteEnabled] = useState(false);
  const [isOpenConfirmProcedure, setIsOpenConfirmProcedure] = useState(false);
  const [titleConfirmProcedure, setTitleConfirmProcedure] = useState('');
  const [isOpenExit, setIsOpenExit] = useState(false);
  const [isOpenWarningRefreshClient, setIsOpenWarningRefreshClient] =
    useState(false);
  const [isOpenTopicsRedirect, setIsOpenTopicsRedirect] = useState(false);
  const [confirmTopicsRedirect, setConfirmTopicsRedirect] = useState(false);
  const [isOpenDisplaysRedirect, setIsOpenDisplaysRedirect] = useState(false);
  const [confirmDisplaysRedirect, setConfirmDisplaysRedirect] = useState(false);

  const { handleSubmit, register, setValue, control, errors } =
    useForm<IFormProcedure1>();

  useEffect(() => {
    let storeRelationshipCategoryLabel = '';

    if (storeRelationshipLoaded) {
      if (storeRelationship) {
        if (storeRelationship.id) {
          if (storeRelationshipCategories) {
            if (storeRelationshipCategories.length > 0) {
              const storeRelCategory = storeRelationshipCategories.find(
                stRelCategory =>
                  stRelCategory.id ===
                  storeRelationship.store_relationship_category_id,
              );

              if (storeRelCategory) {
                storeRelationshipCategoryLabel = storeRelCategory.name;
              }
            }
          }

          setValue('store_relationship_category', {
            value: storeRelationship.store_relationship_category_id,
            label: storeRelationshipCategoryLabel,
          });
        }
      }
    }
  }, [
    storeRelationshipLoaded,
    storeRelationship,
    storeRelationshipCategories,
    setValue,
  ]);

  async function getStoreRelationship(client_code: string): Promise<void> {
    await api
      .get(`/storerelationships/client?erp_client_code=${client_code}`)
      .then(response => {
        setStoreRelationship(response.data);
        setStoreRelationshipLoaded(true);
      });
  }

  useEffect(() => {
    if (state === undefined) {
      addToast({
        type: 'error',
        title: 'Operação inválida!',
        description: 'Registro de loja não localizado',
      });

      history.push('/storerelationships');
    } else {
      register('erp_client_code');
      register('erp_rep_code');
      register('user_inclusion_id');
      register('user_update_id');
      register('record_description');

      setValue('erp_client_code', state.code);
      setValue('erp_rep_code', state.rep_code);
      setValue('user_inclusion_id', user.id);
      setValue('user_update_id', user.id);
      setValue('record_description', 'Atualização do registro de loja');

      getStoreRelationship(state.code);

      api
        .get('/configurations/storerelationshipcategories/valid')
        .then(response => {
          setStoreRelationshipCategories(response.data);
          setLoadingCategories(false);
        });

      api.get(`/clients/code?code=${state.code}`).then(response => {
        setClient(response.data);
      });
    }
  }, [addToast, history, state, user, register, setValue]);

  const categoryOptions = storeRelationshipCategories?.map(
    storeRelationshipCategory => ({
      value: storeRelationshipCategory.id,
      label: storeRelationshipCategory.name,
    }),
  );

  useEffect(() => {
    if (storeRelationshipRedirect && storeRelationshipRedirect.id) {
      if (confirmTopicsRedirect) {
        history.push({
          pathname: '/storerelationships/proceduretopics',
          state: storeRelationshipRedirect,
        });
      } else if (confirmDisplaysRedirect) {
        history.push({
          pathname: '/storerelationships/proceduredisplays',
          state: storeRelationshipRedirect,
        });
      }
    }
  }, [
    storeRelationshipRedirect,
    confirmTopicsRedirect,
    confirmDisplaysRedirect,
    history,
  ]);

  const submitForm = useCallback(
    async (data: IFormProcedure1) => {
      try {
        if (storeRelationship !== undefined && storeRelationship.id) {
          await api.put(`/storerelationships/${storeRelationship.id}`, data);

          addToast({
            type: 'success',
            title: 'Registro de loja atualizado!',
            description: 'Registro de loja atualizado com sucesso.',
          });
        } else {
          await api.post('/storerelationships', data);

          addToast({
            type: 'success',
            title: 'Registro de loja atualizado!',
            description: 'Registro de loja atualizado com sucesso.',
          });
        }

        if (confirmTopicsRedirect || confirmDisplaysRedirect) {
          await api
            .get(`/storerelationships/client?erp_client_code=${state.code}`)
            .then(response => {
              setStoreRelationshipRedirect(response.data);
            });
        } else {
          history.push('/storerelationships');
        }
      } catch (error: any) {
        setConfirmTopicsRedirect(false);
        setConfirmDisplaysRedirect(false);
        addToast({
          type: 'error',
          title: 'Ocorreu um erro!',
          description: error.response.data.message,
        });
      }
    },
    [
      addToast,
      history,
      storeRelationship,
      confirmTopicsRedirect,
      confirmDisplaysRedirect,
      state.code,
    ],
  );

  function onConfirmProcedure(): void {
    handleSubmit(submitForm)();
  }

  const onExit = useCallback(() => {
    history.push('/storerelationships');
  }, [history]);

  function onConfirmWarningRefreshClient(): void {
    setValue('company_name', client?.company_name);
    setValue('address', client?.address);
    setValue('city', client?.city);
    setValue('state', client?.state);
  }

  function onConfirmTopicsRedirect(): void {
    setConfirmTopicsRedirect(true);
  }

  function onConfirmDisplaysRedirect(): void {
    setConfirmDisplaysRedirect(true);
  }

  useEffect(() => {
    if (confirmTopicsRedirect) {
      handleSubmit(submitForm)();
    }
  }, [confirmTopicsRedirect, handleSubmit, submitForm]);

  useEffect(() => {
    if (confirmDisplaysRedirect) {
      handleSubmit(submitForm)();
    }
  }, [confirmDisplaysRedirect, handleSubmit, submitForm]);

  function handleEnableCategoryButton(): React.ReactNode | null {
    if (user.user_type.type !== 'REP') {
      return (
        <ButtonHalfWrapper>
          {categoryEnabled ? (
            <UnlockButton
              iconSize={26}
              type="button"
              onClick={() => {
                setCategoryEnabled(false);
              }}
            />
          ) : (
            <LockButton
              iconSize={26}
              type="button"
              onClick={() => {
                setCategoryEnabled(true);
              }}
            />
          )}
        </ButtonHalfWrapper>
      );
    }

    return null;
  }

  function handleDisplays(): React.ReactNode {
    if (
      user.user_type.type === 'ADM' ||
      user.user_type.type === 'MK1' ||
      user.user_type.type === 'MK2' ||
      user.user_type.type === 'MAX' ||
      user.user_type.type === 'GCM'
    ) {
      return (
        <ExtrasButtonWrapper>
          <ExtrasButton
            type="button"
            onClick={() => {
              setIsOpenDisplaysRedirect(true);
            }}
          >
            <ExtrasButtonIcon>
              <FaBox size={32} />
            </ExtrasButtonIcon>

            <ExtrasButtonType>Displays</ExtrasButtonType>

            <ExtrasButtonGoIcon>
              <FaArrowRight size={20} />
            </ExtrasButtonGoIcon>
          </ExtrasButton>
        </ExtrasButtonWrapper>
      );
    }

    return <></>;
  }

  return (
    <Container>
      <BreadCrumb
        main="Lojas"
        path="/storerelationships"
        firstChildPath="/storerelationships"
        firstChild="Atualização Registro"
        mainIcon={FaStore}
        firstChildIcon={FaStore}
        mainColored
      />

      <StoreRelationshipInfo
        erp_rep_code={state?.rep_code ? state.rep_code : ''}
        erp_client_code={state?.code ? state.code : ''}
        store_relationship_id={storeRelationship?.id}
      />

      <form onSubmit={handleSubmit(submitForm)}>
        <Content maxWidth="800px">
          <HalfWrapper>
            <Controller
              name="store_relationship_category"
              control={control}
              options={categoryOptions}
              label="Categoria de Loja"
              as={Select}
              loading={loadingCategories}
              disabled={!categoryEnabled}
            />
          </HalfWrapper>

          {handleEnableCategoryButton()}
        </Content>

        <LabelContainer>
          <Label>Informações Cliente</Label>
        </LabelContainer>

        <Content maxWidth="800px">
          <InfoWrapper disabled={!clientInfoEnabled}>
            <InfoContent>
              <Wrapper>
                <DefaultInput
                  name="company_name"
                  labelFor="company_name"
                  labelText="Razão Social"
                  type="text"
                  defaultValue={
                    storeRelationship?.company_name &&
                    storeRelationship?.company_name !== ''
                      ? storeRelationship.company_name
                      : client?.company_name
                  }
                  error={errors?.company_name?.message}
                  ref={register}
                  maxLength={60}
                />
              </Wrapper>
            </InfoContent>

            <InfoContent>
              <Wrapper>
                <DefaultInput
                  name="trade_name"
                  labelFor="trade_name"
                  labelText="Nome Fantasia"
                  type="text"
                  defaultValue={
                    storeRelationship?.trade_name
                      ? storeRelationship.trade_name
                      : ''
                  }
                  error={errors?.trade_name?.message}
                  ref={register}
                  maxLength={60}
                />
              </Wrapper>
            </InfoContent>

            <InfoContent>
              <Wrapper>
                <DefaultInput
                  name="address"
                  labelFor="address"
                  labelText="Endereço"
                  type="text"
                  defaultValue={
                    storeRelationship?.address &&
                    storeRelationship?.address !== ''
                      ? storeRelationship.address
                      : client?.address
                  }
                  error={errors?.address?.message}
                  ref={register}
                  maxLength={60}
                />
              </Wrapper>
            </InfoContent>

            <InfoContent>
              <HalfWrapper marginRight="1rem">
                <DefaultInput
                  name="city"
                  labelFor="city"
                  labelText="Cidade"
                  type="text"
                  defaultValue={
                    storeRelationship?.city && storeRelationship?.city !== ''
                      ? storeRelationship.city
                      : client?.city
                  }
                  error={errors?.city?.message}
                  ref={register}
                  maxLength={60}
                />
              </HalfWrapper>

              <HalfWrapper>
                <DefaultInput
                  name="state"
                  labelFor="state"
                  labelText="Estado"
                  type="text"
                  defaultValue={
                    storeRelationship?.state && storeRelationship?.state !== ''
                      ? storeRelationship.state
                      : client?.state
                  }
                  error={errors?.city?.message}
                  ref={register}
                  maxLength={60}
                />
              </HalfWrapper>
            </InfoContent>
          </InfoWrapper>

          <ButtonOneFourthWrapper>
            {clientInfoEnabled ? (
              <UnlockButton
                iconSize={26}
                type="button"
                onClick={() => {
                  setClientInfoEnabled(false);
                }}
              />
            ) : (
              <LockButton
                iconSize={26}
                type="button"
                onClick={() => {
                  setClientInfoEnabled(true);
                }}
              />
            )}

            <RefreshAltButton
              marginTop="1rem"
              marginLeft="1rem"
              type="button"
              onClick={() => {
                setIsOpenWarningRefreshClient(true);
              }}
            />
          </ButtonOneFourthWrapper>
        </Content>

        <LabelContainer>
          <Label>Contato</Label>
        </LabelContainer>

        <Content maxWidth="800px">
          <InfoWrapper disabled={!clientContactEnabled}>
            <InfoContent>
              <ThreeFifthsWrapper marginRight="1rem">
                <DefaultInput
                  name="client_contact_name"
                  labelFor="client_contact_name"
                  labelText="Nome"
                  type="text"
                  defaultValue={
                    storeRelationship?.client_contact_name
                      ? storeRelationship.client_contact_name
                      : ''
                  }
                  ref={register}
                  maxLength={60}
                />
              </ThreeFifthsWrapper>

              <TwoFifthsWrapper>
                <PhoneInput
                  name="client_contact_phone"
                  labelFor="client_contact_phone"
                  labelText="Telefone"
                  type="text"
                  defaultValue={
                    storeRelationship?.client_contact_phone
                      ? storeRelationship.client_contact_phone
                      : ''
                  }
                  ref={register}
                />
              </TwoFifthsWrapper>
            </InfoContent>

            <InfoContent>
              <Wrapper>
                <DefaultInput
                  name="client_contact_email"
                  labelFor="client_contact_email"
                  labelText="E-mail"
                  type="text"
                  defaultValue={
                    storeRelationship?.client_contact_email
                      ? storeRelationship.client_contact_email
                      : ''
                  }
                  ref={register}
                  maxLength={60}
                />
              </Wrapper>
            </InfoContent>
          </InfoWrapper>

          <ButtonOneFourthWrapper>
            {clientContactEnabled ? (
              <UnlockButton
                iconSize={26}
                type="button"
                onClick={() => {
                  setClientContactEnabled(false);
                }}
              />
            ) : (
              <LockButton
                iconSize={26}
                type="button"
                onClick={() => {
                  setClientContactEnabled(true);
                }}
              />
            )}
          </ButtonOneFourthWrapper>
        </Content>

        <Content maxWidth="800px">
          <ThreeFourthsWrapper>
            <TextArea
              name="note"
              labelFor="note"
              labelText="Observação"
              defaultValue={
                storeRelationship?.note ? storeRelationship.note : ''
              }
              error={errors?.note?.message}
              ref={register}
              maxLength={2000}
              disabled={!noteEnabled}
            />
          </ThreeFourthsWrapper>

          <ButtonOneFourthWrapper>
            {noteEnabled ? (
              <UnlockButton
                iconSize={26}
                type="button"
                onClick={() => {
                  setNoteEnabled(false);
                }}
              />
            ) : (
              <LockButton
                iconSize={26}
                type="button"
                onClick={() => {
                  setNoteEnabled(true);
                }}
              />
            )}
          </ButtonOneFourthWrapper>
        </Content>

        <Content maxWidth="800px">
          <ExtrasContainer>
            <ExtrasButtonWrapper>
              <ExtrasButton
                type="button"
                onClick={() => {
                  setIsOpenTopicsRedirect(true);
                }}
              >
                <ExtrasButtonIcon>
                  <FaListUl size={32} />
                </ExtrasButtonIcon>

                <ExtrasButtonType>Tópicos</ExtrasButtonType>

                <ExtrasButtonGoIcon>
                  <FaArrowRight size={20} />
                </ExtrasButtonGoIcon>
              </ExtrasButton>
            </ExtrasButtonWrapper>

            {handleDisplays()}
          </ExtrasContainer>
        </Content>

        <ButtonContainer>
          <SaveButton
            type="button"
            onClick={() => {
              setTitleConfirmProcedure(
                `Confirma gravação do registro ${state.code}?`,
              );
              setIsOpenConfirmProcedure(true);
            }}
          />

          <ExitButton
            style={{ marginLeft: 10 }}
            type="button"
            onClick={() => setIsOpenExit(true)}
          />
        </ButtonContainer>
      </form>

      <ConfirmProcedureDialog
        isOpen={isOpenConfirmProcedure}
        setIsOpen={setIsOpenConfirmProcedure}
        onConfirm={onConfirmProcedure}
        title={titleConfirmProcedure}
        subTitle=""
        opConfirm="Sim"
      />

      <ConfirmExitDialog
        isOpen={isOpenExit}
        setIsOpen={setIsOpenExit}
        onExit={onExit}
      />

      <ConfirmWarningDialog
        isOpen={isOpenWarningRefreshClient}
        setIsOpen={setIsOpenWarningRefreshClient}
        onConfirm={onConfirmWarningRefreshClient}
        title="Deseja atualizar as informações do cliente com base nos dados do ERP?"
        opConfirm="Sim, atualizar"
      />

      <ConfirmWarningDialog
        isOpen={isOpenTopicsRedirect}
        setIsOpen={setIsOpenTopicsRedirect}
        onConfirm={onConfirmTopicsRedirect}
        title={
          'Ao redirecionar para a seção de tópicos, ' +
          'as informações desta página serão salvas ' +
          'automaticamente, deseja continuar?'
        }
        opConfirm="Sim, continuar"
      />

      <ConfirmWarningDialog
        isOpen={isOpenDisplaysRedirect}
        setIsOpen={setIsOpenDisplaysRedirect}
        onConfirm={onConfirmDisplaysRedirect}
        title={
          'Ao redirecionar para a seção de displays, ' +
          'as informações desta página serão salvas ' +
          'automaticamente, deseja continuar?'
        }
        opConfirm="Sim, continuar"
      />
    </Container>
  );
};

export default Procedure1;
