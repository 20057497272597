import React from 'react';
import { Link } from 'react-router-dom';
import { FaArrowRight, FaTools } from 'react-icons/fa';

import { getPermByName } from '~/utils';

import { useAuth } from '~/hooks/auth';

import BreadCrumb from '~/components/BreadCrumb';

import {
  AnimatedContainer,
  Management,
  ManagementCardWrapper,
  ManagementCard,
  ManagementIcon,
  ManagementName,
  GoIcon,
} from './styles';

import { MANAGEMENT_NAVIGATION_ANIMATION } from './animations';

import { MANAGEMENT_NAVIGATION } from '~/constants';

const MainContent: React.FC = () => {
  const { user } = useAuth();

  return (
    <AnimatedContainer variants={MANAGEMENT_NAVIGATION_ANIMATION}>
      <BreadCrumb
        main="Administração"
        path="/management/main"
        mainIcon={FaTools}
      />
      <Management>
        {Object.entries(MANAGEMENT_NAVIGATION).map(
          ([key, value]) =>
            getPermByName(value.perm, user) && (
              <ManagementCardWrapper key={`wrapper-${key}`}>
                <Link to={value.link}>
                  <ManagementCard key={`card-${key}`} backgroundColor="#747474">
                    <ManagementIcon>{value.icon}</ManagementIcon>

                    <ManagementName>{key}</ManagementName>

                    <GoIcon>
                      <FaArrowRight size={20} />
                    </GoIcon>
                  </ManagementCard>
                </Link>
              </ManagementCardWrapper>
            ),
        )}
      </Management>
    </AnimatedContainer>
  );
};

export default MainContent;
