import React, { useCallback, useEffect, useState, useRef } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import moment from 'moment';
import { PropagateLoader } from 'react-spinners';
import { FaProjectDiagram, FaMoneyBillWave } from 'react-icons/fa';
import { v4 as uuidv4 } from 'uuid';

import BreadCrumb from '~/components/BreadCrumb';
import ProjectInfo from '~/components/ProjectInfo/Default';
import TableDateInput from '~/components/TableInputs/Date';
import TableDefaultInput from '~/components/TableInputs/Default';
import TableCurrencyInput from '~/components/TableInputs/Currency';
import LockButton from '~/components/Buttons/Lock';
import UnlockButton from '~/components/Buttons/Unlock';
import AddRowButton from '~/components/Buttons/AddRow';
import DeleteRowButton from '~/components/Buttons/DeleteRow';
import DeletePreviousButton from '~/components/Buttons/DeletePrevious';
import TextArea from '~/components/TextArea';
import UploadFiles from '~/components/UploadFiles';
import SaveButton from '~/components/Buttons/Save';
import ExitButton from '~/components/Buttons/Exit';
import ConfirmProcedureDialog from '~/components/Dialogs/ConfirmProcedure';
import ConfirmExitDialog from '~/components/Dialogs/ConfirmExit';
import ConfirmWarningDialog from '~/components/Dialogs/ConfirmWarning';
import DeleteModal from '~/components/Modals/OldDelete';
import NoticeDialog from '~/components/Dialogs/Notice';

import { useAuth } from '~/hooks/auth';
import { useToast } from '~/hooks/toast';

import api from '~/services/api';

import {
  Container,
  Content,
  ContentAddRow,
  Wrapper,
  LabelContainer,
  Label,
  Table,
  ItemSequence,
  ItemSequenceLabel,
  ItemContent,
  ItemWrapper,
  ButtonContainer,
} from './styles';

interface IPreviousProjectInvoice {
  id: string;
  number: string;
  series: string;
  issue_date: Date;
  gross_price: number;
  discount_price: number;
  sequence: number;
  title_series: string;
  title_number: string;
  title_installment: string;
  project_id: string;
  user_inclusion_id: string;
  user_update_id: string;
  project: {
    code: string;
    name: string;
  };
  user_inclusion: {
    name: string;
  };
  user_update: {
    name: string;
  };
  created_at: string;
}

interface IProjectInvoice {
  id: string;
  number: string;
  series: string;
  issue_date: Date;
  gross_price: number;
  discount_price: number;
  sequence: number;
  title_series: string;
  title_number: string;
  title_installment: string;
  project_id: string;
  user_inclusion_id: string;
  user_update_id: string;
  project_record_reason: string;
  issue_date_invalid_error: boolean;
  issue_date_conflict_general_error: boolean;
  issue_date_conflict_internal_error: boolean;
  invoice_validation_general_error: boolean;
  invoice_validation_internal_error: boolean;
  issue_date_error: string | undefined;
  invalid_invoice_error: string | undefined;
  previous: boolean;
  fields_enabled: boolean;
  deleted: boolean;
  previous_number: string;
  previous_series: string;
  previous_issue_date: Date | undefined;
  previous_title_series: string;
  previous_title_number: string;
  previous_title_installment: string;
}

interface IFormProcedure8 {
  type: string;
  code: string;
  granted_discount_price: number;
  sponsorship_id: string;
  user_update_id: string;
  validation_type: string;
  project_procedure_id: string;
  record_description: string;
  record_note: string;
  project_temp_id: string;
  project_invoices: IProjectInvoice[];
}

interface ILocation {
  id: string;
  type: string;
  code: string;
  price: number;
  gross_discount_price: number;
  net_discount_price: number;
  granted_discount_price: number;
  canceled_discount_price: number;
  erp_rep_code: string;
  status: string;
  invoice: string;
  project_procedure_id: string;
  project_procedure: {
    user_type: string;
  };
}

const Procedure8: React.FC = () => {
  const history = useHistory();
  const { user } = useAuth();
  const { addToast } = useToast();
  const { state } = useLocation<ILocation>();
  const [previousProjectInvoices, setPreviousProjectInvoices] =
    useState<IPreviousProjectInvoice[]>();
  const [projectInvoices, setProjectInvoices] = useState<IProjectInvoice[]>([]);
  const [loadProjectInvoices, setloadProjectInvoices] = useState(false);
  const [projectInvoicesLoaded, setProjectInvoicesLoaded] = useState(false);
  const [loading, setLoading] = useState<boolean>(true);
  const [projectTempFilled, setProjectTempFilled] = useState(false);
  const [projectTemp, setProjectTemp] = useState('');
  const projectInvoiceSequence = useRef(1);
  const [invoiceEnableSequence, setInvoiceEnableSequence] = useState('');
  const [confirmInvoiceEnabled, setConfirmInvoiceEnabled] = useState(false);
  const [invoiceDeleteSequence, setInvoiceDeleteSequence] = useState('');
  const [confirmInvoiceDelete, setConfirmInvoiceDelete] = useState(false);
  const [isOpenConfirmProcedure, setIsOpenConfirmProcedure] = useState(false);
  const [titleConfirmProcedure, setTitleConfirmProcedure] = useState('');
  const [isOpenExit, setIsOpenExit] = useState(false);
  const [isOpenWarning, setIsOpenWarning] = useState(false);
  const [isOpenDelete, setIsOpenDelete] = useState(false);
  const [titleDelete, setTitleDelete] = useState('');
  const [subTitleDelete, setSubTitleDelete] = useState('');
  const [reasonDelete, setReasonDelete] = useState('');
  const [isOpenNotice, setIsOpenNotice] = useState(false);
  const [titleNotice, setTitleNotice] = useState('');
  const [subTitleNotice, setSubTitleNotice] = useState('');
  const [isSaving, setIsSaving] = useState(false);
  const projectTotalGrantedDiscountPrice = useRef(0);

  const { handleSubmit, register, setValue, errors } =
    useForm<IFormProcedure8>();

  useEffect(() => {
    if (loadProjectInvoices) {
      if (previousProjectInvoices) {
        if (previousProjectInvoices.length > 0) {
          Object.keys(previousProjectInvoices).forEach(key => {
            projectInvoiceSequence.current =
              previousProjectInvoices[Number(key)].sequence + 1;

            const previousProjectInvoice: IProjectInvoice = {
              id: previousProjectInvoices[Number(key)].id,
              number: previousProjectInvoices[Number(key)].number,
              series: previousProjectInvoices[Number(key)].series,
              issue_date: new Date(
                new Date(
                  previousProjectInvoices[Number(key)].issue_date,
                ).setHours(0, 0, 0, 0),
              ),
              gross_price: previousProjectInvoices[Number(key)].gross_price,
              discount_price:
                previousProjectInvoices[Number(key)].discount_price,
              sequence: previousProjectInvoices[Number(key)].sequence,
              title_series: previousProjectInvoices[Number(key)].title_series,
              title_number: previousProjectInvoices[Number(key)].title_number,
              title_installment:
                previousProjectInvoices[Number(key)].title_installment,
              project_id: previousProjectInvoices[Number(key)].project_id,
              user_inclusion_id:
                previousProjectInvoices[Number(key)].user_inclusion_id,
              user_update_id: user.id,
              project_record_reason: '',
              issue_date_invalid_error: false,
              issue_date_conflict_general_error: false,
              issue_date_conflict_internal_error: false,
              invoice_validation_general_error: false,
              invoice_validation_internal_error: false,
              issue_date_error: undefined,
              invalid_invoice_error: undefined,
              previous: true,
              fields_enabled: false,
              deleted: false,
              previous_number: previousProjectInvoices[Number(key)].number,
              previous_series: previousProjectInvoices[Number(key)].series,
              previous_issue_date: new Date(
                new Date(
                  previousProjectInvoices[Number(key)].issue_date,
                ).setHours(0, 0, 0, 0),
              ),
              previous_title_series:
                previousProjectInvoices[Number(key)].title_series,
              previous_title_number:
                previousProjectInvoices[Number(key)].title_number,
              previous_title_installment:
                previousProjectInvoices[Number(key)].title_installment,
            };

            setProjectInvoices(prevProjectInvoices => [
              ...prevProjectInvoices,
              previousProjectInvoice,
            ]);
          });
        }
      }

      const firstProjectInvoice: IProjectInvoice = {
        id: '',
        number: '',
        series: '',
        issue_date: new Date(new Date().setHours(0, 0, 0, 0)),
        gross_price: 0,
        discount_price: 0,
        sequence: projectInvoiceSequence.current,
        title_series: '',
        title_number: '',
        title_installment: '',
        project_id: state.id,
        user_inclusion_id: user.id,
        user_update_id: user.id,
        project_record_reason: '',
        issue_date_invalid_error: false,
        issue_date_conflict_general_error: false,
        issue_date_conflict_internal_error: false,
        invoice_validation_general_error: false,
        invoice_validation_internal_error: false,
        issue_date_error: undefined,
        invalid_invoice_error: undefined,
        previous: false,
        fields_enabled: true,
        deleted: false,
        previous_number: '',
        previous_series: '',
        previous_issue_date: undefined,
        previous_title_series: '',
        previous_title_number: '',
        previous_title_installment: '',
      };

      setProjectInvoices(prevProjectInvoices => [
        ...prevProjectInvoices,
        firstProjectInvoice,
      ]);

      setloadProjectInvoices(false);
      setProjectInvoicesLoaded(true);
      setLoading(false);
    }
  }, [state, user, loadProjectInvoices, previousProjectInvoices]);

  useEffect(() => {
    if (previousProjectInvoices) {
      if (!projectInvoicesLoaded) {
        setloadProjectInvoices(true);
      }
    }
  }, [projectInvoicesLoaded, previousProjectInvoices]);

  useEffect(() => {
    if (state === undefined) {
      addToast({
        type: 'error',
        title: 'Operação inválida!',
        description: 'Projeto não localizado',
      });

      history.push('/projects/sponsorships');
    } else if (state.status.toUpperCase() === 'CANCELADO') {
      addToast({
        type: 'error',
        title: 'Operação inválida!',
        description: 'Projeto Cancelado',
      });

      history.push('/projects/sponsorships');
    } else {
      register('validation_type');
      register('project_temp_id');
      register('type');
      register('code');
      register('granted_discount_price');
      register('record_description');
      register('user_update_id');
      register('project_procedure_id');
      register('project_invoices');

      setValue('type', 'PATROCINIO');
      setValue('code', state.code);
      setValue('user_update_id', user.id);
      setValue('project_procedure_id', state.project_procedure_id);

      api
        .get(`/projectinvoices/projectall?project_id=${state.id}`)
        .then(response => {
          setPreviousProjectInvoices(response.data);
        });

      if (!projectTempFilled) {
        setProjectTempFilled(true);
        setProjectTemp(uuidv4());
      }
    }
  }, [addToast, history, state, user, projectTempFilled, register, setValue]);

  useEffect(() => {
    setValue('project_temp_id', projectTemp);
  }, [projectTemp, setValue]);

  const submitForm = useCallback(
    async (data: IFormProcedure8) => {
      try {
        setIsSaving(true);

        await api.put(`/projects/${state.id}`, data);

        addToast({
          type: 'success',
          title: 'Projeto atualizado!',
          description: 'Projeto atualizado com sucesso.',
        });

        history.push('/projects/sponsorships');
      } catch (error: any) {
        setIsSaving(false);

        addToast({
          type: 'error',
          title: 'Ocorreu um erro!',
          description: error.response.data.message,
        });
      }
    },
    [addToast, history, state],
  );

  async function onValidateConfirmProcedure(): Promise<void> {
    projectTotalGrantedDiscountPrice.current = 0;

    const rowsEmptyNumber = projectInvoices.filter(
      projInvoice => projInvoice.number === '',
    );

    const rowsInvalidInvoice = projectInvoices.filter(
      projInvoice => projInvoice.invalid_invoice_error !== undefined,
    );

    const rowsInvalidIssueDate = projectInvoices.filter(
      projInvoice => projInvoice.issue_date_error !== undefined,
    );

    const rowsInvalidGrossPrice = projectInvoices.filter(
      projInvoice => projInvoice.gross_price < projInvoice.discount_price,
    );

    const rowsInvalidDiscountPrice = projectInvoices.filter(
      projInvoice => projInvoice.discount_price <= 0,
    );

    if (projectInvoices) {
      if (projectInvoices.length > 0) {
        Object.keys(projectInvoices).forEach(key => {
          if (!projectInvoices[Number(key)].deleted) {
            projectTotalGrantedDiscountPrice.current +=
              projectInvoices[Number(key)].discount_price;
          }
        });
      }
    }

    if (projectInvoices.length <= 0) {
      setTitleNotice('Notas fiscais inválidas!');
      setSubTitleNotice('Necessário informar notas fiscais!');
      setIsOpenNotice(true);
    } else if (rowsEmptyNumber && rowsEmptyNumber.length > 0) {
      setTitleNotice('Existem notas fiscais sem número!');
      setSubTitleNotice(
        'Não é possível enviar projeto com notas fiscais sem número!',
      );
      setIsOpenNotice(true);
    } else if (rowsInvalidInvoice && rowsInvalidInvoice.length > 0) {
      setTitleNotice('Existem notas fiscais inválidas!');
      setSubTitleNotice(
        'Não é possível enviar projeto com notas fiscais inválidas!',
      );
      setIsOpenNotice(true);
    } else if (rowsInvalidIssueDate && rowsInvalidIssueDate.length > 0) {
      setTitleNotice('Existem datas de emissão inválidas em notas fiscais!');
      setSubTitleNotice(
        'Não é possível enviar projeto com notas fiscais possuindo datas de emissão inválidas!',
      );
      setIsOpenNotice(true);
    } else if (rowsInvalidGrossPrice && rowsInvalidGrossPrice.length > 0) {
      setTitleNotice('Valores inválidos em notas fiscais!');
      setSubTitleNotice(
        'Não é possível enviar projeto com notas fiscais possuindo valor total menor que valor de desconto!',
      );
      setIsOpenNotice(true);
    } else if (
      rowsInvalidDiscountPrice &&
      rowsInvalidDiscountPrice.length > 0
    ) {
      setTitleNotice('Valores inválidos em notas fiscais!');
      setSubTitleNotice(
        'Não é possível enviar projeto com notas fiscais sem valor de desconto!',
      );
      setIsOpenNotice(true);
    } else if (
      Math.round(projectTotalGrantedDiscountPrice.current * 100) / 100 >
      Math.round(
        (state.net_discount_price - state.canceled_discount_price) * 100,
      ) /
        100
    ) {
      setTitleNotice('Valores inválidos de descontos!');
      setSubTitleNotice(
        'Valores de descontos acima do permitido para notas fiscais!',
      );
      setIsOpenNotice(true);
    } else {
      setTitleConfirmProcedure(
        `Confirma finalização do projeto ${state.code}?`,
      );
      setIsOpenConfirmProcedure(true);
    }
  }

  function onConfirmProcedure(): void {
    setValue('validation_type', 'APROVADO');
    setValue(
      'granted_discount_price',
      projectTotalGrantedDiscountPrice.current,
    );

    if (
      Math.round(projectTotalGrantedDiscountPrice.current * 100) / 100 <
      Math.round(
        (state.net_discount_price - state.canceled_discount_price) * 100,
      ) /
        100
    ) {
      setValue(
        'record_description',
        `Atualização do projeto, com desconto concedido ` +
          `no valor de ${new Intl.NumberFormat('pt-BR', {
            style: 'currency',
            currency: 'BRL',
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          }).format(projectTotalGrantedDiscountPrice.current)}`,
      );
    } else {
      setValue(
        'record_description',
        `Finalização do projeto, com desconto concedido ` +
          `no valor de ${new Intl.NumberFormat('pt-BR', {
            style: 'currency',
            currency: 'BRL',
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          }).format(projectTotalGrantedDiscountPrice.current)}`,
      );
    }

    setValue('project_invoices', projectInvoices);

    handleSubmit(submitForm)();
  }

  const onExit = useCallback(() => {
    api.delete(`projectfilesreceived/projecttempid/${projectTemp}`);

    history.push('/projects/sponsorships');
  }, [history, projectTemp]);

  function onConfirmWarning(): void {
    setConfirmInvoiceEnabled(true);
  }

  function onDelete(): void {
    setConfirmInvoiceDelete(true);
  }

  function handleAddRowTableProjectInvoices(): void {
    projectInvoiceSequence.current += 1;

    const newProjectInvoice: IProjectInvoice = {
      id: '',
      number: '',
      series: '',
      issue_date: new Date(new Date().setHours(0, 0, 0, 0)),
      gross_price: 0,
      discount_price: 0,
      sequence: projectInvoiceSequence.current,
      title_series: '',
      title_number: '',
      title_installment: '',
      project_id: state.id,
      user_inclusion_id: user.id,
      user_update_id: user.id,
      project_record_reason: '',
      issue_date_invalid_error: false,
      issue_date_conflict_general_error: false,
      issue_date_conflict_internal_error: false,
      invoice_validation_general_error: false,
      invoice_validation_internal_error: false,
      issue_date_error: undefined,
      invalid_invoice_error: undefined,
      previous: false,
      fields_enabled: true,
      deleted: false,
      previous_number: '',
      previous_series: '',
      previous_issue_date: undefined,
      previous_title_series: '',
      previous_title_number: '',
      previous_title_installment: '',
    };

    setProjectInvoices(prevProjectInvoices => [
      ...prevProjectInvoices,
      newProjectInvoice,
    ]);
  }

  function handleDeleteRowTableProjectInvoices(): void {
    setProjectInvoices(
      projectInvoices.filter(
        projInvoice => projInvoice.sequence !== projectInvoiceSequence.current,
      ),
    );

    projectInvoiceSequence.current -= 1;
  }

  async function handleInvalidInvoiceGeneral(sequence: number): Promise<void> {
    const projInvoiceValidate = projectInvoices.find(
      projectInvoice => projectInvoice.sequence === sequence,
    );

    if (projInvoiceValidate) {
      if (moment(projInvoiceValidate.issue_date).isValid()) {
        await api
          .get(
            `/projectinvoices/validinvoice?` +
              `series=${projInvoiceValidate.series}&` +
              `number=${projInvoiceValidate.number}&` +
              `issue_date=${projInvoiceValidate.issue_date}&` +
              `title_series=${projInvoiceValidate.title_series}&` +
              `title_number=${projInvoiceValidate.title_number}&` +
              `title_installment=${projInvoiceValidate.title_installment}`,
          )
          .then(response => {
            let valid = false;

            if (
              response.data.issue_date &&
              response.data.series &&
              response.data.number &&
              response.data.title_series &&
              response.data.title_number &&
              response.data.title_installment
            ) {
              if (
                response.data.series === projInvoiceValidate.previous_series &&
                response.data.number === projInvoiceValidate.previous_number &&
                moment(response.data.issue_date).format('DD/MM/YYYY') ===
                  moment(projInvoiceValidate.previous_issue_date).format(
                    'DD/MM/YYYY',
                  ) &&
                response.data.title_series ===
                  projInvoiceValidate.previous_title_series &&
                response.data.title_number ===
                  projInvoiceValidate.previous_title_number &&
                response.data.title_installment ===
                  projInvoiceValidate.previous_title_installment
              ) {
                valid = true;
              } else {
                setProjectInvoices(
                  projectInvoices.map(projInvoice => {
                    if (projInvoice.sequence === sequence) {
                      projInvoice.invoice_validation_general_error = true;
                      projInvoice.issue_date_conflict_general_error = true;
                    }
                    return projInvoice;
                  }),
                );
              }
            } else {
              valid = true;
            }

            if (valid) {
              setProjectInvoices(
                projectInvoices.map(projInvoice => {
                  if (projInvoice.sequence === sequence) {
                    projInvoice.invoice_validation_general_error = false;
                    projInvoice.issue_date_conflict_general_error = false;
                  }
                  return projInvoice;
                }),
              );
            }
          });
      }
    }
  }

  async function handleInvalidInvoiceInternal(sequence: number): Promise<void> {
    const projInvoiceValidate = projectInvoices.find(
      projectInvoice => projectInvoice.sequence === sequence,
    );

    if (projInvoiceValidate) {
      const projInvoiceInvalid = projectInvoices.filter(
        projectInvoice =>
          !projectInvoice.deleted &&
          projectInvoice.series === projInvoiceValidate.series &&
          projectInvoice.number === projInvoiceValidate.number &&
          moment(projectInvoice.issue_date).format('DD/MM/YYYY') ===
            moment(projInvoiceValidate.issue_date).format('DD/MM/YYYY') &&
          projectInvoice.title_series === projInvoiceValidate.title_series &&
          projectInvoice.title_number === projInvoiceValidate.title_number &&
          projectInvoice.title_installment ===
            projInvoiceValidate.title_installment,
      );

      if (projInvoiceInvalid) {
        if (projInvoiceInvalid.length > 0) {
          Object.keys(projInvoiceInvalid).forEach(key => {
            setProjectInvoices(
              projectInvoices.map(projInvoice => {
                if (
                  projInvoice.sequence ===
                  projInvoiceInvalid[Number(key)].sequence
                ) {
                  projInvoice.invoice_validation_internal_error = true;
                  projInvoice.issue_date_conflict_internal_error = true;
                }
                return projInvoice;
              }),
            );
          });
        }
      }
    }
  }

  useEffect(() => {
    let checkInvoiceErrors = false;

    const projInvoiceGeneralError = projectInvoices.filter(
      projectInvoice => projectInvoice.invoice_validation_general_error,
    );

    if (projInvoiceGeneralError) {
      if (projInvoiceGeneralError.length > 0) {
        Object.keys(projInvoiceGeneralError).forEach(key => {
          const projInvoicePrevious = projectInvoices.find(
            projectInvoice =>
              projectInvoice.sequence !==
                projInvoiceGeneralError[Number(key)].sequence &&
              projectInvoice.previous_series ===
                projInvoiceGeneralError[Number(key)].series &&
              projectInvoice.previous_number ===
                projInvoiceGeneralError[Number(key)].number &&
              moment(projectInvoice.previous_issue_date).format(
                'DD/MM/YYYY',
              ) ===
                moment(projInvoiceGeneralError[Number(key)].issue_date).format(
                  'DD/MM/YYYY',
                ) &&
              projectInvoice.previous_title_series ===
                projInvoiceGeneralError[Number(key)].title_series &&
              projectInvoice.previous_title_number ===
                projInvoiceGeneralError[Number(key)].title_number &&
              projectInvoice.previous_title_installment ===
                projInvoiceGeneralError[Number(key)].title_installment,
          );

          if (projInvoicePrevious) {
            const projInvoiceGeneralConflict = projectInvoices.find(
              projectInvoice =>
                !projectInvoice.deleted &&
                projectInvoice.sequence !==
                  projInvoiceGeneralError[Number(key)].sequence &&
                projectInvoice.series ===
                  projInvoiceGeneralError[Number(key)].series &&
                projectInvoice.number ===
                  projInvoiceGeneralError[Number(key)].number &&
                moment(projectInvoice.issue_date).format('DD/MM/YYYY') ===
                  moment(
                    projInvoiceGeneralError[Number(key)].issue_date,
                  ).format('DD/MM/YYYY') &&
                projectInvoice.title_series ===
                  projInvoiceGeneralError[Number(key)].title_series &&
                projectInvoice.title_number ===
                  projInvoiceGeneralError[Number(key)].title_number &&
                projectInvoice.title_installment ===
                  projInvoiceGeneralError[Number(key)].title_installment,
            );

            if (!projInvoiceGeneralConflict) {
              setProjectInvoices(
                projectInvoices.map(projInvoice => {
                  if (
                    projInvoice.sequence ===
                    projInvoiceGeneralError[Number(key)].sequence
                  ) {
                    projInvoice.invoice_validation_general_error = false;
                    projInvoice.issue_date_conflict_general_error = false;
                    checkInvoiceErrors = true;
                  }
                  return projInvoice;
                }),
              );
            }
          }
        });
      }
    }

    const projInvoiceInternalError = projectInvoices.filter(
      projectInvoice => projectInvoice.invoice_validation_internal_error,
    );

    if (projInvoiceInternalError) {
      if (projInvoiceInternalError.length > 0) {
        Object.keys(projInvoiceInternalError).forEach(key => {
          let conflict = false;

          const projInvoiceInternalConflict = projectInvoices.filter(
            projectInvoice =>
              !projectInvoice.deleted &&
              projectInvoice.sequence !==
                projInvoiceInternalError[Number(key)].sequence &&
              projectInvoice.series ===
                projInvoiceInternalError[Number(key)].series &&
              projectInvoice.number ===
                projInvoiceInternalError[Number(key)].number &&
              moment(projectInvoice.issue_date).format('DD/MM/YYYY') ===
                moment(projInvoiceInternalError[Number(key)].issue_date).format(
                  'DD/MM/YYYY',
                ) &&
              projectInvoice.title_series ===
                projInvoiceInternalError[Number(key)].title_series &&
              projectInvoice.title_number ===
                projInvoiceInternalError[Number(key)].title_number &&
              projectInvoice.title_installment ===
                projInvoiceInternalError[Number(key)].title_installment,
          );

          if (projInvoiceInternalConflict) {
            if (projInvoiceInternalConflict.length > 0) {
              conflict = true;
            }
          }

          if (!conflict) {
            setProjectInvoices(
              projectInvoices.map(projInvoice => {
                if (
                  projInvoice.sequence ===
                  projInvoiceInternalError[Number(key)].sequence
                ) {
                  projInvoice.invoice_validation_internal_error = false;
                  projInvoice.issue_date_conflict_internal_error = false;
                  checkInvoiceErrors = true;
                }
                return projInvoice;
              }),
            );
          }
        });
      }
    }

    if (checkInvoiceErrors) {
      setProjectInvoices(
        projectInvoices.map(projInvoice => {
          if (projInvoice.invoice_validation_internal_error) {
            projInvoice.invalid_invoice_error =
              'Nota fiscal e título já utilizados';
          } else if (projInvoice.invoice_validation_general_error) {
            projInvoice.invalid_invoice_error =
              'Nota fiscal e título já utilizados em outro projeto';
          } else {
            projInvoice.invalid_invoice_error = undefined;
          }

          if (projInvoice.issue_date_conflict_internal_error) {
            projInvoice.issue_date_error = 'Nota fiscal e título já utilizados';
          } else if (projInvoice.issue_date_conflict_general_error) {
            projInvoice.issue_date_error =
              'Nota fiscal e título já utilizados em outro projeto';
          } else if (projInvoice.issue_date_invalid_error) {
            projInvoice.issue_date_error = 'Data inválida';
          } else {
            projInvoice.issue_date_error = undefined;
          }

          return projInvoice;
        }),
      );
    }
  }, [projectInvoices]);

  function handleInvalidInvoices(): void {
    setProjectInvoices(
      projectInvoices.map(projInvoice => {
        if (projInvoice.invoice_validation_internal_error) {
          projInvoice.invalid_invoice_error =
            'Nota fiscal e título já utilizados';
        } else if (projInvoice.invoice_validation_general_error) {
          projInvoice.invalid_invoice_error =
            'Nota fiscal e título já utilizados em outro projeto';
        } else {
          projInvoice.invalid_invoice_error = undefined;
        }

        return projInvoice;
      }),
    );
  }

  function handleInvalidIssueDates(): void {
    setProjectInvoices(
      projectInvoices.map(projInvoice => {
        if (projInvoice.issue_date_conflict_internal_error) {
          projInvoice.issue_date_error = 'Nota fiscal e título já utilizados';
        } else if (projInvoice.issue_date_conflict_general_error) {
          projInvoice.issue_date_error =
            'Nota fiscal e título já utilizados em outro projeto';
        } else if (projInvoice.issue_date_invalid_error) {
          projInvoice.issue_date_error = 'Data inválida';
        } else {
          projInvoice.issue_date_error = undefined;
        }

        return projInvoice;
      }),
    );
  }

  async function handleInvoiceValidationsComplete(
    sequence: number,
  ): Promise<void> {
    await handleInvalidInvoiceGeneral(sequence);
    await handleInvalidInvoiceInternal(sequence);
    await handleInvalidInvoices();
    await handleInvalidIssueDates();
  }

  async function handleRowIssueDateChange(
    sequence: number,
    fieldValue: string,
  ): Promise<void> {
    const issueDate = moment(fieldValue, 'DD/MM/YYYY').toDate();

    setProjectInvoices(
      projectInvoices.map(projInvoice => {
        if (projInvoice.sequence === sequence) {
          projInvoice.issue_date = issueDate;
        }
        return projInvoice;
      }),
    );

    if (
      !moment(fieldValue, 'DD/MM/YYYY').isValid() ||
      issueDate.setHours(0, 0, 0, 0) > new Date().setHours(0, 0, 0, 0)
    ) {
      setProjectInvoices(
        projectInvoices.map(projInvoice => {
          if (projInvoice.sequence === sequence) {
            projInvoice.issue_date_invalid_error = true;
          }
          return projInvoice;
        }),
      );
    } else {
      setProjectInvoices(
        projectInvoices.map(projInvoice => {
          if (projInvoice.sequence === sequence) {
            projInvoice.issue_date_invalid_error = false;
          }
          return projInvoice;
        }),
      );
    }

    await handleInvoiceValidationsComplete(sequence);
  }

  async function handleRowSeriesChange(
    sequence: number,
    fieldValue: string,
  ): Promise<void> {
    setProjectInvoices(
      projectInvoices.map(projInvoice => {
        if (projInvoice.sequence === sequence) {
          projInvoice.series = fieldValue;
        }
        return projInvoice;
      }),
    );

    await handleInvoiceValidationsComplete(sequence);
  }

  async function handleRowNumberChange(
    sequence: number,
    fieldValue: string,
  ): Promise<void> {
    setProjectInvoices(
      projectInvoices.map(projInvoice => {
        if (projInvoice.sequence === sequence) {
          projInvoice.number = fieldValue;
        }
        return projInvoice;
      }),
    );

    await handleInvoiceValidationsComplete(sequence);
  }

  function handleRowGrossPriceChange(
    sequence: number,
    fieldValue: string,
  ): void {
    let grossPriceConv = fieldValue;
    let grossPriceValue = 0;

    grossPriceConv = grossPriceConv.replaceAll('R$', '');
    grossPriceConv = grossPriceConv.replaceAll('.', '');
    grossPriceConv = grossPriceConv.replaceAll(',', '.');

    if (!Number.isNaN(Number(parseFloat(grossPriceConv)))) {
      grossPriceValue = parseFloat(grossPriceConv);
    }

    setProjectInvoices(
      projectInvoices.map(projInvoice => {
        if (projInvoice.sequence === sequence) {
          projInvoice.gross_price = grossPriceValue;
        }
        return projInvoice;
      }),
    );
  }

  function handleRowDiscountPriceChange(
    sequence: number,
    fieldValue: string,
  ): void {
    let discountPriceConv = fieldValue;
    let discountPriceValue = 0;

    discountPriceConv = discountPriceConv.replaceAll('R$', '');
    discountPriceConv = discountPriceConv.replaceAll('.', '');
    discountPriceConv = discountPriceConv.replaceAll(',', '.');

    if (!Number.isNaN(Number(parseFloat(discountPriceConv)))) {
      discountPriceValue = parseFloat(discountPriceConv);
    }

    setProjectInvoices(
      projectInvoices.map(projInvoice => {
        if (projInvoice.sequence === sequence) {
          projInvoice.discount_price = discountPriceValue;
        }
        return projInvoice;
      }),
    );
  }

  async function handleRowTitleSeriesChange(
    sequence: number,
    fieldValue: string,
  ): Promise<void> {
    setProjectInvoices(
      projectInvoices.map(projInvoice => {
        if (projInvoice.sequence === sequence) {
          projInvoice.title_series = fieldValue;
        }
        return projInvoice;
      }),
    );

    await handleInvoiceValidationsComplete(sequence);
  }

  async function handleRowTitleNumberChange(
    sequence: number,
    fieldValue: string,
  ): Promise<void> {
    setProjectInvoices(
      projectInvoices.map(projInvoice => {
        if (projInvoice.sequence === sequence) {
          projInvoice.title_number = fieldValue;
        }
        return projInvoice;
      }),
    );

    await handleInvoiceValidationsComplete(sequence);
  }

  async function handleRowTitleInstallmentChange(
    sequence: number,
    fieldValue: string,
  ): Promise<void> {
    setProjectInvoices(
      projectInvoices.map(projInvoice => {
        if (projInvoice.sequence === sequence) {
          projInvoice.title_installment = fieldValue;
        }
        return projInvoice;
      }),
    );

    await handleInvoiceValidationsComplete(sequence);
  }

  function handleRowFieldsEnabledFalseChange(sequence: number): void {
    setProjectInvoices(
      projectInvoices.map(projInvoice => {
        if (projInvoice.sequence === sequence) {
          projInvoice.fields_enabled = false;
        }
        return projInvoice;
      }),
    );
  }

  useEffect(() => {
    if (confirmInvoiceEnabled) {
      if (invoiceEnableSequence) {
        if (invoiceEnableSequence !== '') {
          setProjectInvoices(
            projectInvoices.map(projInvoice => {
              if (projInvoice.sequence === Number(invoiceEnableSequence)) {
                projInvoice.fields_enabled = true;
              }
              return projInvoice;
            }),
          );
          setInvoiceEnableSequence('');
          setConfirmInvoiceEnabled(false);
        }
      }
    }
  }, [confirmInvoiceEnabled, invoiceEnableSequence, projectInvoices]);

  useEffect(() => {
    if (confirmInvoiceDelete) {
      if (invoiceDeleteSequence) {
        if (invoiceDeleteSequence !== '') {
          setProjectInvoices(
            projectInvoices.map(projInvoice => {
              if (projInvoice.sequence === Number(invoiceDeleteSequence)) {
                projInvoice.deleted = true;
                projInvoice.project_record_reason = reasonDelete;
                projInvoice.issue_date_invalid_error = false;
                projInvoice.issue_date_conflict_general_error = false;
                projInvoice.issue_date_conflict_internal_error = false;
                projInvoice.invoice_validation_general_error = false;
                projInvoice.invoice_validation_internal_error = false;
                projInvoice.issue_date_error = undefined;
                projInvoice.invalid_invoice_error = undefined;
              }
              return projInvoice;
            }),
          );

          setInvoiceDeleteSequence('');
          setConfirmInvoiceDelete(false);
          setReasonDelete('');
        }
      }
    }
  }, [
    confirmInvoiceDelete,
    invoiceDeleteSequence,
    projectInvoices,
    reasonDelete,
  ]);

  function handleProjectInvoicesTableRowEnableFieldsButtons(
    rowProjectInvoice: IProjectInvoice,
  ): React.ReactNode {
    if (rowProjectInvoice.fields_enabled) {
      return (
        <UnlockButton
          iconSize={20}
          type="button"
          onClick={() => {
            handleRowFieldsEnabledFalseChange(rowProjectInvoice.sequence);
          }}
        />
      );
    }

    return (
      <LockButton
        iconSize={20}
        type="button"
        onClick={() => {
          setInvoiceEnableSequence(rowProjectInvoice.sequence.toString());
          setIsOpenWarning(true);
        }}
      />
    );
  }

  function handleDeleteRow(
    projectInvoiceReceived: IProjectInvoice,
  ): React.ReactNode | null {
    if (projectInvoiceReceived.sequence === projectInvoiceSequence.current) {
      return (
        <DeleteRowButton
          type="button"
          onClick={() => {
            handleDeleteRowTableProjectInvoices();
          }}
        />
      );
    }

    return null;
  }

  function handleProjectInvoicesTableRowButtons(
    rowProjectInvoice: IProjectInvoice,
  ): React.ReactNode | null {
    if (rowProjectInvoice.previous) {
      return (
        <>
          {handleProjectInvoicesTableRowEnableFieldsButtons(rowProjectInvoice)}
          <DeletePreviousButton
            iconSize={20}
            marginTop="0.8rem"
            marginLeft="1rem"
            type="button"
            onClick={() => {
              setInvoiceDeleteSequence(rowProjectInvoice.sequence.toString());
              setTitleDelete(
                `Deseja realmente excluir a NF ${rowProjectInvoice.number} ` +
                  `previamente registrada?`,
              );
              setSubTitleDelete(
                'A exclusão apenas será efetivada ao salvar o procedimento!',
              );
              setIsOpenDelete(true);
            }}
          />
        </>
      );
    }

    return handleDeleteRow(rowProjectInvoice);
  }

  function handleProjectInvoicesTableRow(
    rowProjectInvoice: IProjectInvoice,
  ): React.ReactNode {
    if (!rowProjectInvoice.deleted) {
      return (
        <tr key={rowProjectInvoice.sequence}>
          <ItemSequence>
            <ItemSequenceLabel>
              {`${'Sequência'.toUpperCase()}: ${rowProjectInvoice.sequence}`}
            </ItemSequenceLabel>
          </ItemSequence>

          <ItemContent>
            <ItemWrapper>
              <TableDateInput
                type="text"
                labelText="Data Emissão"
                upperCaseLabel
                defaultValue={rowProjectInvoice.issue_date.toLocaleDateString()}
                error={rowProjectInvoice.issue_date_error}
                disabled={
                  rowProjectInvoice.previous &&
                  !rowProjectInvoice.fields_enabled
                }
                onChange={e => {
                  handleRowIssueDateChange(
                    rowProjectInvoice.sequence,
                    e.target.value,
                  );
                }}
              />
            </ItemWrapper>

            <ItemWrapper>
              <TableDefaultInput
                type="text"
                labelText="Série NF"
                upperCaseLabel
                defaultValue={rowProjectInvoice.series}
                maxLength={3}
                error={rowProjectInvoice.invalid_invoice_error}
                disabled={
                  rowProjectInvoice.previous &&
                  !rowProjectInvoice.fields_enabled
                }
                onChange={e => {
                  handleRowSeriesChange(
                    rowProjectInvoice.sequence,
                    e.target.value,
                  );
                }}
              />
            </ItemWrapper>

            <ItemWrapper>
              <TableDefaultInput
                type="text"
                labelText="Número NF"
                upperCaseLabel
                defaultValue={rowProjectInvoice.number}
                maxLength={7}
                error={rowProjectInvoice.invalid_invoice_error}
                disabled={
                  rowProjectInvoice.previous &&
                  !rowProjectInvoice.fields_enabled
                }
                onChange={e => {
                  handleRowNumberChange(
                    rowProjectInvoice.sequence,
                    e.target.value,
                  );
                }}
              />
            </ItemWrapper>
          </ItemContent>

          <ItemContent>
            <ItemWrapper>
              <TableCurrencyInput
                type="text"
                labelText="Vlr. Total NF"
                upperCaseLabel
                defaultValue={rowProjectInvoice.gross_price}
                disabled={
                  rowProjectInvoice.previous &&
                  !rowProjectInvoice.fields_enabled
                }
                onSet={e => {
                  handleRowGrossPriceChange(
                    rowProjectInvoice.sequence,
                    e.target.value,
                  );
                }}
              />
            </ItemWrapper>

            <ItemWrapper>
              <TableCurrencyInput
                type="text"
                labelText="Vlr. Total Desc."
                upperCaseLabel
                defaultValue={rowProjectInvoice.discount_price}
                disabled={
                  rowProjectInvoice.previous &&
                  !rowProjectInvoice.fields_enabled
                }
                onSet={e => {
                  handleRowDiscountPriceChange(
                    rowProjectInvoice.sequence,
                    e.target.value,
                  );
                }}
              />
            </ItemWrapper>

            <ItemWrapper>
              <TableDefaultInput
                type="text"
                labelText="Série Duplicata"
                upperCaseLabel
                defaultValue={rowProjectInvoice.title_series}
                maxLength={3}
                error={rowProjectInvoice.invalid_invoice_error}
                disabled={
                  rowProjectInvoice.previous &&
                  !rowProjectInvoice.fields_enabled
                }
                onChange={e => {
                  handleRowTitleSeriesChange(
                    rowProjectInvoice.sequence,
                    e.target.value,
                  );
                }}
              />
            </ItemWrapper>
          </ItemContent>

          <ItemContent>
            <ItemWrapper>
              <TableDefaultInput
                type="text"
                labelText="Número Duplicata"
                upperCaseLabel
                defaultValue={rowProjectInvoice.title_number}
                maxLength={10}
                error={rowProjectInvoice.invalid_invoice_error}
                disabled={
                  rowProjectInvoice.previous &&
                  !rowProjectInvoice.fields_enabled
                }
                onChange={e => {
                  handleRowTitleNumberChange(
                    rowProjectInvoice.sequence,
                    e.target.value,
                  );
                }}
              />
            </ItemWrapper>

            <ItemWrapper>
              <TableDefaultInput
                type="text"
                labelText="Parcela Duplicata"
                upperCaseLabel
                defaultValue={rowProjectInvoice.title_installment}
                maxLength={2}
                error={rowProjectInvoice.invalid_invoice_error}
                disabled={
                  rowProjectInvoice.previous &&
                  !rowProjectInvoice.fields_enabled
                }
                onChange={e => {
                  handleRowTitleInstallmentChange(
                    rowProjectInvoice.sequence,
                    e.target.value,
                  );
                }}
              />
            </ItemWrapper>
          </ItemContent>

          <div
            style={{
              display: 'flex',
              justifyContent: 'flex-end',
              padding: '10px 5px 10px 10px',
            }}
          >
            {handleProjectInvoicesTableRowButtons(rowProjectInvoice)}
          </div>
        </tr>
      );
    }

    return <></>;
  }

  function handleProjectInvoicesTable(): React.ReactNode {
    if (projectInvoices) {
      if (projectInvoices.length > 0) {
        return (
          <Table>
            {loading && (
              <div
                style={{
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'center',
                }}
              >
                <PropagateLoader color="#276c8d" />
              </div>
            )}
            {projectInvoices?.map(projectInvoice =>
              handleProjectInvoicesTableRow(projectInvoice),
            )}
          </Table>
        );
      }
    }

    return <></>;
  }

  return (
    <Container>
      <BreadCrumb
        main="Solicitações"
        path="/projects/main"
        firstChildPath="/projects/sponsorships"
        firstChild="Pagamentos"
        secondChild="Atualização Solicitação"
        mainIcon={FaProjectDiagram}
        firstChildIcon={FaMoneyBillWave}
        secondChildIcon={FaMoneyBillWave}
        mainColored
        firstChildColored
      />

      <ProjectInfo project_id={state.id} />

      <form onSubmit={handleSubmit(submitForm)}>
        <LabelContainer>
          <Label>Notas Fiscais</Label>
        </LabelContainer>

        <Content>{handleProjectInvoicesTable()}</Content>

        <ContentAddRow>
          <AddRowButton
            type="button"
            onClick={() => handleAddRowTableProjectInvoices()}
          />
        </ContentAddRow>

        <Content>
          <Wrapper>
            <TextArea
              name="record_note"
              labelFor="record_note"
              labelText="Observação"
              error={errors?.record_note?.message}
              ref={register}
              maxLength={1000}
            />
          </Wrapper>
        </Content>

        <Content>
          <UploadFiles
            type="anexo"
            project_temp_id={projectTemp}
            project_type="PATROCINIO"
            sequence={8}
            user_id={user.id}
          />
        </Content>

        <ButtonContainer>
          <SaveButton
            type="button"
            processing={isSaving}
            disabled={isSaving}
            onClick={() => onValidateConfirmProcedure()}
          />

          <ExitButton
            style={{ marginLeft: 10 }}
            type="button"
            disabled={isSaving}
            onClick={() => setIsOpenExit(true)}
          />
        </ButtonContainer>
      </form>

      <ConfirmProcedureDialog
        isOpen={isOpenConfirmProcedure}
        setIsOpen={setIsOpenConfirmProcedure}
        onConfirm={onConfirmProcedure}
        title={titleConfirmProcedure}
        subTitle="O projeto será finalizado"
        opConfirm="Sim"
      />

      <ConfirmExitDialog
        isOpen={isOpenExit}
        setIsOpen={setIsOpenExit}
        onExit={onExit}
      />

      <ConfirmWarningDialog
        isOpen={isOpenWarning}
        setIsOpen={setIsOpenWarning}
        onConfirm={onConfirmWarning}
        title="Deseja realmente alterar a NF previamente registrada?"
        opConfirm="Sim, alterar"
      />

      <DeleteModal
        isOpen={isOpenDelete}
        onDelete={onDelete}
        title={titleDelete}
        subTitle={subTitleDelete}
        setIsOpen={setIsOpenDelete}
        setReason={setReasonDelete}
      />

      <NoticeDialog
        isOpen={isOpenNotice}
        type="error"
        title={titleNotice}
        subTitle={subTitleNotice}
        setIsOpen={setIsOpenNotice}
      />
    </Container>
  );
};

export default Procedure8;
