import styled, { css } from 'styled-components';

export const MainContainer = styled.section`
  max-width: 680px;
  margin-bottom: 1rem;
`;

export const Label = styled.label`
  ${({ theme }) => css`
    font-size: ${theme.fontSizes.default};
    white-space: nowrap;
    color: ${theme.colors.labelInput};
  `}
`;

export const Container = styled.section`
  ${({ theme }) => css`
    width: 100%;
    max-width: 680px;
    background: ${theme.colors.backgroundContainer};
    border-radius: ${theme.radius.small};
    border: 1px solid #e4e4e4;
    margin-bottom: 2rem;
    padding: 2rem 2rem 2rem 2rem;
  `}
`;

export const Wrapper = styled.div`
  @media (max-width: 1010px) {
    width: 100% !important;
  }
`;
