import React, { useState, useEffect, useCallback } from 'react';
import { Link } from 'react-router-dom';
import { FcCheckmark, FcCancel } from 'react-icons/fc';
import { FaBoxes, FaMoneyBillWave } from 'react-icons/fa';
import { PropagateLoader } from 'react-spinners';

import BreadCrumb from '~/components/BreadCrumb';
import DefaultInput from '~/components/Inputs/Default';
import Badge from '~/components/Badge';
import EditButton from '~/components/Buttons/Edit';
import DeleteButton from '~/components/Buttons/Delete';
import Pagination from '~/components/Pagination';
import NewButton from '~/components/Buttons/New';
import ConfirmDeleteDialog from '~/components/Dialogs/ConfirmDelete';

import { useToast } from '~/hooks/toast';

import api from '~/services/api';

import { Container, FilterContent, Table, Td, SymbolWrapper } from './styles';

interface ISponsorship {
  id: string;
  name: string;
  active: boolean;
  requires_req_files: boolean;
  requires_art_approval: boolean;
}

const AMOUNT_PER_PAGE = 5;

const List: React.FC = () => {
  const { addToast } = useToast();
  const [page, setPage] = useState(0);
  const [count, setCount] = useState(0);
  const [filter, setFilter] = useState('');
  const [lastRefresh, setLastRefresh] = useState(new Date());
  const [sponsorships, setSponsorships] = useState<ISponsorship[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [sponsorshipIdDelete, setSponsorshipIdDelete] = useState('');
  const [isOpenDelete, setIsOpenDelete] = useState(false);
  const [titleDelete, setTitleDelete] = useState('');
  const [subTitleDelete, setSubTitleDelete] = useState('');

  useEffect(() => {
    const loadSponsorships = async () => {
      await api
        .get(
          `/items/sponsorships?page=${page}&filter=${filter}&amount=${AMOUNT_PER_PAGE}`,
        )
        .then(response => {
          setSponsorships(response.data[0]);
          setCount(response.data[1]);
          setLoading(false);
        });
    };

    loadSponsorships();
  }, [page, filter, lastRefresh]);

  const onDelete = useCallback(
    async (id: string) => {
      try {
        await api.delete(`/items/sponsorships/${id}`).then(() => {
          setPage(0);
          setLastRefresh(new Date());

          addToast({
            type: 'success',
            title: 'Tipo de pagamento excluído!',
            description: 'Tipo de pagamento excluído com sucesso.',
          });
        });
      } catch (error: any) {
        addToast({
          type: 'error',
          title: 'Ocorreu um erro!',
          description: error.response.data.message,
        });
      }
      setSponsorshipIdDelete('');
      setTitleDelete('');
      setSubTitleDelete('');
    },
    [addToast],
  );

  return (
    <Container style={{ minHeight: 600 }}>
      <BreadCrumb
        main="Itens"
        path="/items/main"
        firstChild="Tipos de Pagamentos"
        mainIcon={FaBoxes}
        firstChildIcon={FaMoneyBillWave}
        mainColored
      />

      <FilterContent>
        <DefaultInput
          name="search"
          labelText="Pesquisar"
          type="text"
          onChange={event => {
            setFilter(event.target.value);
            setPage(0);
          }}
        />
      </FilterContent>

      <Table>
        <thead>
          <tr>
            <th style={{ width: '30%', textAlign: 'left' }}>Nome</th>
            <th style={{ width: '20%', textAlign: 'center' }}>
              Exige Arq. Req.
            </th>
            <th style={{ width: '20%', textAlign: 'center' }}>
              Exige Aprov. Arte
            </th>
            <th style={{ width: '15%', textAlign: 'center' }}>Situação</th>
            <th style={{ width: '15%' }} />
          </tr>
        </thead>

        <tbody>
          {loading ? (
            <tr>
              <Td colSpan={5} style={{ padding: '3rem', textAlign: 'center' }}>
                <PropagateLoader color="#276c8d" />
              </Td>
            </tr>
          ) : sponsorships.length > 0 ? (
            sponsorships.map(sponsorship => (
              <tr key={sponsorship.id}>
                <Td style={{ width: '30%', textAlign: 'left' }} heading="NOME">
                  {sponsorship.name}
                </Td>
                <Td style={{ width: '20%' }} heading="EXIGE ARQ. REQ.">
                  <SymbolWrapper>
                    {sponsorship.requires_req_files ? (
                      <Badge label="Sim" type="positive" />
                    ) : (
                      <Badge label="Não" type="negative" />
                    )}
                  </SymbolWrapper>
                </Td>
                <Td
                  style={{ width: '20%', textAlign: 'center' }}
                  heading="EXIGE APROV. ARTE"
                >
                  <SymbolWrapper>
                    {sponsorship.requires_art_approval ? (
                      <Badge label="Sim" type="positive" />
                    ) : (
                      <Badge label="Não" type="negative" />
                    )}
                  </SymbolWrapper>
                </Td>
                <Td style={{ width: '15%' }} heading="SITUAÇÃO">
                  <SymbolWrapper>
                    {sponsorship.active ? (
                      <FcCheckmark size={22} />
                    ) : (
                      <FcCancel size={22} />
                    )}
                  </SymbolWrapper>
                </Td>
                <Td
                  style={{ width: '15%', display: 'flex', textAlign: 'center' }}
                  heading={' '}
                >
                  <Link
                    to={{
                      pathname: `/items/sponsorships/edit/${sponsorship.id}`,
                      state: sponsorship,
                    }}
                  >
                    <EditButton />
                  </Link>

                  <DeleteButton
                    style={{ marginLeft: '0.5rem' }}
                    onClick={() => {
                      setSponsorshipIdDelete(sponsorship.id);
                      setIsOpenDelete(true);
                      setTitleDelete(
                        `Deseja realmente excluir o item ${sponsorship.name}?`,
                      );
                      setSubTitleDelete(
                        'Esta operação não poderá ser desfeita!',
                      );
                    }}
                  />
                </Td>
              </tr>
            ))
          ) : (
            <tr>
              <Td colSpan={5} style={{ textAlign: 'center' }}>
                Nenhuma informação disponível
              </Td>
            </tr>
          )}
        </tbody>
      </Table>

      <div style={{ display: 'flex', marginTop: '2rem' }}>
        <Pagination
          page={page}
          count={count}
          amountPerPage={AMOUNT_PER_PAGE}
          setPage={setPage}
        />

        <Link to="/items/sponsorships/new">
          <NewButton />
        </Link>
      </div>

      <ConfirmDeleteDialog
        isOpen={isOpenDelete}
        title={titleDelete}
        subTitle={subTitleDelete}
        setIsOpen={setIsOpenDelete}
        onClose={() => {
          onDelete(sponsorshipIdDelete);
        }}
      />
    </Container>
  );
};

export default List;
