import React from 'react';

import { Container } from './styles';

interface IBadgeProps {
  label: string;
  type: 'positive' | 'negative';
}

const Badge: React.FC<IBadgeProps> = ({ label, type }: IBadgeProps) => {
  return <Container type={type}>{label}</Container>;
};

export default Badge;
