import React, { useCallback, useEffect, useState, useRef } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { PropagateLoader } from 'react-spinners';
import { FaProjectDiagram, FaMoneyBillWave } from 'react-icons/fa';
import { v4 as uuidv4 } from 'uuid';

import BreadCrumb from '~/components/BreadCrumb';
import ProjectInfo from '~/components/ProjectInfo/Default';
import TableDefaultInput from '~/components/TableInputs/Default';
import TableWholeNumberInput from '~/components/TableInputs/WholeNumber';
import TableCurrencyInput from '~/components/TableInputs/Currency';
import TableSelect from '~/components/TableSelect';
import TableDynamicCurrencyInput from '~/components/TableInputs/DynamicCurrency';
import TablePercentageInput from '~/components/TableInputs/Percentage';
import LockButton from '~/components/Buttons/Lock';
import UnlockButton from '~/components/Buttons/Unlock';
import AddRowButton from '~/components/Buttons/AddRow';
import DeleteRowButton from '~/components/Buttons/DeleteRow';
import TextArea from '~/components/TextArea';
import UploadFiles from '~/components/UploadFiles';
import SaveButton from '~/components/Buttons/Save';
import ExitButton from '~/components/Buttons/Exit';
import ConfirmProcedureDialog from '~/components/Dialogs/ConfirmProcedure';
import ConfirmExitDialog from '~/components/Dialogs/ConfirmExit';
import NoticeDialog from '~/components/Dialogs/Notice';

import { useAuth } from '~/hooks/auth';
import { useToast } from '~/hooks/toast';

import api from '~/services/api';

import {
  Container,
  Content,
  ContentAddRow,
  Wrapper,
  LabelContainer,
  Label,
  WarningLabelContainer,
  WarningLabel,
  Table,
  ItemSequence,
  ItemSequenceLabel,
  ItemContent,
  ItemWrapper,
  ItemSubWrapper,
  ItemInfoWrapper,
  ItemButtonsWrapper,
  ButtonContainer,
} from './styles';

interface IProjectSponsorshipSheet {
  id: string;
  amount: number;
  sheet_price: number;
  total_price: number;
  sheet_pattern: {
    name: string;
  };
  sheet_thickness: {
    description: string;
  };
  user_update_id: string;
}

interface IProjectTaxDeduction {
  basis: number;
  percentage: number;
  price: number;
  sequence: number;
  tax_deduction_type: {
    value: string;
  };
  project_id: string;
  user_inclusion_id: string;
  user_update_id: string;
  basis_enabled: boolean;
  basis_error: string | undefined;
}

interface IFormProcedure7 {
  type: string;
  code: string;
  sponsorship_id: string;
  user_update_id: string;
  validation_type: string;
  project_procedure_id: string;
  record_description: string;
  record_note: string;
  project_temp_id: string;
  project_tax_deductions: IProjectTaxDeduction[];
  project_sponsorship_sheets: IProjectSponsorshipSheet[];
}

interface ILocation {
  id: string;
  type: string;
  code: string;
  price: number;
  total_price: number;
  gross_discount_price: number;
  erp_rep_code: string;
  status: string;
  project_procedure_id: string;
  project_procedure: {
    user_type: string;
  };
}

const Procedure7: React.FC = () => {
  const history = useHistory();
  const { user } = useAuth();
  const { addToast } = useToast();
  const { state } = useLocation<ILocation>();
  const [taxDeductionTypes, setTaxDeductionTypes] = useState<
    {
      id: string;
      name: string;
      percentage: number;
    }[]
  >();
  const [
    previousProjectSponsorshipSheets,
    setPreviousProjectSponsorshipSheets,
  ] = useState<IProjectSponsorshipSheet[]>();
  const projectSponsorshipSheets = useRef<IProjectSponsorshipSheet[]>([]);
  const [projectTaxDeductions, setProjectTaxDeductions] = useState<
    IProjectTaxDeduction[]
  >([]);
  const [
    previousLoadProjectSponsorshipSheets,
    setPreviousLoadProjectSponsorshipSheets,
  ] = useState(false);
  const [
    previousProjectSponsorshipSheetsLoaded,
    setPreviousProjectSponsorshipSheetsLoaded,
  ] = useState(false);
  const [loadProjectTaxDeductions, setLoadProjectTaxDeductions] =
    useState(false);
  const [projectTaxDeductionsLoaded, setProjectTaxDeductionsLoaded] =
    useState(false);
  const [loadingProjectSponsorshipSheets, setLoadingProjectSponsorshipSheets] =
    useState<boolean>(true);
  const [loadingProjectTaxDeductions, setLoadingProjectTaxDeductions] =
    useState<boolean>(true);
  const [projectTempFilled, setProjectTempFilled] = useState(false);
  const [projectTemp, setProjectTemp] = useState('');
  const [isOpenConfirmProcedure, setIsOpenConfirmProcedure] = useState(false);
  const [titleConfirmProcedure, setTitleConfirmProcedure] = useState('');
  const [isOpenExit, setIsOpenExit] = useState(false);
  const [isOpenNotice, setIsOpenNotice] = useState(false);
  const [titleNotice, setTitleNotice] = useState('');
  const [subTitleNotice, setSubTitleNotice] = useState('');
  const [isSaving, setIsSaving] = useState(false);
  const totalSponsorshipSheetsPrice = useRef(0);
  const projectTaxDeductionSequence = useRef(0);

  const { handleSubmit, register, setValue, errors } =
    useForm<IFormProcedure7>();

  useEffect(() => {
    if (previousLoadProjectSponsorshipSheets) {
      if (previousProjectSponsorshipSheets) {
        if (previousProjectSponsorshipSheets.length > 0) {
          Object.keys(previousProjectSponsorshipSheets).forEach(key => {
            const projectSponsorshipSheet: IProjectSponsorshipSheet = {
              id: previousProjectSponsorshipSheets[Number(key)].id,
              amount: previousProjectSponsorshipSheets[Number(key)].amount,
              sheet_price:
                previousProjectSponsorshipSheets[Number(key)].sheet_price,
              total_price:
                previousProjectSponsorshipSheets[Number(key)].total_price,
              sheet_pattern: {
                name: previousProjectSponsorshipSheets[Number(key)]
                  .sheet_pattern.name,
              },
              sheet_thickness: {
                description:
                  previousProjectSponsorshipSheets[Number(key)].sheet_thickness
                    .description,
              },
              user_update_id: user.id,
            };

            projectSponsorshipSheets.current.push(projectSponsorshipSheet);
          });
        }

        setPreviousLoadProjectSponsorshipSheets(false);
        setPreviousProjectSponsorshipSheetsLoaded(true);
        setLoadingProjectSponsorshipSheets(false);
      }
    }
  }, [
    state,
    user,
    previousLoadProjectSponsorshipSheets,
    previousProjectSponsorshipSheets,
  ]);

  useEffect(() => {
    if (previousProjectSponsorshipSheets) {
      if (!previousProjectSponsorshipSheetsLoaded) {
        setPreviousLoadProjectSponsorshipSheets(true);
      }
    }
  }, [
    previousProjectSponsorshipSheetsLoaded,
    previousProjectSponsorshipSheets,
    state,
  ]);

  useEffect(() => {
    if (loadProjectTaxDeductions) {
      projectTaxDeductionSequence.current += 1;
      const firstProjectTaxDeduction: IProjectTaxDeduction = {
        basis:
          Math.round(
            (state.gross_discount_price + totalSponsorshipSheetsPrice.current) *
              100,
          ) / 100,
        percentage: 0,
        price: 0,
        sequence: projectTaxDeductionSequence.current,
        tax_deduction_type: { value: '' },
        project_id: state.id,
        user_inclusion_id: user.id,
        user_update_id: user.id,
        basis_enabled: false,
        basis_error: undefined,
      };

      setProjectTaxDeductions([firstProjectTaxDeduction]);
      setLoadProjectTaxDeductions(false);
      setProjectTaxDeductionsLoaded(true);
      setLoadingProjectTaxDeductions(false);
    }
  }, [state, user, loadProjectTaxDeductions, totalSponsorshipSheetsPrice]);

  useEffect(() => {
    if (taxDeductionTypes) {
      if (!projectTaxDeductionsLoaded) {
        setLoadProjectTaxDeductions(true);
      }
    }
  }, [taxDeductionTypes, projectTaxDeductionsLoaded]);

  useEffect(() => {
    if (state === undefined) {
      addToast({
        type: 'error',
        title: 'Operação inválida!',
        description: 'Projeto não localizado',
      });

      history.push('/projects/sponsorships');
    } else if (state.status.toUpperCase() === 'CANCELADO') {
      addToast({
        type: 'error',
        title: 'Operação inválida!',
        description: 'Projeto Cancelado',
      });

      history.push('/projects/sponsorships');
    } else if (state.status.toUpperCase() === 'FINALIZADO') {
      addToast({
        type: 'error',
        title: 'Operação inválida!',
        description: 'Projeto Finalizado',
      });

      history.push('/projects/sponsorships');
    } else {
      register('validation_type');
      register('project_temp_id');
      register('type');
      register('code');
      register('record_description');
      register('user_update_id');
      register('project_procedure_id');
      register('project_sponsorship_sheets');
      register('project_tax_deductions');

      setValue('type', 'PATROCINIO');
      setValue('code', state.code);
      setValue('user_update_id', user.id);
      setValue('project_procedure_id', state.project_procedure_id);

      api
        .get(`/projectsponsorshipsheets/projectall?project_id=${state.id}`)
        .then(response => {
          setPreviousProjectSponsorshipSheets(response.data);
        });

      api.get('/configurations/taxdeductiontypes/valid').then(response => {
        setTaxDeductionTypes(response.data);
        setLoadingProjectTaxDeductions(false);
      });

      if (!projectTempFilled) {
        setProjectTempFilled(true);
        setProjectTemp(uuidv4());
      }
    }
  }, [addToast, history, state, user, projectTempFilled, register, setValue]);

  const options = taxDeductionTypes?.map(taxDeductionType => ({
    value: taxDeductionType.id,
    label: taxDeductionType.name,
  }));

  useEffect(() => {
    setValue('project_temp_id', projectTemp);
  }, [projectTemp, setValue]);

  const submitForm = useCallback(
    async (data: IFormProcedure7) => {
      try {
        setIsSaving(true);

        await api.put(`/projects/${state.id}`, data);

        addToast({
          type: 'success',
          title: 'Projeto atualizado!',
          description: 'Projeto atualizado com sucesso.',
        });

        history.push('/projects/sponsorships');
      } catch (error: any) {
        setIsSaving(false);

        addToast({
          type: 'error',
          title: 'Ocorreu um erro!',
          description: error.response.data.message,
        });
      }
    },
    [addToast, history, state],
  );

  async function onValidateConfirmProcedure(): Promise<void> {
    const sponsorshipSheetRowsInvalidSheetPrice =
      projectSponsorshipSheets.current.filter(
        projSponsorshipSheet => projSponsorshipSheet.sheet_price <= 0,
      );

    const taxDeductionRowsInvalidType = projectTaxDeductions.filter(
      projTaxDeduction => projTaxDeduction.tax_deduction_type.value === '',
    );

    const taxDeductionRowsInvalidBasis = projectTaxDeductions.filter(
      projTaxDeduction =>
        projTaxDeduction.basis >
        state.gross_discount_price + totalSponsorshipSheetsPrice.current,
    );

    if (
      sponsorshipSheetRowsInvalidSheetPrice &&
      sponsorshipSheetRowsInvalidSheetPrice.length > 0
    ) {
      setTitleNotice('Valores inválidos em chapas!');
      setSubTitleNotice(
        'Não é possível enviar projeto com registro de chapas sem valor informado!',
      );
      setIsOpenNotice(true);
    } else if (
      taxDeductionRowsInvalidType &&
      taxDeductionRowsInvalidType.length > 0
    ) {
      setTitleNotice('Tipos inválidos em abatimentos fiscais!');
      setSubTitleNotice(
        'Não é possível enviar projeto com registro de abatimentos fiscais sem tipo selecionado!',
      );
      setIsOpenNotice(true);
    } else if (
      taxDeductionRowsInvalidBasis &&
      taxDeductionRowsInvalidBasis.length > 0
    ) {
      setTitleNotice('Bases de cálculo inválidos em abatimentos fiscais!');
      setSubTitleNotice(
        'Não é possível enviar projeto com bases de cálculo indevidos para abatimentos fiscais!',
      );
      setIsOpenNotice(true);
    } else {
      setTitleConfirmProcedure(
        `Confirma envio do projeto ${state.code} para aprovação geral?`,
      );
      setIsOpenConfirmProcedure(true);
    }
  }

  function onConfirmProcedure(): void {
    setValue('validation_type', 'APROVADO');
    setValue('record_description', `Cálculo de abatimentos fiscais`);

    if (
      projectSponsorshipSheets.current &&
      projectSponsorshipSheets.current.length > 0
    ) {
      setValue('project_sponsorship_sheets', projectSponsorshipSheets.current);
    }

    setValue('project_tax_deductions', projectTaxDeductions);

    handleSubmit(submitForm)();
  }

  const onExit = useCallback(() => {
    api.delete(`projectfilesreceived/projecttempid/${projectTemp}`);

    history.push('/projects/sponsorships');
  }, [history, projectTemp]);

  function handleProjectSponsorshipSheetsTotalPriceChange(
    previousTotalPrice: number,
    currentTotalPrice: number,
  ): void {
    const totSponsorshipSheetsPrice =
      totalSponsorshipSheetsPrice.current -
      previousTotalPrice +
      currentTotalPrice;

    totalSponsorshipSheetsPrice.current =
      Math.round((totSponsorshipSheetsPrice + Number.EPSILON) * 100) / 100;

    setProjectTaxDeductions(
      projectTaxDeductions.map(projTaxDeduction => {
        projTaxDeduction.basis =
          Math.round(
            (state.gross_discount_price + totalSponsorshipSheetsPrice.current) *
              100,
          ) / 100;

        projTaxDeduction.price =
          Math.round(
            (((state.gross_discount_price +
              totalSponsorshipSheetsPrice.current) *
              projTaxDeduction.percentage) /
              100) *
              100,
          ) / 100;

        return projTaxDeduction;
      }),
    );
  }

  function handleRowSponsorshipSheetsSheetPriceChange(
    id: string,
    fieldValue: string,
  ): void {
    let sheetPriceConv = fieldValue;
    let sheetPriceValue = 0;
    const previousProjSponsorshipSheets = projectSponsorshipSheets.current.find(
      projSponsorshipSheet => projSponsorshipSheet.id === id,
    );
    let previousTotalPrice = 0;
    let currentTotalPrice = 0;

    if (previousProjSponsorshipSheets) {
      previousTotalPrice = previousProjSponsorshipSheets.total_price;
    }

    sheetPriceConv = sheetPriceConv.replaceAll('R$', '');
    sheetPriceConv = sheetPriceConv.replaceAll('.', '');
    sheetPriceConv = sheetPriceConv.replaceAll(',', '.');

    if (!Number.isNaN(Number(parseFloat(sheetPriceConv)))) {
      sheetPriceValue = parseFloat(sheetPriceConv);
    }

    projectSponsorshipSheets.current = projectSponsorshipSheets.current.map(
      projSponsorshipSheet => {
        if (projSponsorshipSheet.id === id) {
          projSponsorshipSheet.sheet_price = sheetPriceValue;
          projSponsorshipSheet.total_price =
            projSponsorshipSheet.amount * sheetPriceValue;
          currentTotalPrice = projSponsorshipSheet.amount * sheetPriceValue;
        }
        return projSponsorshipSheet;
      },
    );

    handleProjectSponsorshipSheetsTotalPriceChange(
      previousTotalPrice,
      currentTotalPrice,
    );
  }

  function handleAddRowTableProjectTaxDeductions(): void {
    projectTaxDeductionSequence.current += 1;
    const newProjectTaxDeduction: IProjectTaxDeduction = {
      basis:
        Math.round(
          (state.gross_discount_price + totalSponsorshipSheetsPrice.current) *
            100,
        ) / 100,
      percentage: 0,
      price: 0,
      sequence: projectTaxDeductionSequence.current,
      tax_deduction_type: { value: '' },
      project_id: state.id,
      user_inclusion_id: user.id,
      user_update_id: user.id,
      basis_enabled: false,
      basis_error: undefined,
    };

    setProjectTaxDeductions(prevProjectTaxDeductions => [
      ...prevProjectTaxDeductions,
      newProjectTaxDeduction,
    ]);
  }

  function handleDeleteRowTableProjectTaxDeductions(): void {
    setProjectTaxDeductions(
      projectTaxDeductions.filter(
        projTaxDeduction =>
          projTaxDeduction.sequence !== projectTaxDeductionSequence.current,
      ),
    );

    projectTaxDeductionSequence.current -= 1;
  }

  function handleRowTaxDeductionsTypeChange(
    sequence: number,
    fieldValue: string,
  ): void {
    projectTaxDeductions.map(projTaxDeduction => {
      if (projTaxDeduction.sequence === sequence) {
        projTaxDeduction.tax_deduction_type.value = fieldValue;
      }
      return projTaxDeduction;
    });

    if (taxDeductionTypes) {
      const rowTaxDeductionType = taxDeductionTypes.find(
        taxDeductionType => taxDeductionType.id === fieldValue,
      );

      if (rowTaxDeductionType) {
        if (rowTaxDeductionType.percentage) {
          setProjectTaxDeductions(
            projectTaxDeductions.map(projTaxDeduction => {
              if (projTaxDeduction.sequence === sequence) {
                projTaxDeduction.percentage = rowTaxDeductionType.percentage;
              }
              return projTaxDeduction;
            }),
          );

          handleRowTaxDeductionsPercentageChange(
            sequence,
            new Intl.NumberFormat('pt-BR', {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            }).format(rowTaxDeductionType.percentage),
          );
        }
      }
    }
  }

  function handleRowTaxDeductionsBasisChange(
    sequence: number,
    fieldValue: string,
  ): void {
    let basisConv = fieldValue;
    let basisValue = 0;

    basisConv = basisConv.replaceAll('R$', '');
    basisConv = basisConv.replaceAll('.', '');
    basisConv = basisConv.replaceAll(',', '.');

    if (!Number.isNaN(Number(parseFloat(basisConv)))) {
      basisValue = parseFloat(basisConv);
    }

    setProjectTaxDeductions(
      projectTaxDeductions.map(projTaxDeduction => {
        if (projTaxDeduction.sequence === sequence) {
          projTaxDeduction.basis = basisValue;
        }
        return projTaxDeduction;
      }),
    );

    if (
      basisValue >
      state.gross_discount_price + totalSponsorshipSheetsPrice.current
    ) {
      setProjectTaxDeductions(
        projectTaxDeductions.map(projTaxDeduction => {
          if (projTaxDeduction.sequence === sequence) {
            projTaxDeduction.basis_error = 'Valor acima do permitido';
          }
          return projTaxDeduction;
        }),
      );
    } else {
      setProjectTaxDeductions(
        projectTaxDeductions.map(projTaxDeduction => {
          if (projTaxDeduction.sequence === sequence) {
            projTaxDeduction.basis_error = undefined;
          }
          return projTaxDeduction;
        }),
      );
    }

    handleTaxDeductionsPriceCalculation(sequence, 'basis', basisValue);
  }

  function handleRowTaxDeductionsPercentageChange(
    sequence: number,
    fieldValue: string,
  ): void {
    let percentageConv = fieldValue;
    let percentageValue = 0;

    percentageConv = percentageConv.replaceAll('%', '');
    percentageConv = percentageConv.replaceAll('.', '');
    percentageConv = percentageConv.replaceAll(',', '.');

    if (!Number.isNaN(Number(parseFloat(percentageConv)))) {
      percentageValue = parseFloat(percentageConv);
    }

    setProjectTaxDeductions(
      projectTaxDeductions.map(projTaxDeduction => {
        if (projTaxDeduction.sequence === sequence) {
          projTaxDeduction.percentage = percentageValue;
        }
        return projTaxDeduction;
      }),
    );

    handleTaxDeductionsPriceCalculation(
      sequence,
      'percentage',
      percentageValue,
    );
  }

  function handleTaxDeductionsPriceCalculation(
    sequence: number,
    type: string,
    value: number,
  ): void {
    const currentRow = projectTaxDeductions.find(
      projTaxDeduction => projTaxDeduction.sequence === sequence,
    );

    if (currentRow) {
      if (type === 'basis') {
        handleRowTaxDeductionsPriceChange(
          sequence,
          Math.round(((value * currentRow.percentage) / 100) * 100) / 100,
        );
      } else if (type === 'percentage') {
        handleRowTaxDeductionsPriceChange(
          sequence,
          Math.round(((value * currentRow.basis) / 100) * 100) / 100,
        );
      }
    }
  }

  function handleRowTaxDeductionsPriceChange(
    sequence: number,
    fieldValue: number,
  ): void {
    setProjectTaxDeductions(
      projectTaxDeductions.map(projTaxDeduction => {
        if (projTaxDeduction.sequence === sequence) {
          projTaxDeduction.price = fieldValue;
        }
        return projTaxDeduction;
      }),
    );
  }

  function handleRowTaxDeductionsBasisEnabledChange(
    sequence: number,
    enabled: boolean,
  ): void {
    setProjectTaxDeductions(
      projectTaxDeductions.map(projTaxDeduction => {
        if (projTaxDeduction.sequence === sequence) {
          projTaxDeduction.basis_enabled = enabled;
        }
        return projTaxDeduction;
      }),
    );
  }

  function handleProjectSponsorshipSheetsTable(): React.ReactNode {
    if (projectSponsorshipSheets.current) {
      if (projectSponsorshipSheets.current.length > 0) {
        return (
          <Table>
            {loadingProjectSponsorshipSheets && (
              <div
                style={{
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'center',
                }}
              >
                <PropagateLoader color="#276c8d" />
              </div>
            )}
            {projectSponsorshipSheets.current?.map(projectSponsorshipSheet => (
              <tr key={projectSponsorshipSheet.id}>
                <ItemSequence />

                <ItemContent>
                  <ItemWrapper>
                    <TableDefaultInput
                      type="text"
                      labelText="Padrão"
                      upperCaseLabel
                      defaultValue={projectSponsorshipSheet.sheet_pattern.name}
                      disabled
                    />
                  </ItemWrapper>

                  <ItemWrapper>
                    <TableDefaultInput
                      type="text"
                      labelText="Espessura"
                      upperCaseLabel
                      defaultValue={
                        projectSponsorshipSheet.sheet_thickness.description
                      }
                      disabled
                    />
                  </ItemWrapper>

                  <ItemWrapper>
                    <TableWholeNumberInput
                      type="text"
                      labelText="Quantidade"
                      upperCaseLabel
                      defaultValue={projectSponsorshipSheet.amount}
                      disabled
                    />
                  </ItemWrapper>
                </ItemContent>

                <ItemContent style={{ marginBottom: '20px' }}>
                  <ItemWrapper>
                    <TableCurrencyInput
                      type="text"
                      labelText="Valor Chapa"
                      upperCaseLabel
                      defaultValue={projectSponsorshipSheet.sheet_price}
                      warning={
                        projectSponsorshipSheet.sheet_price <= 0
                          ? 'Necessário informar valor'
                          : undefined
                      }
                      onSet={e => {
                        handleRowSponsorshipSheetsSheetPriceChange(
                          projectSponsorshipSheet.id,
                          e.target.value,
                        );
                      }}
                    />
                  </ItemWrapper>

                  <ItemWrapper>
                    <TableDefaultInput
                      type="text"
                      labelText="Valor Total"
                      upperCaseLabel
                      value={new Intl.NumberFormat('pt-BR', {
                        style: 'currency',
                        currency: 'BRL',
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      }).format(projectSponsorshipSheet.total_price)}
                      disabled
                    />
                  </ItemWrapper>
                </ItemContent>
              </tr>
            ))}
          </Table>
        );
      }
    }

    return <></>;
  }

  function handleProjectSponsorshipSheets(): React.ReactNode {
    if (
      projectSponsorshipSheets.current &&
      projectSponsorshipSheets.current.length > 0
    ) {
      return (
        <>
          <LabelContainer>
            <Label>Chapas de MDF</Label>
          </LabelContainer>

          <Content>{handleProjectSponsorshipSheetsTable()}</Content>
        </>
      );
    }

    return <></>;
  }

  function checkDisabledProjectTaxDeductionsTable(): boolean {
    if (
      projectSponsorshipSheets.current &&
      projectSponsorshipSheets.current.length > 0
    ) {
      const sponsorshipSheetRowsInvalidSheetPrice =
        projectSponsorshipSheets.current.filter(
          projSponsorshipSheet => projSponsorshipSheet.sheet_price <= 0,
        );

      if (
        sponsorshipSheetRowsInvalidSheetPrice &&
        sponsorshipSheetRowsInvalidSheetPrice.length > 0
      ) {
        return true;
      }
    }

    return false;
  }

  function handleDeleteRow(
    projectTaxDeductionReceived: IProjectTaxDeduction,
  ): React.ReactNode | null {
    if (
      projectTaxDeductionReceived.sequence ===
      projectTaxDeductionSequence.current
    ) {
      return (
        <DeleteRowButton
          type="button"
          onClick={() => {
            handleDeleteRowTableProjectTaxDeductions();
          }}
        />
      );
    }

    return null;
  }

  function handleProjectTaxDeductionsTable(): React.ReactNode {
    if (projectTaxDeductions) {
      if (projectTaxDeductions.length > 0) {
        return (
          <Table>
            {loadingProjectTaxDeductions && (
              <div
                style={{
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'center',
                }}
              >
                <PropagateLoader color="#276c8d" />
              </div>
            )}
            {projectTaxDeductions?.map(projectTaxDeduction => (
              <tr key={projectTaxDeduction.sequence}>
                <ItemSequence>
                  <ItemSequenceLabel>
                    {`${'Sequência'.toUpperCase()}: ${
                      projectTaxDeduction.sequence
                    }`}
                  </ItemSequenceLabel>
                </ItemSequence>

                <ItemContent>
                  <ItemWrapper>
                    <TableSelect
                      label="Tipo"
                      upperCaseLabel
                      options={options}
                      loading={loadingProjectTaxDeductions}
                      onSet={(e: any) => {
                        handleRowTaxDeductionsTypeChange(
                          projectTaxDeduction.sequence,
                          e.value,
                        );
                      }}
                      isDisabled={checkDisabledProjectTaxDeductionsTable()}
                    />
                  </ItemWrapper>

                  <ItemWrapper>
                    <ItemSubWrapper>
                      <ItemInfoWrapper>
                        <TableDynamicCurrencyInput
                          type="text"
                          labelText="Base de Cálculo"
                          upperCaseLabel
                          value={projectTaxDeduction.basis}
                          error={projectTaxDeduction.basis_error}
                          onSet={e => {
                            handleRowTaxDeductionsBasisChange(
                              projectTaxDeduction.sequence,
                              e.target.value,
                            );
                          }}
                          disabled={
                            !projectTaxDeduction.basis_enabled ||
                            checkDisabledProjectTaxDeductionsTable()
                          }
                        />
                      </ItemInfoWrapper>

                      <ItemButtonsWrapper>
                        {projectTaxDeduction.basis_enabled ? (
                          <UnlockButton
                            iconSize={20}
                            type="button"
                            onClick={() => {
                              handleRowTaxDeductionsBasisEnabledChange(
                                projectTaxDeduction.sequence,
                                false,
                              );
                            }}
                            disabled={checkDisabledProjectTaxDeductionsTable()}
                          />
                        ) : (
                          <LockButton
                            iconSize={20}
                            type="button"
                            onClick={() => {
                              handleRowTaxDeductionsBasisEnabledChange(
                                projectTaxDeduction.sequence,
                                true,
                              );
                            }}
                            disabled={checkDisabledProjectTaxDeductionsTable()}
                          />
                        )}
                      </ItemButtonsWrapper>
                    </ItemSubWrapper>
                  </ItemWrapper>
                </ItemContent>

                <ItemContent>
                  <ItemWrapper>
                    <TablePercentageInput
                      type="text"
                      labelText="Percentual"
                      upperCaseLabel
                      value={projectTaxDeduction.percentage}
                      automaticValue
                      onSet={e => {
                        handleRowTaxDeductionsPercentageChange(
                          projectTaxDeduction.sequence,
                          e.target.value,
                        );
                      }}
                      disabled
                    />
                  </ItemWrapper>

                  <ItemWrapper>
                    <TableDefaultInput
                      type="text"
                      labelText="Valor"
                      upperCaseLabel
                      value={new Intl.NumberFormat('pt-BR', {
                        style: 'currency',
                        currency: 'BRL',
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      }).format(projectTaxDeduction.price)}
                      disabled
                    />
                  </ItemWrapper>
                </ItemContent>

                <div
                  style={{
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'flex-end',
                    padding: '10px 5px 10px 10px',
                  }}
                >
                  {handleDeleteRow(projectTaxDeduction)}
                </div>
              </tr>
            ))}
          </Table>
        );
      }
    }

    return <></>;
  }

  function handleProjectTaxDeductionsWarningLabel(): React.ReactNode {
    if (checkDisabledProjectTaxDeductionsTable()) {
      return (
        <Content>
          <WarningLabelContainer>
            <WarningLabel>Necessário preencher valores em chapas</WarningLabel>
          </WarningLabelContainer>
        </Content>
      );
    }

    return <></>;
  }

  return (
    <Container>
      <BreadCrumb
        main="Solicitações"
        path="/projects/main"
        firstChildPath="/projects/sponsorships"
        firstChild="Pagamentos"
        secondChild="Atualização Solicitação"
        mainIcon={FaProjectDiagram}
        firstChildIcon={FaMoneyBillWave}
        secondChildIcon={FaMoneyBillWave}
        mainColored
        firstChildColored
      />

      <ProjectInfo project_id={state.id} />

      <form onSubmit={handleSubmit(submitForm)}>
        {handleProjectSponsorshipSheets()}

        <LabelContainer>
          <Label>Abatimentos Fiscais</Label>
        </LabelContainer>

        {handleProjectTaxDeductionsWarningLabel()}

        <Content>{handleProjectTaxDeductionsTable()}</Content>

        <ContentAddRow>
          <AddRowButton
            type="button"
            onClick={() => handleAddRowTableProjectTaxDeductions()}
          />
        </ContentAddRow>

        <Content>
          <Wrapper>
            <TextArea
              name="record_note"
              labelFor="record_note"
              labelText="Observação"
              error={errors?.record_note?.message}
              ref={register}
              maxLength={1000}
            />
          </Wrapper>
        </Content>

        <Content>
          <UploadFiles
            type="anexo"
            project_temp_id={projectTemp}
            project_type="PATROCINIO"
            sequence={7}
            user_id={user.id}
          />
        </Content>

        <ButtonContainer>
          <SaveButton
            type="button"
            processing={isSaving}
            disabled={isSaving}
            onClick={() => onValidateConfirmProcedure()}
          />

          <ExitButton
            style={{ marginLeft: 10 }}
            type="button"
            disabled={isSaving}
            onClick={() => setIsOpenExit(true)}
          />
        </ButtonContainer>
      </form>

      <ConfirmProcedureDialog
        isOpen={isOpenConfirmProcedure}
        setIsOpen={setIsOpenConfirmProcedure}
        onConfirm={onConfirmProcedure}
        title={titleConfirmProcedure}
        subTitle="O projeto será enviado para o próximo procedimento"
        opConfirm="Sim"
      />

      <ConfirmExitDialog
        isOpen={isOpenExit}
        setIsOpen={setIsOpenExit}
        onExit={onExit}
      />

      <NoticeDialog
        isOpen={isOpenNotice}
        type="error"
        title={titleNotice}
        subTitle={subTitleNotice}
        setIsOpen={setIsOpenNotice}
      />
    </Container>
  );
};

export default Procedure7;
