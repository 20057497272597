import React from 'react';
import { Link } from 'react-router-dom';
import { FaProjectDiagram, FaArrowRight } from 'react-icons/fa';

import { getPermByPath } from '~/utils';

import { useAuth } from '~/hooks/auth';

import BreadCrumb from '~/components/BreadCrumb';

import {
  AnimatedContainer,
  Container,
  TaskButtonWrapper,
  TaskButton,
  ProjectIcon,
  ProjectType,
  GoIcon,
} from './styles';

import { PROJECTS_NAVIGATION_ANIMATION } from './animations';

import { PROJECTS_NAVIGATION } from '~/constants';

const MainContent: React.FC = () => {
  const { user } = useAuth();

  return (
    <AnimatedContainer variants={PROJECTS_NAVIGATION_ANIMATION}>
      <BreadCrumb
        main="Solicitações"
        path="/projects/main"
        mainIcon={FaProjectDiagram}
      />
      <Container>
        {Object.entries(PROJECTS_NAVIGATION).map(
          ([key, value]) =>
            getPermByPath(user.feature_paths, value.link) && (
              <TaskButtonWrapper key={`wrapper-${key}`}>
                <Link to={value.link}>
                  <TaskButton key={`card-${key}`} backgroundColor="#747474">
                    <ProjectIcon>{value.icon}</ProjectIcon>

                    <ProjectType>{key}</ProjectType>

                    <GoIcon>
                      <FaArrowRight size={20} />
                    </GoIcon>
                  </TaskButton>
                </Link>
              </TaskButtonWrapper>
            ),
        )}
      </Container>
    </AnimatedContainer>
  );
};

export default MainContent;
