import React from 'react';

import {
  FaCog,
  FaMobileAlt,
  FaTrashAlt,
  FaUsers,
  FaWrench,
} from 'react-icons/fa';

export default {
  'Alteração Manual de Solicitação': {
    icon: <FaWrench size={30} />,
    link: '/management/projectediting',
    perm: 'mgmt_proj_editing',
  },
  'Exclusão de Solicitação': {
    icon: <FaTrashAlt size={30} />,
    link: '/management/projectdeletion',
    perm: 'mgmt_proj_deletion',
  },
  Clientes: {
    icon: <FaUsers size={30} />,
    link: '/management/localclients',
    perm: 'mgmt_local_clients',
  },
  'Acessos Aplicativos Mobile': {
    icon: <FaMobileAlt size={30} />,
    link: '/management/mobileaccesses',
    perm: 'mgmt_mobile_accesses',
  },
  'Configurações Gerais': {
    icon: <FaCog size={30} />,
    link: '/management/generalsettings',
    perm: 'mgmt_general_settings',
  },
};
