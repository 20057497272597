import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { Controller, useForm } from 'react-hook-form';
import { FaProjectDiagram, FaCalendarAlt } from 'react-icons/fa';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { string, number } from 'yup';
import { v4 as uuidv4 } from 'uuid';
import moment from 'moment';

import BreadCrumb from '~/components/BreadCrumb';
import ProjectInfo from '~/components/ProjectInfo/Default';
import Select from '~/components/Select';
import DateInput from '~/components/Inputs/Date';
import WholeNumberInput from '~/components/Inputs/WholeNumber';
import CurrencyInput from '~/components/Inputs/Currency';
import TableSelect from '~/components/TableSelect';
import TableWholeNumberInput from '~/components/TableInputs/WholeNumber';
import TableCurrencyInput from '~/components/TableInputs/Currency';
import TableDefaultInput from '~/components/TableInputs/Default';
import TableTextArea from '~/components/TableTextArea';
import AddRowButton from '~/components/Buttons/AddRow';
import DeleteRowButton from '~/components/Buttons/DeleteRow';
import TextArea from '~/components/TextArea';
import UploadFiles from '~/components/UploadFiles';
import SaveButton from '~/components/Buttons/Save';
import ExitButton from '~/components/Buttons/Exit';
import ConfirmProcedureDialog from '~/components/Dialogs/ConfirmProcedure';
import ConfirmExitDialog from '~/components/Dialogs/ConfirmExit';
import NoticeDialog from '~/components/Dialogs/Notice';

import {
  getCurrencyFormatValue,
  getFormattedCurrency,
  getNumberFormatValue,
} from '~/utils';

import { useAuth } from '~/hooks/auth';
import { useToast } from '~/hooks/toast';

import api from '~/services/api';

import { Container, Content, Wrapper, Label, BorderContent } from './styles';

interface IFormProcedure2 {
  event_date: Date;
  estimated_event_participants_number: number;
  price_req_by_client: number;
  event: {
    value: string;
  };
  record_note: string;
}

interface ILocation {
  id: string;
  type: string;
  code: string;
  status: string;
  event_date: Date;
  estimated_event_participants_number: number;
  price_req_by_client: number;
  event: {
    id: string;
    name: string;
  };
  project_procedure_id: string;
}

const schema = Yup.object().shape({
  event_date: Yup.date().typeError('Campo obrigatório'),
  estimated_event_participants_number: Yup.string().when((value: string) => {
    if (Number.isNaN(Number(parseFloat(value)))) {
      return string().required('Quantidade inválida');
    }
    if (parseFloat(value) <= 0) {
      return number()
        .min(1, 'Quantidade inválida')
        .required('Quantidade inválida')
        .typeError('Quantidade inválida');
    }
    return string().notRequired();
  }),
});

const Procedure2: React.FC = () => {
  const history = useHistory();
  const { user } = useAuth();
  const { addToast } = useToast();
  const { state } = useLocation<ILocation>();
  const [giftTypeOptions, setGiftTypeOptions] = useState<{
    loaded: boolean;
    options: {
      value: string;
      label: string;
      price: number;
      enabled: boolean;
    }[];
  }>({ loaded: false, options: [] });
  const [projectGifts, setProjectGifts] = useState<
    {
      id?: string;
      temp_id: string;
      amount: number;
      unit_price: number;
      total_price: number;
      gift_type: {
        value: string;
      };
      deleted: boolean;
    }[]
  >([]);
  const [events, setEvents] = useState<
    {
      id: string;
      name: string;
    }[]
  >();
  const [eventCostTypeOptions, setEventCostTypeOptions] = useState<
    {
      value: string;
      label: string;
      enabled: boolean;
    }[]
  >([]);
  const [projectEventCosts, setProjectEventCosts] = useState<
    {
      temp_id: string;
      price: number;
      note: string;
      event_cost_type: {
        value: string;
      };
    }[]
  >([
    {
      temp_id: uuidv4(),
      price: 0,
      note: '',
      event_cost_type: {
        value: '',
      },
    },
  ]);
  const projectTemp = useRef(uuidv4());
  const [isOpenConfirmProcedure, setIsOpenConfirmProcedure] = useState(false);
  const [isOpenExit, setIsOpenExit] = useState(false);
  const [notice, setNotice] = useState<{
    title: string;
    subtitle: string;
  }>({
    title: '',
    subtitle: '',
  });
  const [isOpenNotice, setIsOpenNotice] = useState(false);
  const [isSaving, setIsSaving] = useState(false);

  const { handleSubmit, register, control, errors } = useForm<IFormProcedure2>({
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    if (state === undefined) {
      addToast({
        type: 'error',
        title: 'Operação inválida!',
        description: 'Solicitação não localizada',
      });

      history.push('/projects/events');
    } else if (state.status.toUpperCase() === 'CANCELADO') {
      addToast({
        type: 'error',
        title: 'Operação inválida!',
        description: 'Solicitação Cancelada',
      });

      history.push('/projects/events');
    } else if (state.status.toUpperCase() === 'FINALIZADO') {
      addToast({
        type: 'error',
        title: 'Operação inválida!',
        description: 'Solicitação Finalizada',
      });

      history.push('/projects/events');
    } else {
      const loadProjectGiftsAndGiftTypes = async () => {
        console.log('Teste 1010');

        let usedGiftTypes: string[] = [];

        await api
          .get<
            {
              id: string;
              amount: number;
              unit_price: number;
              total_price: number;
              gift_type_id: string;
            }[]
          >(`/projectgifts/projectall?project_id=${state.id}`)
          .then(response => {
            usedGiftTypes = response.data.map(p_g => p_g.gift_type_id);

            setProjectGifts(
              response.data.map(p_g => ({
                id: p_g.id,
                temp_id: uuidv4(),
                amount: p_g.amount,
                unit_price: p_g.unit_price,
                total_price: p_g.total_price,
                gift_type: {
                  value: p_g.gift_type_id,
                },
                deleted: false,
              })),
            );
          });

        await api.get('/configurations/gifttypes/valid').then(response => {
          setGiftTypeOptions({
            loaded: true,
            options: response.data.map((g_t: any) => ({
              value: g_t.id,
              label: g_t.name,
              price: g_t.price || 0,
              enabled:
                usedGiftTypes.find(u_g_t => u_g_t === g_t.id) === undefined,
            })),
          });
        });
      };

      loadProjectGiftsAndGiftTypes();

      api.get('/items/events/valid').then(response => {
        setEvents(response.data);
      });

      api.get('/configurations/eventcosttypes/valid').then(response => {
        setEventCostTypeOptions(
          response.data.map((e_c_t: any) => ({
            value: e_c_t.id,
            label: e_c_t.name,
            enabled: true,
          })),
        );
      });
    }
  }, [addToast, history, state, user]);

  const submitForm = useCallback(
    async (data: IFormProcedure2) => {
      try {
        setIsSaving(true);

        await api.put(`/projects/${state.id}`, {
          ...data,
          code: state.code,
          estimated_event_participants_number: getNumberFormatValue(
            data.estimated_event_participants_number,
          ),
          event_id: data.event.value,
          price_req_by_client: getCurrencyFormatValue(data.price_req_by_client),
          project_event_costs: projectEventCosts,
          project_gifts: projectGifts,
          project_procedure_id: state.project_procedure_id,
          project_temp_id: projectTemp.current,
          record_description: 'Registro de custos',
          type: 'EVENTO',
          validation_type: 'APROVADO',
        });

        addToast({
          type: 'success',
          title: 'Solicitação atualizada!',
          description: 'Solicitação atualizada com sucesso.',
        });

        history.push('/projects/events');
      } catch (error: any) {
        setIsSaving(false);

        addToast({
          type: 'error',
          title: 'Ocorreu um erro!',
          description: error.response.data.message,
        });
      }
    },
    [addToast, history, projectEventCosts, projectGifts, projectTemp, state],
  );

  const onExit = () => {
    api.delete(`projectfilesreceived/projecttempid/${projectTemp.current}`);

    history.push('/projects/events');
  };

  return (
    <Container>
      <BreadCrumb
        main="Solicitações"
        path="/projects/main"
        firstChildPath="/projects/events"
        firstChild="Eventos"
        secondChild="Atualização Solicitação"
        mainIcon={FaProjectDiagram}
        firstChildIcon={FaCalendarAlt}
        secondChildIcon={FaCalendarAlt}
        mainColored
        firstChildColored
      />

      <ProjectInfo project_id={state.id} />

      <form onSubmit={handleSubmit(submitForm)}>
        <div
          style={{
            maxWidth: 680,
            display: 'flex',
            flexWrap: 'wrap',
            columnGap: 20,
            rowGap: 12,
          }}
        >
          <Wrapper style={{ width: 300 }}>
            <Controller
              name="event"
              control={control}
              options={events?.map(event => ({
                value: event.id,
                label: event.name,
              }))}
              label="Tipo de Evento"
              defaultValue={{
                value: state.event.id,
                label: state.event.name,
              }}
              error={errors?.event?.value?.message}
              as={Select}
              loading={!events}
            />
          </Wrapper>

          <Wrapper style={{ width: 250 }}>
            <DateInput
              name="event_date"
              labelFor="event_date"
              labelText="Data Evento"
              type="date"
              defaultValue={moment(state?.event_date).format('YYYY-MM-DD')}
              error={errors?.event_date?.message}
              ref={register}
            />
          </Wrapper>

          <Wrapper style={{ width: 250, margin: 0 }}>
            <WholeNumberInput
              name="estimated_event_participants_number"
              labelFor="estimated_event_participants_number"
              labelText="Número Estimado de Participantes"
              defaultValue={state.estimated_event_participants_number}
              error={errors?.estimated_event_participants_number?.message}
              ref={register}
            />
          </Wrapper>

          <Wrapper style={{ width: 250, margin: 0 }}>
            <CurrencyInput
              name="price_req_by_client"
              labelFor="price_req_by_client"
              labelText="Cota Solicitada Pelo Cliente"
              defaultValue={state.price_req_by_client}
              error={errors?.price_req_by_client?.message}
              ref={register}
            />
          </Wrapper>
        </div>

        <div style={{ margin: '1rem 0 0.5rem 0' }}>
          <Label>Brindes</Label>
        </div>

        <div>
          {giftTypeOptions.loaded && (
            <>
              {projectGifts
                .filter(projectGift => !projectGift.deleted)
                .map(projectGift => (
                  <BorderContent
                    key={projectGift.temp_id}
                    style={{ marginBottom: 15 }}
                  >
                    <div
                      style={{
                        display: 'flex',
                        flexWrap: 'wrap',
                        columnGap: 10,
                        rowGap: 10,
                      }}
                    >
                      <Wrapper style={{ width: 250, margin: 0 }}>
                        <TableSelect
                          name={`gift_type_${projectGift.temp_id}`}
                          label="Tipo de Brinde"
                          options={giftTypeOptions.options.filter(
                            op => op.enabled,
                          )}
                          defaultValue={{
                            value: projectGift.gift_type.value,
                            label:
                              giftTypeOptions.options.find(
                                op => op.value === projectGift.gift_type.value,
                              )?.label || 'Selecione ...',
                          }}
                          disabled={projectGift.id}
                          onSet={(e: any) => {
                            const prevValue = projectGift.gift_type.value;

                            setProjectGifts(prevProjGifts =>
                              prevProjGifts.map(prevProjGift => {
                                if (
                                  prevProjGift.temp_id === projectGift.temp_id
                                ) {
                                  const findGiftType =
                                    giftTypeOptions.options.find(
                                      op => op.value === e.value,
                                    );

                                  return {
                                    ...prevProjGift,
                                    gift_type: {
                                      value: e.value,
                                    },
                                    unit_price: findGiftType?.price || 0,
                                    total_price:
                                      prevProjGift.amount *
                                      (findGiftType?.price || 0),
                                  };
                                }

                                return { ...prevProjGift };
                              }),
                            );

                            setGiftTypeOptions(prevGiftTypeOptions => ({
                              ...prevGiftTypeOptions,
                              options: prevGiftTypeOptions.options.map(op => {
                                if (op.value === prevValue) {
                                  return { ...op, enabled: true };
                                }

                                if (op.value === e.value) {
                                  return { ...op, enabled: false };
                                }

                                return { ...op };
                              }),
                            }));
                          }}
                        />
                      </Wrapper>

                      <Wrapper style={{ width: 150, margin: 0 }}>
                        <TableWholeNumberInput
                          name={`gift_amount_${projectGift.temp_id}`}
                          labelText="Quantidade"
                          defaultValue={projectGift.amount}
                          onSet={e => {
                            setProjectGifts(prevProjGifts =>
                              prevProjGifts.map(prevProjGift => {
                                if (
                                  prevProjGift.temp_id === projectGift.temp_id
                                ) {
                                  const amount = getNumberFormatValue(
                                    e.target.value,
                                  );

                                  return {
                                    ...prevProjGift,
                                    amount,
                                    total_price:
                                      amount * prevProjGift.unit_price,
                                  };
                                }

                                return { ...prevProjGift };
                              }),
                            );
                          }}
                        />
                      </Wrapper>

                      <Wrapper style={{ width: 200, margin: 0 }}>
                        {projectGift.id ? (
                          <TableCurrencyInput
                            name={`gift_c_i_unit_price_${projectGift.temp_id}`}
                            type="text"
                            labelText="Valor Unitário"
                            upperCaseLabel
                            defaultValue={projectGift.unit_price}
                            onSet={e => {
                              setProjectGifts(prevProjGifts =>
                                prevProjGifts.map(prevProjGift => {
                                  if (
                                    prevProjGift.temp_id === projectGift.temp_id
                                  ) {
                                    const unit_price = getCurrencyFormatValue(
                                      e.target.value,
                                    );

                                    return {
                                      ...prevProjGift,
                                      unit_price,
                                      total_price:
                                        unit_price * prevProjGift.amount,
                                    };
                                  }

                                  return { ...prevProjGift };
                                }),
                              );
                            }}
                          />
                        ) : (
                          <TableDefaultInput
                            name={`gift_d_i_unit_price_${projectGift.temp_id}`}
                            labelText="Valor Unitário"
                            value={getFormattedCurrency(projectGift.unit_price)}
                            disabled
                          />
                        )}
                      </Wrapper>

                      <Wrapper style={{ width: 200, margin: 0 }}>
                        <TableDefaultInput
                          name={`gift_total_price_${projectGift.temp_id}`}
                          labelText="Valor Total"
                          value={getFormattedCurrency(projectGift.total_price)}
                          disabled
                        />
                      </Wrapper>
                    </div>

                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'flex-end',
                        marginTop: 10,
                      }}
                    >
                      <DeleteRowButton
                        type="button"
                        onClick={() => {
                          setGiftTypeOptions(prevGiftTypeOptions => ({
                            ...prevGiftTypeOptions,
                            options: prevGiftTypeOptions.options.map(op => {
                              if (op.value === projectGift.gift_type.value) {
                                return { ...op, enabled: true };
                              }

                              return { ...op };
                            }),
                          }));

                          setProjectGifts(prevProjGifts =>
                            prevProjGifts.map(prevProjGift =>
                              prevProjGift.temp_id === projectGift.temp_id
                                ? { ...prevProjGift, deleted: true }
                                : { ...prevProjGift },
                            ),
                          );
                        }}
                      />
                    </div>
                  </BorderContent>
                ))}
            </>
          )}
        </div>

        <div
          style={{
            maxWidth: 680,
            display: 'flex',
            justifyContent: 'flex-end',
            margin: '1rem 0 2rem 0',
          }}
        >
          <AddRowButton
            type="button"
            onClick={() => {
              setProjectGifts(prevProjectGifts => [
                ...prevProjectGifts,
                {
                  temp_id: uuidv4(),
                  amount: 0,
                  unit_price: 0,
                  total_price: 0,
                  gift_type: {
                    value: '',
                  },
                  deleted: false,
                },
              ]);
            }}
          />
        </div>

        <div style={{ margin: '1rem 0 0.5rem 0' }}>
          <Label>Custos</Label>
        </div>

        <div>
          {eventCostTypeOptions.length > 0 && (
            <>
              {projectEventCosts.map(projectEventCost => (
                <BorderContent
                  key={projectEventCost.temp_id}
                  style={{ marginBottom: 15 }}
                >
                  <div
                    style={{
                      display: 'flex',
                      flexWrap: 'wrap',
                      columnGap: 10,
                      rowGap: 10,
                    }}
                  >
                    <Wrapper style={{ width: 250, margin: 0 }}>
                      <TableSelect
                        name={`event_cost_type_${projectEventCost.temp_id}`}
                        label="Tipo"
                        options={eventCostTypeOptions.filter(op => op.enabled)}
                        onSet={(e: any) => {
                          const prevValue =
                            projectEventCost.event_cost_type.value;

                          setProjectEventCosts(prevProjEventCosts =>
                            prevProjEventCosts.map(prevProjEventCost => {
                              if (
                                prevProjEventCost.temp_id ===
                                projectEventCost.temp_id
                              ) {
                                return {
                                  ...prevProjEventCost,
                                  event_cost_type: {
                                    value: e.value,
                                  },
                                };
                              }

                              return { ...prevProjEventCost };
                            }),
                          );

                          setEventCostTypeOptions(prevEventCostTypeOptions =>
                            prevEventCostTypeOptions.map(op => {
                              if (op.value === prevValue) {
                                return { ...op, enabled: true };
                              }

                              if (op.value === e.value) {
                                return { ...op, enabled: false };
                              }

                              return { ...op };
                            }),
                          );
                        }}
                      />
                    </Wrapper>

                    <Wrapper style={{ width: 150, margin: 0 }}>
                      <TableCurrencyInput
                        name={`event_cost_price_${projectEventCost.temp_id}`}
                        type="text"
                        labelText="Valor"
                        upperCaseLabel
                        defaultValue={0}
                        onSet={e => {
                          setProjectEventCosts(prevProjEventCosts =>
                            prevProjEventCosts.map(prevProjEventCost => {
                              if (
                                prevProjEventCost.temp_id ===
                                projectEventCost.temp_id
                              ) {
                                return {
                                  ...prevProjEventCost,
                                  price: getCurrencyFormatValue(e.target.value),
                                };
                              }

                              return { ...prevProjEventCost };
                            }),
                          );
                        }}
                      />
                    </Wrapper>

                    <Wrapper style={{ width: '100%', margin: 0 }}>
                      <TableTextArea
                        name={`event_cost_note_${projectEventCost.temp_id}`}
                        labelText="Observação"
                        upperCaseLabel
                        maxLength={2000}
                        onChange={e => {
                          setProjectEventCosts(prevProjEventCosts =>
                            prevProjEventCosts.map(prevProjEventCost => {
                              if (
                                prevProjEventCost.temp_id ===
                                projectEventCost.temp_id
                              ) {
                                return {
                                  ...prevProjEventCost,
                                  note: e.target.value,
                                };
                              }

                              return { ...prevProjEventCost };
                            }),
                          );
                        }}
                      />
                    </Wrapper>
                  </div>

                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'flex-end',
                      marginTop: 10,
                    }}
                  >
                    <DeleteRowButton
                      type="button"
                      onClick={() => {
                        setEventCostTypeOptions(prevEventCostTypeOptions =>
                          prevEventCostTypeOptions.map(op =>
                            op.value === projectEventCost.event_cost_type.value
                              ? { ...op, enabled: true }
                              : { ...op },
                          ),
                        );

                        setProjectEventCosts(prevProjEventCosts =>
                          prevProjEventCosts.filter(
                            prevProjEventCost =>
                              prevProjEventCost.temp_id !==
                              projectEventCost.temp_id,
                          ),
                        );
                      }}
                    />
                  </div>
                </BorderContent>
              ))}
            </>
          )}
        </div>

        <div
          style={{
            maxWidth: 680,
            display: 'flex',
            justifyContent: 'flex-end',
            margin: '1rem 0 2rem 0',
          }}
        >
          <AddRowButton
            type="button"
            onClick={() => {
              setProjectEventCosts(prevProjectEventCosts => [
                ...prevProjectEventCosts,
                {
                  temp_id: uuidv4(),
                  price: 0,
                  note: '',
                  event_cost_type: {
                    value: '',
                  },
                },
              ]);
            }}
          />
        </div>

        <Content>
          <Wrapper style={{ width: '100%' }}>
            <TextArea
              name="record_note"
              labelFor="record_note"
              labelText="Observação"
              error={errors?.record_note?.message}
              ref={register}
              maxLength={1000}
            />
          </Wrapper>
        </Content>

        <Content>
          <Wrapper style={{ width: '100%' }}>
            <UploadFiles
              type="anexo"
              project_temp_id={projectTemp.current}
              project_type="EVENTO"
              sequence={2}
              user_id={user.id}
            />
          </Wrapper>
        </Content>

        <div
          style={{
            display: 'flex',
            justifyContent: 'flex-end',
            marginTop: '2rem',
          }}
        >
          <SaveButton
            type="button"
            processing={isSaving}
            disabled={isSaving}
            onClick={() => {
              if (
                projectGifts.filter(projGift => projGift.gift_type.value === '')
                  .length > 0
              ) {
                setNotice({
                  title: 'Brindes inválidos!',
                  subtitle: 'Necessário selecionar tipo de brinde!',
                });
                setIsOpenNotice(true);
              } else if (
                projectGifts.filter(projGift => projGift.amount <= 0).length > 0
              ) {
                setNotice({
                  title: 'Existem brindes sem quantidade informada!',
                  subtitle:
                    'É necessário informar quantidade em todos os brindes.',
                });

                setIsOpenNotice(true);
              } else if (
                projectGifts.filter(projGift => projGift.unit_price <= 0)
                  .length > 0
              ) {
                setNotice({
                  title: 'Existem brindes sem valor!',
                  subtitle:
                    'É necessário informar valor em cadastro de tipos de brindes.',
                });

                setIsOpenNotice(true);
              } else if (projectEventCosts.length <= 0) {
                setNotice({
                  title: 'Custos inválidos!',
                  subtitle: 'Necessário informar custos!',
                });

                setIsOpenNotice(true);
              } else if (
                projectEventCosts.filter(
                  projEventCost => projEventCost.event_cost_type.value === '',
                ).length > 0
              ) {
                setNotice({
                  title: 'Tipos inválidos em custos!',
                  subtitle:
                    'Não é possível enviar solicitação sem tipo de custo selecionado!',
                });

                setIsOpenNotice(true);
              } else if (
                projectEventCosts.filter(
                  projEventCost => projEventCost.price <= 0,
                ).length > 0
              ) {
                setNotice({
                  title: 'Valores inválidos em custos!',
                  subtitle:
                    'Não é possível enviar solicitação sem valor de custo informado!',
                });

                setIsOpenNotice(true);
              } else {
                setIsOpenConfirmProcedure(true);
              }
            }}
          />

          <ExitButton
            style={{ marginLeft: 10 }}
            type="button"
            disabled={isSaving}
            onClick={() => setIsOpenExit(true)}
          />
        </div>
      </form>

      <ConfirmProcedureDialog
        isOpen={isOpenConfirmProcedure}
        setIsOpen={setIsOpenConfirmProcedure}
        onConfirm={() => handleSubmit(submitForm)()}
        title={`Confirma aprovação da solicitação ${state.code}?`}
        subTitle="A solicitação será enviada para o próximo procedimento"
        opConfirm="Sim"
      />

      <ConfirmExitDialog
        isOpen={isOpenExit}
        setIsOpen={setIsOpenExit}
        onExit={onExit}
      />

      <NoticeDialog
        isOpen={isOpenNotice}
        type="error"
        title={notice.title}
        subTitle={notice.subtitle}
        setIsOpen={setIsOpenNotice}
      />
    </Container>
  );
};

export default Procedure2;
