import React, { useCallback, useEffect, useState, useRef } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import moment from 'moment';
import { PropagateLoader } from 'react-spinners';
import { FaProjectDiagram, FaFolder } from 'react-icons/fa';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { string, number } from 'yup';
import { v4 as uuidv4 } from 'uuid';

import BreadCrumb from '~/components/BreadCrumb';
import ProjectInfo from '~/components/ProjectInfo/Default';
import WholeNumberInput from '~/components/Inputs/WholeNumber';
import LockButton from '~/components/Buttons/Lock';
import UnlockButton from '~/components/Buttons/Unlock';
import TableDefaultInput from '~/components/TableInputs/Default';
import TableDateInput from '~/components/TableInputs/Date';
import TableCurrencyInput from '~/components/TableInputs/Currency';
import TableWholeNumberInput from '~/components/TableInputs/WholeNumber';
import AddRowButton from '~/components/Buttons/AddRow';
import DeleteRowButton from '~/components/Buttons/DeleteRow';
import DeletePreviousButton from '~/components/Buttons/DeletePrevious';
import TextArea from '~/components/TextArea';
import UploadFiles from '~/components/UploadFiles';
import SaveButton from '~/components/Buttons/Save';
import ExitButton from '~/components/Buttons/Exit';
import ConfirmProcedureDialog from '~/components/Dialogs/ConfirmProcedure';
import ConfirmExitDialog from '~/components/Dialogs/ConfirmExit';
import ConfirmWarningDialog from '~/components/Dialogs/ConfirmWarning';
import DeleteModal from '~/components/Modals/OldDelete';
import NoticeDialog from '~/components/Dialogs/Notice';

import { useAuth } from '~/hooks/auth';
import { useToast } from '~/hooks/toast';

import api from '~/services/api';

import {
  Container,
  Content,
  ContentAddRow,
  Wrapper,
  LabelContainer,
  Label,
  Table,
  ItemSequence,
  ItemSequenceLabel,
  ItemContent,
  ItemWrapper,
  ButtonContainer,
} from './styles';

interface IPreviousProjectSentInvoice {
  id: string;
  number: string;
  series: string;
  issue_date: Date;
  net_price: number;
  amount: number;
  sequence: number;
  project_id: string;
  user_inclusion_id: string;
  user_update_id: string;
  project: {
    code: string;
    name: string;
  };
  user_inclusion: {
    name: string;
  };
  user_update: {
    name: string;
  };
  created_at: string;
}

interface IProjectSentInvoice {
  id: string;
  number: string;
  series: string;
  issue_date: Date;
  net_price: number;
  amount: number;
  sequence: number;
  project_id: string;
  user_inclusion_id: string;
  user_update_id: string;
  project_record_reason: string;
  validation_error: string | undefined;
  validation_general_error: boolean;
  validation_internal_error: boolean;
  issue_date_error: string | undefined;
  previous: boolean;
  fields_enabled: boolean;
  deleted: boolean;
  previous_number: string;
  previous_series: string;
  previous_issue_date: Date | undefined;
  created_at: Date;
}

interface IFormProcedure2 {
  type: string;
  code: string;
  item_total_amount: number;
  item_total_amount_temp: number;
  user_update_id: string;
  validation_type: string;
  project_procedure_id: string;
  record_description: string;
  record_note: string;
  project_temp_id: string;
  project_sent_invoices: IProjectSentInvoice[];
}

interface ILocation {
  id: string;
  type: string;
  code: string;
  item_total_amount: number;
  erp_rep_code: string;
  status: string;
  project_procedure_id: string;
}

const schema = Yup.object().shape({
  item_total_amount: Yup.string().when((value: string) => {
    if (Number.isNaN(Number(parseFloat(value)))) {
      return string().required('Valor inválido');
    }
    if (parseFloat(value) <= 0) {
      return number()
        .min(1, 'Valor inválido')
        .required('Valor inválido')
        .typeError('Valor inválido');
    }
    return string().notRequired();
  }),
});

const Procedure2: React.FC = () => {
  const history = useHistory();
  const { user } = useAuth();
  const { addToast } = useToast();
  const { state } = useLocation<ILocation>();
  const [previousProjectSentInvoices, setPreviousProjectSentInvoices] =
    useState<IPreviousProjectSentInvoice[]>();
  const [projectSentInvoices, setProjectSentInvoices] = useState<
    IProjectSentInvoice[]
  >([]);
  const [loadProjectSentInvoices, setloadProjectSentInvoices] = useState(false);
  const [projectSentInvoicesLoaded, setProjectSentInvoicesLoaded] =
    useState(false);
  const [loading, setLoading] = useState<boolean>(true);
  const [projectTempFilled, setProjectTempFilled] = useState(false);
  const [projectTemp, setProjectTemp] = useState('');
  const [isOpenConfirmProcedure, setIsOpenConfirmProcedure] = useState(false);
  const [titleConfirmProcedure, setTitleConfirmProcedure] = useState('');
  const [isOpenExit, setIsOpenExit] = useState(false);
  const [isOpenWarning, setIsOpenWarning] = useState(false);
  const [isOpenDelete, setIsOpenDelete] = useState(false);
  const [titleDelete, setTitleDelete] = useState('');
  const [subTitleDelete, setSubTitleDelete] = useState('');
  const [reasonDelete, setReasonDelete] = useState('');
  const [isOpenNotice, setIsOpenNotice] = useState(false);
  const [titleNotice, setTitleNotice] = useState('');
  const [subTitleNotice, setSubTitleNotice] = useState('');
  const [isSaving, setIsSaving] = useState(false);
  const projectSentInvoiceSequence = useRef(0);
  const [totalAmountEnabled, setTotalAmountEnabled] = useState(false);
  const projectTotalAmount = useRef(0);
  const projectSentInvoicesTotalAmount = useRef(0);
  const [sentInvoiceEnableSequence, setSentInvoiceEnableSequence] =
    useState('');
  const [confirmSentInvoiceEnabled, setConfirmSentInvoiceEnabled] =
    useState(false);
  const [sentInvoiceDeleteSequence, setSentInvoiceDeleteSequence] =
    useState('');
  const [confirmSentInvoiceDelete, setConfirmSentInvoiceDelete] =
    useState(false);

  const { handleSubmit, register, getValues, setValue, errors } =
    useForm<IFormProcedure2>({ resolver: yupResolver(schema) });

  useEffect(() => {
    if (loadProjectSentInvoices) {
      if (previousProjectSentInvoices) {
        if (previousProjectSentInvoices.length > 0) {
          Object.keys(previousProjectSentInvoices).forEach(key => {
            projectSentInvoiceSequence.current =
              previousProjectSentInvoices[Number(key)].sequence + 1;
            const previousProjectSentInvoice: IProjectSentInvoice = {
              id: previousProjectSentInvoices[Number(key)].id,
              number: previousProjectSentInvoices[Number(key)].number,
              series: previousProjectSentInvoices[Number(key)].series,
              issue_date: new Date(
                previousProjectSentInvoices[Number(key)].issue_date,
              ),
              net_price: previousProjectSentInvoices[Number(key)].net_price,
              amount: previousProjectSentInvoices[Number(key)].amount,
              sequence: previousProjectSentInvoices[Number(key)].sequence,
              project_id: previousProjectSentInvoices[Number(key)].project_id,
              user_inclusion_id:
                previousProjectSentInvoices[Number(key)].user_inclusion_id,
              user_update_id: user.id,
              project_record_reason: '',
              validation_error: undefined,
              validation_general_error: false,
              validation_internal_error: false,
              issue_date_error: undefined,
              previous: true,
              fields_enabled: false,
              deleted: false,
              previous_number: previousProjectSentInvoices[Number(key)].number,
              previous_series: previousProjectSentInvoices[Number(key)].series,
              previous_issue_date: new Date(
                previousProjectSentInvoices[Number(key)].issue_date,
              ),
              created_at: new Date(
                previousProjectSentInvoices[Number(key)].created_at,
              ),
            };

            setProjectSentInvoices(prevProjectSentInvoices => [
              ...prevProjectSentInvoices,
              previousProjectSentInvoice,
            ]);
          });
        }
      }

      projectSentInvoiceSequence.current += 1;
      const firstProjectSentInvoice: IProjectSentInvoice = {
        id: '',
        number: '',
        series: '',
        issue_date: new Date(new Date().setHours(0, 0, 0, 0)),
        net_price: 0,
        amount: 0,
        sequence: projectSentInvoiceSequence.current,
        project_id: state.id,
        user_inclusion_id: user.id,
        user_update_id: user.id,
        project_record_reason: '',
        validation_error: undefined,
        validation_general_error: false,
        validation_internal_error: false,
        issue_date_error: undefined,
        previous: false,
        fields_enabled: true,
        deleted: false,
        previous_number: '',
        previous_series: '',
        previous_issue_date: undefined,
        created_at: new Date(),
      };

      setProjectSentInvoices(prevProjectSentInvoices => [
        ...prevProjectSentInvoices,
        firstProjectSentInvoice,
      ]);

      setloadProjectSentInvoices(false);
      setProjectSentInvoicesLoaded(true);
      setLoading(false);
    }
  }, [state, user, loadProjectSentInvoices, previousProjectSentInvoices]);

  useEffect(() => {
    if (state === undefined) {
      if (!projectSentInvoicesLoaded) {
        setloadProjectSentInvoices(true);
      }
    } else if (previousProjectSentInvoices) {
      if (!projectSentInvoicesLoaded) {
        setloadProjectSentInvoices(true);
      }
    }
  }, [projectSentInvoicesLoaded, previousProjectSentInvoices, state]);

  useEffect(() => {
    if (state === undefined) {
      addToast({
        type: 'error',
        title: 'Operação inválida!',
        description: 'Projeto não localizado',
      });

      history.push('/projects/folders');
    } else if (state.status.toUpperCase() === 'CANCELADO') {
      addToast({
        type: 'error',
        title: 'Operação inválida!',
        description: 'Projeto Cancelado',
      });

      history.push('/projects/folders');
    } else {
      register('validation_type');
      register('project_temp_id');
      register('type');
      register('code');
      register('record_description');
      register('user_update_id');
      register('project_procedure_id');
      register('project_sent_invoices');

      setValue('type', 'PASTA');
      setValue('code', state.code);
      setValue('user_update_id', user.id);
      setValue('project_procedure_id', state.project_procedure_id);

      if (state !== undefined) {
        api
          .get(`/projectsentinvoices/projectall?project_id=${state.id}`)
          .then(response => {
            setPreviousProjectSentInvoices(response.data);
          });
      }

      if (!projectTempFilled) {
        setProjectTempFilled(true);
        setProjectTemp(uuidv4());
      }
    }
  }, [addToast, history, state, user, projectTempFilled, register, setValue]);

  useEffect(() => {
    setValue('project_temp_id', projectTemp);
  }, [projectTemp, setValue]);

  const submitForm = useCallback(
    async (data: IFormProcedure2) => {
      try {
        setIsSaving(true);

        await api.put(`/projects/${state.id}`, data);

        addToast({
          type: 'success',
          title: 'Projeto atualizado!',
          description: 'Projeto atualizado com sucesso.',
        });

        history.push('/projects/folders');
      } catch (error: any) {
        setIsSaving(false);

        addToast({
          type: 'error',
          title: 'Ocorreu um erro!',
          description: error.response.data.message,
        });
      }
    },
    [addToast, history, state],
  );

  async function onValidateConfirmProcedure(): Promise<void> {
    projectTotalAmount.current = Number(
      getValues('item_total_amount_temp').toString().replaceAll('.', ''),
    );

    const rowsEmptyNumber = projectSentInvoices.filter(
      projSentInvoice => projSentInvoice.number === '',
    );

    const rowsInvalidInvoice = projectSentInvoices.filter(
      projSentInvoice => projSentInvoice.validation_error !== undefined,
    );

    const rowsInvalidIssueDate = projectSentInvoices.filter(
      projSentInvoice => projSentInvoice.issue_date_error !== undefined,
    );

    const rowsInvalidNetPrice = projectSentInvoices.filter(
      projSentInvoice => !(projSentInvoice.net_price > 0),
    );

    const rowsInvalidAmount = projectSentInvoices.filter(
      projSentInvoice => !(projSentInvoice.amount > 0),
    );

    projectSentInvoicesTotalAmount.current = 0;
    if (projectSentInvoices) {
      if (projectSentInvoices.length > 0) {
        Object.keys(projectSentInvoices).forEach(key => {
          if (!projectSentInvoices[Number(key)].deleted) {
            projectSentInvoicesTotalAmount.current +=
              projectSentInvoices[Number(key)].amount;
          }
        });
      }
    }

    if (projectTotalAmount.current <= 0) {
      setTitleNotice('Quantidades inválidas!');
      setSubTitleNotice('Quantidade total deve ser maior do que "0" (zero)!');
      setIsOpenNotice(true);
    } else if (
      projectTotalAmount.current < projectSentInvoicesTotalAmount.current
    ) {
      setTitleNotice('Quantidades inválidas!');
      setSubTitleNotice('Quantidade total menor do que a quantidade enviada!');
      setIsOpenNotice(true);
    } else if (projectSentInvoices.length <= 0) {
      setTitleNotice('Notas fiscais inválidas!');
      setSubTitleNotice('Necessário informar notas fiscais!');
      setIsOpenNotice(true);
    } else if (rowsEmptyNumber && rowsEmptyNumber.length > 0) {
      setTitleNotice('Existem notas fiscais sem número!');
      setSubTitleNotice(
        'Não é possível enviar projeto com notas fiscais sem número!',
      );
      setIsOpenNotice(true);
    } else if (rowsInvalidInvoice && rowsInvalidInvoice.length > 0) {
      setTitleNotice('Existem notas fiscais inválidas!');
      setSubTitleNotice(
        'Não é possível enviar projeto com notas fiscais inválidas!',
      );
      setIsOpenNotice(true);
    } else if (rowsInvalidIssueDate && rowsInvalidIssueDate.length > 0) {
      setTitleNotice('Existem datas de emissão inválidas em notas fiscais!');
      setSubTitleNotice(
        'Não é possível enviar projeto com notas fiscais possuindo datas de emissão inválidas!',
      );
      setIsOpenNotice(true);
    } else if (rowsInvalidNetPrice && rowsInvalidNetPrice.length > 0) {
      setTitleNotice('Valores inválidos em notas fiscais!');
      setSubTitleNotice(
        'Não é possível enviar projeto com notas fiscais sem valor informado!',
      );
      setIsOpenNotice(true);
    } else if (rowsInvalidAmount && rowsInvalidAmount.length > 0) {
      setTitleNotice('Quantidades inválidas em notas fiscais!');
      setSubTitleNotice(
        'Não é possível enviar projeto com notas fiscais sem quantidade informada!',
      );
      setIsOpenNotice(true);
    } else {
      setTitleConfirmProcedure(`Confirma gravação do projeto ${state.code}?`);
      setIsOpenConfirmProcedure(true);
    }
  }

  function onConfirmProcedure(): void {
    register('item_total_amount');
    setValue('item_total_amount', getValues('item_total_amount_temp'));
    const itemTotalAmountValue = getValues('item_total_amount');

    let itemTotalAmountConv = itemTotalAmountValue.toString();
    itemTotalAmountConv = itemTotalAmountConv.replaceAll('.', '');

    if (!Number.isNaN(Number(parseFloat(itemTotalAmountConv)))) {
      setValue('item_total_amount', parseFloat(itemTotalAmountConv));
    }

    setValue('validation_type', 'APROVADO');
    if (projectTotalAmount.current <= projectSentInvoicesTotalAmount.current) {
      setValue(
        'record_description',
        `Finalização do projeto, com quantidade total ` +
          `enviada de ${projectSentInvoicesTotalAmount.current}`,
      );
    } else {
      setValue(
        'record_description',
        `Atualização do projeto, com quantidade total ` +
          `enviada de ${projectSentInvoicesTotalAmount.current}`,
      );
    }
    setValue('project_sent_invoices', projectSentInvoices);

    handleSubmit(submitForm)();
  }

  const onExit = useCallback(() => {
    api.delete(`projectfilesreceived/projecttempid/${projectTemp}`);

    history.push('/projects/folders');
  }, [history, projectTemp]);

  function onConfirmWarning(): void {
    setConfirmSentInvoiceEnabled(true);
  }

  function onDelete(): void {
    setConfirmSentInvoiceDelete(true);
  }

  function handleAddRowTableProjectSentInvoices(): void {
    projectSentInvoiceSequence.current += 1;

    const newProjectSentInvoice: IProjectSentInvoice = {
      id: '',
      number: '',
      series: '',
      issue_date: new Date(new Date().setHours(0, 0, 0, 0)),
      net_price: 0,
      amount: 0,
      sequence: projectSentInvoiceSequence.current,
      project_id: state.id,
      user_inclusion_id: user.id,
      user_update_id: user.id,
      project_record_reason: '',
      validation_error: undefined,
      validation_general_error: false,
      validation_internal_error: false,
      issue_date_error: undefined,
      previous: false,
      fields_enabled: true,
      deleted: false,
      previous_number: '',
      previous_series: '',
      previous_issue_date: undefined,
      created_at: new Date(),
    };

    setProjectSentInvoices(prevProjectSentInvoices => [
      ...prevProjectSentInvoices,
      newProjectSentInvoice,
    ]);
  }

  function handleDeleteRowTableProjectSentInvoices(): void {
    setProjectSentInvoices(
      projectSentInvoices.filter(
        projSentInvoice =>
          projSentInvoice.sequence !== projectSentInvoiceSequence.current,
      ),
    );

    projectSentInvoiceSequence.current -= 1;
  }

  async function handleInvalidInvoiceGeneral(sequence: number): Promise<void> {
    const projSentInvoiceValidate = projectSentInvoices.find(
      projectSentInvoice => projectSentInvoice.sequence === sequence,
    );

    if (projSentInvoiceValidate) {
      if (moment(projSentInvoiceValidate.issue_date).isValid()) {
        await api
          .get(
            `/projectsentinvoices/valid?` +
              `series=${projSentInvoiceValidate.series}&` +
              `number=${projSentInvoiceValidate.number}&` +
              `issue_date=${projSentInvoiceValidate.issue_date}`,
          )
          .then(response => {
            let valid = false;

            if (
              response.data.series &&
              response.data.number &&
              response.data.issue_date
            ) {
              if (
                response.data.series ===
                  projSentInvoiceValidate.previous_series &&
                response.data.number ===
                  projSentInvoiceValidate.previous_number &&
                moment(response.data.issue_date).format('DD/MM/YYYY') ===
                  moment(projSentInvoiceValidate.previous_issue_date).format(
                    'DD/MM/YYYY',
                  )
              ) {
                valid = true;
              } else {
                setProjectSentInvoices(
                  projectSentInvoices.map(projSentInvoice => {
                    if (projSentInvoice.sequence === sequence) {
                      projSentInvoice.validation_general_error = true;
                    }
                    return projSentInvoice;
                  }),
                );
              }
            } else {
              valid = true;
            }

            if (valid) {
              setProjectSentInvoices(
                projectSentInvoices.map(projSentInvoice => {
                  if (projSentInvoice.sequence === sequence) {
                    projSentInvoice.validation_general_error = false;
                  }
                  return projSentInvoice;
                }),
              );
            }
          });
      }
    }
  }

  async function handleInvalidInvoiceInternal(sequence: number): Promise<void> {
    const projSentInvoiceValidate = projectSentInvoices.find(
      projectSentInvoice => projectSentInvoice.sequence === sequence,
    );

    if (projSentInvoiceValidate) {
      const projSentInvoiceInvalid = projectSentInvoices.filter(
        projectSentInvoice =>
          !projectSentInvoice.deleted &&
          projectSentInvoice.series === projSentInvoiceValidate.series &&
          projectSentInvoice.number === projSentInvoiceValidate.number &&
          moment(projectSentInvoice.issue_date).format('DD/MM/YYYY') ===
            moment(projSentInvoiceValidate.issue_date).format('DD/MM/YYYY'),
      );

      if (projSentInvoiceInvalid) {
        if (projSentInvoiceInvalid.length > 0) {
          Object.keys(projSentInvoiceInvalid).forEach(key => {
            setProjectSentInvoices(
              projectSentInvoices.map(projSentInvoice => {
                if (
                  projSentInvoice.sequence ===
                  projSentInvoiceInvalid[Number(key)].sequence
                ) {
                  projSentInvoice.validation_internal_error = true;
                }
                return projSentInvoice;
              }),
            );
          });
        }
      }
    }
  }

  useEffect(() => {
    let checkInvoiceErrors = false;

    const projSentInvoiceGeneralError = projectSentInvoices.filter(
      projectSentInvoice => projectSentInvoice.validation_general_error,
    );

    if (projSentInvoiceGeneralError) {
      if (projSentInvoiceGeneralError.length > 0) {
        Object.keys(projSentInvoiceGeneralError).forEach(key => {
          const projSentInvoicePrevious = projectSentInvoices.find(
            projectSentInvoice =>
              projectSentInvoice.sequence !==
                projSentInvoiceGeneralError[Number(key)].sequence &&
              projectSentInvoice.previous_series ===
                projSentInvoiceGeneralError[Number(key)].series &&
              projectSentInvoice.previous_number ===
                projSentInvoiceGeneralError[Number(key)].number &&
              moment(projectSentInvoice.previous_issue_date).format(
                'DD/MM/YYYY',
              ) ===
                moment(
                  projSentInvoiceGeneralError[Number(key)].issue_date,
                ).format('DD/MM/YYYY'),
          );

          if (projSentInvoicePrevious) {
            const projSentInvoiceGeneralConflict = projectSentInvoices.find(
              projectSentInvoice =>
                !projectSentInvoice.deleted &&
                projectSentInvoice.sequence !==
                  projSentInvoiceGeneralError[Number(key)].sequence &&
                projectSentInvoice.series ===
                  projSentInvoiceGeneralError[Number(key)].series &&
                projectSentInvoice.number ===
                  projSentInvoiceGeneralError[Number(key)].number &&
                moment(projectSentInvoice.issue_date).format('DD/MM/YYYY') ===
                  moment(
                    projSentInvoiceGeneralError[Number(key)].issue_date,
                  ).format('DD/MM/YYYY'),
            );

            if (!projSentInvoiceGeneralConflict) {
              setProjectSentInvoices(
                projectSentInvoices.map(projSentInvoice => {
                  if (
                    projSentInvoice.sequence ===
                    projSentInvoiceGeneralError[Number(key)].sequence
                  ) {
                    projSentInvoice.validation_general_error = false;
                    checkInvoiceErrors = true;
                  }
                  return projSentInvoice;
                }),
              );
            }
          }
        });
      }
    }

    const projSentInvoiceInternalError = projectSentInvoices.filter(
      projectSentInvoice => projectSentInvoice.validation_internal_error,
    );

    if (projSentInvoiceInternalError) {
      if (projSentInvoiceInternalError.length > 0) {
        Object.keys(projSentInvoiceInternalError).forEach(key => {
          let conflict = false;

          const projSentInvoiceInternalConflict = projectSentInvoices.filter(
            projectSentInvoice =>
              !projectSentInvoice.deleted &&
              projectSentInvoice.sequence !==
                projSentInvoiceInternalError[Number(key)].sequence &&
              projectSentInvoice.series ===
                projSentInvoiceInternalError[Number(key)].series &&
              projectSentInvoice.number ===
                projSentInvoiceInternalError[Number(key)].number &&
              moment(projectSentInvoice.issue_date).format('DD/MM/YYYY') ===
                moment(
                  projSentInvoiceInternalError[Number(key)].issue_date,
                ).format('DD/MM/YYYY'),
          );

          if (projSentInvoiceInternalConflict) {
            if (projSentInvoiceInternalConflict.length > 0) {
              conflict = true;
            }
          }

          if (!conflict) {
            setProjectSentInvoices(
              projectSentInvoices.map(projSentInvoice => {
                if (
                  projSentInvoice.sequence ===
                  projSentInvoiceInternalError[Number(key)].sequence
                ) {
                  projSentInvoice.validation_internal_error = false;
                  checkInvoiceErrors = true;
                }
                return projSentInvoice;
              }),
            );
          }
        });
      }
    }

    if (checkInvoiceErrors) {
      setProjectSentInvoices(
        projectSentInvoices.map(projSentInvoice => {
          if (projSentInvoice.validation_internal_error) {
            projSentInvoice.validation_error = 'Nota fiscal já utilizada';
          } else if (projSentInvoice.validation_general_error) {
            projSentInvoice.validation_error =
              'Nota fiscal já utilizada em outro projeto';
          } else {
            projSentInvoice.validation_error = undefined;
          }

          return projSentInvoice;
        }),
      );
    }
  }, [projectSentInvoices]);

  function handleInvalidInvoices(): void {
    setProjectSentInvoices(
      projectSentInvoices.map(projSentInvoice => {
        if (projSentInvoice.validation_internal_error) {
          projSentInvoice.validation_error = 'Nota fiscal já utilizada';
        } else if (projSentInvoice.validation_general_error) {
          projSentInvoice.validation_error =
            'Nota fiscal já utilizada em outro projeto';
        } else {
          projSentInvoice.validation_error = undefined;
        }

        return projSentInvoice;
      }),
    );
  }

  async function handleInvoiceValidationsComplete(
    sequence: number,
  ): Promise<void> {
    await handleInvalidInvoiceGeneral(sequence);
    await handleInvalidInvoiceInternal(sequence);
    await handleInvalidInvoices();
  }

  async function handleRowNumberChange(
    sequence: number,
    fieldValue: string,
  ): Promise<void> {
    setProjectSentInvoices(
      projectSentInvoices.map(projSentInvoice => {
        if (projSentInvoice.sequence === sequence) {
          projSentInvoice.number = fieldValue;
        }
        return projSentInvoice;
      }),
    );

    await handleInvoiceValidationsComplete(sequence);
  }

  async function handleRowSeriesChange(
    sequence: number,
    fieldValue: string,
  ): Promise<void> {
    setProjectSentInvoices(
      projectSentInvoices.map(projSentInvoice => {
        if (projSentInvoice.sequence === sequence) {
          projSentInvoice.series = fieldValue;
        }
        return projSentInvoice;
      }),
    );

    await handleInvoiceValidationsComplete(sequence);
  }

  async function handleRowIssueDateChange(
    sequence: number,
    fieldValue: string,
  ): Promise<void> {
    const issueDate = moment(fieldValue, 'DD/MM/YYYY').toDate();

    setProjectSentInvoices(
      projectSentInvoices.map(projSentInvoice => {
        if (projSentInvoice.sequence === sequence) {
          projSentInvoice.issue_date = issueDate;
        }
        return projSentInvoice;
      }),
    );

    if (
      !moment(fieldValue, 'DD/MM/YYYY').isValid() ||
      issueDate.setHours(0, 0, 0, 0) > new Date().setHours(0, 0, 0, 0)
    ) {
      setProjectSentInvoices(
        projectSentInvoices.map(projSentInvoice => {
          if (projSentInvoice.sequence === sequence) {
            projSentInvoice.issue_date_error = 'Data inválida';
          }
          return projSentInvoice;
        }),
      );
    } else {
      setProjectSentInvoices(
        projectSentInvoices.map(projSentInvoice => {
          if (projSentInvoice.sequence === sequence) {
            projSentInvoice.issue_date_error = undefined;
          }
          return projSentInvoice;
        }),
      );
    }

    await handleInvoiceValidationsComplete(sequence);
  }

  function handleRowNetPriceChange(sequence: number, fieldValue: string): void {
    let netPriceConv = fieldValue;
    let netPriceValue = 0;

    netPriceConv = netPriceConv.replaceAll('R$', '');
    netPriceConv = netPriceConv.replaceAll('.', '');
    netPriceConv = netPriceConv.replaceAll(',', '.');

    if (!Number.isNaN(Number(parseFloat(netPriceConv)))) {
      netPriceValue = parseFloat(netPriceConv);
    }

    setProjectSentInvoices(
      projectSentInvoices.map(projSentInvoice => {
        if (projSentInvoice.sequence === sequence) {
          projSentInvoice.net_price = netPriceValue;
        }
        return projSentInvoice;
      }),
    );
  }

  function handleRowAmountChange(sequence: number, fieldValue: string): void {
    let amountConv = fieldValue;
    let amountValue = 0;

    amountConv = amountConv.replaceAll('.', '');
    amountConv = amountConv.replaceAll(',', '.');

    if (!Number.isNaN(Number(parseFloat(amountConv)))) {
      amountValue = parseFloat(amountConv);
    }

    setProjectSentInvoices(
      projectSentInvoices.map(projSentInvoice => {
        if (projSentInvoice.sequence === sequence) {
          projSentInvoice.amount = amountValue;
        }
        return projSentInvoice;
      }),
    );
  }

  function handleRowFieldsEnabledFalseChange(sequence: number): void {
    setProjectSentInvoices(
      projectSentInvoices.map(projSentInvoice => {
        if (projSentInvoice.sequence === sequence) {
          projSentInvoice.fields_enabled = false;
        }
        return projSentInvoice;
      }),
    );
  }

  useEffect(() => {
    if (confirmSentInvoiceEnabled) {
      if (sentInvoiceEnableSequence) {
        if (sentInvoiceEnableSequence !== '') {
          setProjectSentInvoices(
            projectSentInvoices.map(projSentInvoice => {
              if (
                projSentInvoice.sequence === Number(sentInvoiceEnableSequence)
              ) {
                projSentInvoice.fields_enabled = true;
              }
              return projSentInvoice;
            }),
          );
          setSentInvoiceEnableSequence('');
          setConfirmSentInvoiceEnabled(false);
        }
      }
    }
  }, [
    confirmSentInvoiceEnabled,
    sentInvoiceEnableSequence,
    projectSentInvoices,
  ]);

  useEffect(() => {
    if (confirmSentInvoiceDelete) {
      if (sentInvoiceDeleteSequence) {
        if (sentInvoiceDeleteSequence !== '') {
          setProjectSentInvoices(
            projectSentInvoices.map(projSentInvoice => {
              if (
                projSentInvoice.sequence === Number(sentInvoiceDeleteSequence)
              ) {
                projSentInvoice.deleted = true;
                projSentInvoice.project_record_reason = reasonDelete;
                projSentInvoice.validation_error = undefined;
                projSentInvoice.validation_general_error = false;
                projSentInvoice.validation_internal_error = false;
              }
              return projSentInvoice;
            }),
          );

          setSentInvoiceDeleteSequence('');
          setConfirmSentInvoiceDelete(false);
          setReasonDelete('');
        }
      }
    }
  }, [
    confirmSentInvoiceDelete,
    sentInvoiceDeleteSequence,
    projectSentInvoices,
    reasonDelete,
  ]);

  function handleProjectSentInvoicesTableRowEnableFieldsButtons(
    rowProjectSentInvoice: IProjectSentInvoice,
  ): React.ReactNode {
    if (rowProjectSentInvoice.fields_enabled) {
      return (
        <UnlockButton
          iconSize={20}
          type="button"
          onClick={() => {
            handleRowFieldsEnabledFalseChange(rowProjectSentInvoice.sequence);
          }}
        />
      );
    }

    return (
      <LockButton
        iconSize={20}
        type="button"
        onClick={() => {
          setSentInvoiceEnableSequence(
            rowProjectSentInvoice.sequence.toString(),
          );
          setIsOpenWarning(true);
        }}
      />
    );
  }

  function handleDeleteRow(
    projectSentInvoiceReceived: IProjectSentInvoice,
  ): React.ReactNode | null {
    if (
      projectSentInvoiceReceived.sequence === projectSentInvoiceSequence.current
    ) {
      return (
        <DeleteRowButton
          type="button"
          onClick={() => {
            handleDeleteRowTableProjectSentInvoices();
          }}
        />
      );
    }

    return null;
  }

  function handleProjectSentInvoicesTableRowButtons(
    rowProjectSentInvoice: IProjectSentInvoice,
  ): React.ReactNode | null {
    if (rowProjectSentInvoice.previous) {
      return (
        <>
          {handleProjectSentInvoicesTableRowEnableFieldsButtons(
            rowProjectSentInvoice,
          )}
          <DeletePreviousButton
            marginTop="0.8rem"
            marginLeft="1rem"
            type="button"
            onClick={() => {
              setSentInvoiceDeleteSequence(
                rowProjectSentInvoice.sequence.toString(),
              );
              setTitleDelete(
                `Deseja realmente excluir a NF ${rowProjectSentInvoice.number} ` +
                  `previamente registrada?`,
              );
              setSubTitleDelete(
                'A exclusão apenas será efetivada ao salvar o procedimento!',
              );
              setIsOpenDelete(true);
            }}
          />
        </>
      );
    }

    return handleDeleteRow(rowProjectSentInvoice);
  }

  function handleProjectSentInvoicesTableRow(
    rowProjectSentInvoice: IProjectSentInvoice,
  ): React.ReactNode | null {
    if (!rowProjectSentInvoice.deleted) {
      return (
        <tr key={rowProjectSentInvoice.sequence}>
          <ItemSequence>
            <ItemSequenceLabel>
              {`${'Sequência'.toUpperCase()}: ${
                rowProjectSentInvoice.sequence
              }`}
            </ItemSequenceLabel>
          </ItemSequence>

          <ItemContent>
            <ItemWrapper>
              <TableDefaultInput
                type="text"
                labelText="Número"
                upperCaseLabel
                defaultValue={rowProjectSentInvoice.number}
                error={rowProjectSentInvoice.validation_error}
                maxLength={7}
                disabled={
                  rowProjectSentInvoice.previous &&
                  !rowProjectSentInvoice.fields_enabled
                }
                onChange={e => {
                  handleRowNumberChange(
                    rowProjectSentInvoice.sequence,
                    e.target.value,
                  );
                }}
              />
            </ItemWrapper>

            <ItemWrapper>
              <TableDefaultInput
                type="text"
                labelText="Série"
                upperCaseLabel
                defaultValue={rowProjectSentInvoice.series}
                maxLength={3}
                disabled={
                  rowProjectSentInvoice.previous &&
                  !rowProjectSentInvoice.fields_enabled
                }
                onChange={e => {
                  handleRowSeriesChange(
                    rowProjectSentInvoice.sequence,
                    e.target.value,
                  );
                }}
              />
            </ItemWrapper>

            <ItemWrapper>
              <TableDateInput
                type="text"
                labelText="Data Emissão"
                upperCaseLabel
                defaultValue={rowProjectSentInvoice.issue_date.toLocaleDateString()}
                error={rowProjectSentInvoice.issue_date_error}
                disabled={
                  rowProjectSentInvoice.previous &&
                  !rowProjectSentInvoice.fields_enabled
                }
                onChange={e => {
                  handleRowIssueDateChange(
                    rowProjectSentInvoice.sequence,
                    e.target.value,
                  );
                }}
              />
            </ItemWrapper>
          </ItemContent>

          <ItemContent>
            <ItemWrapper>
              <TableDateInput
                type="text"
                labelText="Data Inclusão"
                upperCaseLabel
                defaultValue={rowProjectSentInvoice.created_at.toLocaleDateString()}
                disabled
              />
            </ItemWrapper>

            <ItemWrapper>
              <TableCurrencyInput
                type="text"
                labelText="Valor NF"
                upperCaseLabel
                defaultValue={rowProjectSentInvoice.net_price}
                disabled={
                  rowProjectSentInvoice.previous &&
                  !rowProjectSentInvoice.fields_enabled
                }
                onSet={e => {
                  handleRowNetPriceChange(
                    rowProjectSentInvoice.sequence,
                    e.target.value,
                  );
                }}
              />
            </ItemWrapper>

            <ItemWrapper>
              <TableWholeNumberInput
                type="text"
                labelText="Quantidade"
                upperCaseLabel
                defaultValue={rowProjectSentInvoice.amount}
                disabled={
                  rowProjectSentInvoice.previous &&
                  !rowProjectSentInvoice.fields_enabled
                }
                onSet={e => {
                  handleRowAmountChange(
                    rowProjectSentInvoice.sequence,
                    e.target.value,
                  );
                }}
              />
            </ItemWrapper>
          </ItemContent>

          <div
            style={{
              width: '100%',
              display: 'flex',
              justifyContent: 'flex-end',
              padding: '10px 5px 10px 10px',
            }}
          >
            {handleProjectSentInvoicesTableRowButtons(rowProjectSentInvoice)}
          </div>
        </tr>
      );
    }

    return null;
  }

  function handleProjectSentInvoicesTable(): React.ReactNode {
    if (projectSentInvoices) {
      if (projectSentInvoices.length > 0) {
        return (
          <Table>
            {loading && (
              <div
                style={{
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'center',
                }}
              >
                <PropagateLoader color="#276c8d" />
              </div>
            )}
            {projectSentInvoices?.map(projectSentInvoice =>
              handleProjectSentInvoicesTableRow(projectSentInvoice),
            )}
          </Table>
        );
      }
    }

    return <></>;
  }

  return (
    <Container>
      <BreadCrumb
        main="Solicitações"
        path="/projects/main"
        firstChildPath="/projects/folders"
        firstChild="Pastas"
        secondChild="Atualização Solicitação"
        mainIcon={FaProjectDiagram}
        firstChildIcon={FaFolder}
        secondChildIcon={FaFolder}
        mainColored
        firstChildColored
      />

      <ProjectInfo project_id={state.id} />

      <form onSubmit={handleSubmit(submitForm)}>
        <Content>
          <Wrapper style={{ width: '50%' }}>
            <WholeNumberInput
              name="item_total_amount_temp"
              labelFor="item_total_amount_temp"
              labelText="Quantidade"
              defaultValue={state.item_total_amount}
              error={errors?.item_total_amount?.message}
              ref={register}
              disabledField={!totalAmountEnabled}
            />
          </Wrapper>

          <Wrapper style={{ width: '50%' }}>
            {totalAmountEnabled ? (
              <UnlockButton
                iconSize={26}
                marginTop="2rem"
                type="button"
                onClick={() => {
                  setTotalAmountEnabled(false);
                }}
              />
            ) : (
              <LockButton
                iconSize={26}
                marginTop="2rem"
                type="button"
                onClick={() => {
                  setTotalAmountEnabled(true);
                }}
              />
            )}
          </Wrapper>
        </Content>

        <LabelContainer>
          <Label>Notas Fiscais</Label>
        </LabelContainer>

        <Content>{handleProjectSentInvoicesTable()}</Content>

        <ContentAddRow>
          <AddRowButton
            type="button"
            onClick={() => handleAddRowTableProjectSentInvoices()}
          />
        </ContentAddRow>

        <Content>
          <Wrapper style={{ width: '100%' }}>
            <TextArea
              name="record_note"
              labelFor="record_note"
              labelText="Observação"
              error={errors?.record_note?.message}
              ref={register}
              maxLength={1000}
            />
          </Wrapper>
        </Content>

        <Content>
          <UploadFiles
            type="anexo"
            project_temp_id={projectTemp}
            project_type="PASTA"
            sequence={2}
            user_id={user.id}
          />
        </Content>

        <ButtonContainer>
          <SaveButton
            type="button"
            processing={isSaving}
            disabled={isSaving}
            onClick={() => onValidateConfirmProcedure()}
          />

          <ExitButton
            style={{ marginLeft: 10 }}
            type="button"
            disabled={isSaving}
            onClick={() => setIsOpenExit(true)}
          />
        </ButtonContainer>
      </form>

      <ConfirmProcedureDialog
        isOpen={isOpenConfirmProcedure}
        setIsOpen={setIsOpenConfirmProcedure}
        onConfirm={onConfirmProcedure}
        title={titleConfirmProcedure}
        subTitle="As alterações feitas no projeto serão salvas"
        opConfirm="Sim"
      />

      <ConfirmExitDialog
        isOpen={isOpenExit}
        setIsOpen={setIsOpenExit}
        onExit={onExit}
      />

      <ConfirmWarningDialog
        isOpen={isOpenWarning}
        setIsOpen={setIsOpenWarning}
        onConfirm={onConfirmWarning}
        title="Deseja realmente alterar a NF previamente registrada?"
        opConfirm="Sim, alterar"
      />

      <DeleteModal
        isOpen={isOpenDelete}
        onDelete={onDelete}
        title={titleDelete}
        subTitle={subTitleDelete}
        setIsOpen={setIsOpenDelete}
        setReason={setReasonDelete}
      />

      <NoticeDialog
        isOpen={isOpenNotice}
        type="error"
        title={titleNotice}
        subTitle={subTitleNotice}
        setIsOpen={setIsOpenNotice}
      />
    </Container>
  );
};

export default Procedure2;
