const getPermByPath = (
  feature_paths: string[] | undefined,
  path: string,
): boolean => {
  if (feature_paths) {
    if (path === '/management/main') {
      return feature_paths.some(f_p => f_p.includes('/management'));
    }

    if (path === '/configurations/main') {
      return feature_paths.some(f_p => f_p.includes('/configurations'));
    }

    if (path === '/items/main') {
      return feature_paths.some(f_p => f_p.includes('/items'));
    }

    if (path === '/projects/main') {
      return feature_paths.some(f_p => f_p.includes('/projects'));
    }

    if (path === '/projects/displays') {
      return feature_paths.some(f_p => f_p.includes('/projects/displays'));
    }

    if (path === '/projects/materials') {
      return feature_paths.some(f_p => f_p.includes('/projects/materials'));
    }

    if (path === '/projects/sponsorships') {
      return feature_paths.some(f_p => f_p.includes('/projects/sponsorships'));
    }

    if (path === '/projects/adversities') {
      return feature_paths.some(f_p => f_p.includes('/projects/adversities'));
    }

    if (path === '/projects/folders') {
      return feature_paths.some(f_p => f_p.includes('/projects/folders'));
    }

    if (path === '/projects/samples') {
      return feature_paths.some(f_p => f_p.includes('/projects/samples'));
    }

    if (path === '/projects/events') {
      return feature_paths.some(f_p => f_p.includes('/projects/events'));
    }

    return feature_paths.some(f_p => path.includes(f_p));
  }

  return false;
};

export default getPermByPath;
