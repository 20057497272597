import styled, { css } from 'styled-components';
import { motion } from 'framer-motion';

interface ITdProps {
  width?: string;
  textAlign?: string;
  padding?: string;
  display?: string;
  heading?: string;
}

export const Container = styled(motion.section)`
  ${({ theme }) => css`
    width: 100%;
    min-height: 600px;
    margin: 2.4rem 0;
    padding: 2.4rem;
    background: ${theme.colors.backgroundContainer};
    border-radius: ${theme.radius.small};
    box-shadow: ${theme.shadows.flat};
  `}
`;

export const ContentSplit = styled.div`
  width: 100%;
  display: flex;

  @media (max-width: 1200px) {
    flex-direction: column;
  }
`;

export const FilterContent = styled.div`
  width: 50%;

  @media (max-width: 1200px) {
    width: 100%;
    max-width: 680px;
  }
`;

export const ReportContent = styled.div`
  width: 50%;
  display: flex;
  justify-content: flex-end;
  margin-top: 2rem;

  @media (max-width: 1200px) {
    width: 100%;
    justify-content: flex-start;
  }
`;

export const Table = styled.table`
  ${({ theme }) => css`
    margin: 3rem 0 0 0;
    width: 100%;
    border-collapse: collapse;
    border-spacing: 0 4px;
    background-color: ${theme.colors.lightGrey};
    box-shadow: 1px 1px 6px #ececec;
    border-radius: ${theme.radius.small};

    > thead {
      @media (max-width: 995px) {
        display: none;
      }

      > tr > th {
        padding: 1.8rem 0 0 1rem;
        font-size: 14px;
        text-align: left;
        text-transform: uppercase;

        &:first-child {
          padding-left: 2rem;
        }
      }
    }

    > tbody {
      background-color: ${theme.colors.backgroundContainer};

      > tr {
        transition: all 0.25s ease;
        background-color: #fff;

        &:hover {
          transform: translateY(-1px);
          background-color: ${theme.colors.hoverTable};
          border-radius: ${theme.radius.small};
        }

        @media (max-width: 995px) {
          display: block;
          margin-bottom: 2rem;
          border-radius: ${theme.radius.small};
        }
      }
    }
  `}
`;

export const Td = styled.td<ITdProps>`
  ${({ theme, heading }) => css`
    border: 0;
    font-size: ${theme.fontSizes.meddium};
    padding: 1rem 0 0.5rem 1rem;
    font-weight: ${theme.weight.default};

    &:first-child {
      padding-left: 2rem;

      &::before {
        border-top-left-radius: ${theme.radius.small};
      }
    }

    &:last-child {
      padding-right: 2rem;

      &::before {
        border-bottom-left-radius: ${theme.radius.small};
      }
    }

    @media (max-width: 995px) {
      display: block;
      position: relative;
      width: 100% !important;
      padding-left: 13.2rem;
      text-align: left;

      &:first-child {
        padding-left: 13.2rem;
      }

      &::before {
        ${heading &&
        css`
          content: '${heading}';
        `}

        width: 11rem;
        height: 100%;
        top: 0;
        left: 0;
        display: flex;
        align-items: center;
        position: absolute;
        background-color: ${theme.colors.lightGrey};
        padding: 0 0.8rem;
        font-size: 14px;
        font-weight: 700;
      }
    }
  `}
`;

export const RowButtonsContent = styled.div`
  display: flex;

  @media (max-width: 995px) {
    gap: 0.5rem;

    > div {
      width: auto !important;
    }
  }
`;

export const ButtonsContent = styled.div`
  display: flex;
  margin-top: 2rem;
`;
