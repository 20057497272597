import styled, { css } from 'styled-components';

interface IContainerProps {
  type: string;
}

export const Container = styled.div<IContainerProps>`
  ${({ theme, type }) => css`
    width: 80px;
    display: flex;
    justify-content: center;
    color: #fff;
    border-radius: 4px;
    font-size: 14px;

    ${type === 'positive' &&
    css`
      background: ${theme.colors.green};
    `}

    ${type === 'negative' &&
    css`
      background: ${theme.colors.grey};
    `}
  `}
`;
