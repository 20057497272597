import React, { useCallback, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useForm, Controller } from 'react-hook-form';
import { FaExclamationCircle, FaUser } from 'react-icons/fa';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { string } from 'yup';

import { useTheme } from 'styled-components';

import BreadCrumb from '~/components/BreadCrumb';
import CurrencyInput from '~/components/Inputs/Currency';
import DefaultInput from '~/components/Inputs/Default';
import DefaultTooltip from '~/components/Tooltips/Default';
import Divider from '~/components/Divider';
import Toggle from '~/components/Toggle';
import Select from '~/components/Select';
import SaveButton from '~/components/Buttons/Save';
import ExitButton from '~/components/Buttons/Exit';
import ConfirmProcedureDialog from '~/components/Dialogs/ConfirmProcedure';
import ConfirmExitDialog from '~/components/Dialogs/ConfirmExit';
import NoticeDialog from '~/components/Dialogs/Notice';

import { getCurrencyFormatValue } from '~/utils';

import { useToast } from '~/hooks/toast';

import api from '~/services/api';

import {
  Container,
  Content,
  Wrapper,
  ToggleWrapper,
  PermWrapper,
  InputWithTTWrapper,
  InputWrapper,
  TooltipWrapper,
  ButtonContainer,
} from './styles';

interface IFormUser {
  name: string;
  email: string;
  password: string;
  erp_rep_code?: string;
  active: boolean;
  event_gifts_responsable: boolean;
  enable_new_display_req: boolean;
  enable_new_material_req: boolean;
  enable_new_sponsorship_req: boolean;
  enable_new_problem_rep: boolean;
  enable_new_folder_req: boolean;
  enable_new_sample_req: boolean;
  enable_new_event_req: boolean;
  user_type: {
    value: string;
  };
}

interface IUserType {
  id: string;
  name: string;
  type: string;
}

interface IModule {
  id: string;
  sequence: number;
  name: string;
  description: string;
  groups: {
    sequence: number;
    name?: string;
    description?: string;
    features: {
      id: string;
      sequence: number;
      name: string;
      description: string;
      price_range: boolean;
    }[];
  }[];
}

interface IFeature {
  id: string;
  sequence: number;
  name: string;
  description: string;
  price_range: boolean;
  group_seq?: number;
  group_name?: string;
  group_desc?: string;
  module: {
    id: string;
    sequence: number;
    name: string;
    description: string;
  };
}

interface IPermission {
  feature_id: string;
  min_price?: number;
  max_price?: number;
}

interface ILocation {
  id: string;
  name: string;
  email: string;
  erp_rep_code: string;
  active: boolean;
  event_gifts_responsable: boolean;
  enable_new_display_req: boolean;
  enable_new_material_req: boolean;
  enable_new_sponsorship_req: boolean;
  enable_new_problem_rep: boolean;
  enable_new_folder_req: boolean;
  enable_new_sample_req: boolean;
  enable_new_event_req: boolean;
  user_type: IUserType;
}

const schema = Yup.object().shape({
  name: Yup.string().required('Campo obrigatório'),
  email: Yup.string().required('Campo obrigatório').email('E-mail inválido'),
  password: Yup.string().when(
    '$passwordRequired',
    (passwordRequired: string) => {
      if (passwordRequired) {
        return string().required('Campo obrigatório');
      }

      return string().notRequired();
    },
  ),
  user_type: Yup.object().shape({
    value: Yup.string().required('Campo obrigatório'),
  }),
  erp_rep_code: Yup.string().when(
    '$erpCodeRequired',
    (erpCodeRequired: string) => {
      if (erpCodeRequired) {
        return string().required('Campo obrigatório');
      }
      return string().notRequired();
    },
  ),
});

const New: React.FC = () => {
  const history = useHistory();
  const { colors } = useTheme();
  const { addToast } = useToast();
  const { state } = useLocation<ILocation>();
  const [userTypes, setUserTypes] = useState<IUserType[]>();
  const [selectedUserType, setSelectedUserType] = useState<
    IUserType | undefined
  >(state && state.user_type);
  const [modules, setModules] = useState<IModule[]>();
  const [permissions, setPermissions] = useState<IPermission[]>();
  const [repCodeError, setRepCodeError] = useState('');
  const [isOpenConfirmProcedure, setIsOpenConfirmProcedure] = useState(false);
  const [isOpenExit, setIsOpenExit] = useState(false);
  const [isOpenNotice, setIsOpenNotice] = useState(false);
  const [isSaving, setIsSaving] = useState(false);

  const { handleSubmit, register, setValue, control, errors } =
    useForm<IFormUser>({
      context: {
        passwordRequired: state === undefined,
        erpCodeRequired: selectedUserType?.type === 'REP',
      },
      resolver: yupResolver(schema),
      defaultValues: {
        active: state?.active,
      },
    });

  useEffect(() => {
    const loadUserTypes = async () => {
      await api.get('/usertypes').then(response => {
        setUserTypes(response.data);
      });
    };

    const loadPermissionsAndModules = async () => {
      if (state) {
        await api
          .get(`/users/permissions?user_id=${state.id}`)
          .then(response => {
            setPermissions(response.data);
          });
      } else {
        setPermissions([]);
      }

      let tempModules: IModule[] = [];

      await api.get<IFeature[]>('/features').then(response => {
        response.data.forEach(d => {
          const mod = {
            id: d.module.id,
            sequence: d.module.sequence,
            name: d.module.name,
            description: d.module.description,
            groups: [
              {
                sequence: d.group_seq || 0,
                name: d.group_name,
                description: d.group_desc,
                features: [
                  {
                    id: d.id,
                    sequence: d.sequence,
                    name: d.name,
                    description: d.description,
                    price_range: d.price_range,
                  },
                ],
              },
            ],
          };

          const prevMod = tempModules.find(m => m.id === d.module.id);

          if (prevMod) {
            const prevGroup = prevMod.groups.find(g => g.name === d.group_name);

            if (prevGroup) {
              prevGroup.features = [
                ...prevGroup.features,
                mod.groups[0].features[0],
              ];
            } else {
              prevMod.groups = [...prevMod.groups, mod.groups[0]];
            }
          } else {
            tempModules = [...tempModules, mod];
          }
        });

        setModules(tempModules);
      });
    };

    register('user_type');

    if (state) {
      setValue('user_type', { value: state.user_type.id });
    }

    loadUserTypes();
    loadPermissionsAndModules();
  }, [register, setValue, state]);

  const submitForm = useCallback(
    async (data: IFormUser) => {
      try {
        setIsSaving(true);

        if (state === undefined) {
          await api.post('/users', { ...data, permissions });

          addToast({
            type: 'success',
            title: 'Cadastro realizado!',
            description: 'Usuário cadastrado com sucesso.',
          });
        } else {
          await api.put(`/users/${state.id}`, {
            ...data,
            password:
              !data.password || data.password === ''
                ? undefined
                : data.password,
            permissions,
          });

          addToast({
            type: 'success',
            title: 'Cadastro atualizado!',
            description: 'Usuário atualizado com sucesso.',
          });
        }

        history.push('/users');
      } catch (error: any) {
        setIsSaving(false);

        addToast({
          type: 'error',
          title: 'Ocorreu um erro!',
          description: error.response.data.message,
        });
      }
    },
    [addToast, history, permissions, state],
  );

  useEffect(() => {
    if (selectedUserType?.type !== 'REP') {
      setValue('erp_rep_code', '', { shouldValidate: true });

      setRepCodeError('');
    }
  }, [selectedUserType, setValue]);

  const validateRepCode = async (fieldValue: string) => {
    let foundRep = false;

    await api.get(`/representatives/code?code=${fieldValue}`).then(response => {
      if (
        response.data &&
        response.data.code &&
        response.data.code === fieldValue
      ) {
        foundRep = true;
      }
    });

    if (foundRep) {
      setRepCodeError('');
    } else {
      setRepCodeError('Código não encontrado');
    }
  };

  const onExit = () => {
    history.push('/users');
  };

  return (
    <Container>
      <BreadCrumb
        main="Usuários"
        path="/users"
        firstChild={state === undefined ? 'Novo usuário' : 'Edição de usuário'}
        mainIcon={FaUser}
        firstChildIcon={FaUser}
        mainColored
      />

      <form onSubmit={handleSubmit(submitForm)}>
        <Content>
          <Wrapper style={{ width: 400 }}>
            <DefaultInput
              name="name"
              labelFor="name"
              labelText="Nome"
              type="text"
              defaultValue={state?.name}
              error={errors?.name?.message}
              ref={register}
              maxLength={60}
            />
          </Wrapper>

          <Wrapper style={{ width: 100 }}>
            <Controller
              render={props => (
                <Toggle
                  onChange={e => props.onChange(e)}
                  checked={props.value}
                />
              )}
              name="active"
              defaultValue
              control={control}
            />
          </Wrapper>

          <Wrapper style={{ width: 400 }}>
            <DefaultInput
              name="email"
              labelFor="email"
              labelText="E-mail"
              type="email"
              defaultValue={state?.email}
              error={errors?.email?.message}
              ref={register}
              maxLength={60}
            />
          </Wrapper>

          <Wrapper style={{ width: 280 }}>
            <Select
              label="Tipo"
              options={userTypes?.map(userType => ({
                value: userType.id,
                label: `${userType.name}`,
              }))}
              error={errors?.user_type?.value?.message}
              loading={!userTypes}
              value={{
                value: selectedUserType?.id,
                label: selectedUserType?.name || 'Selecione ...',
              }}
              onSet={(e: any) => {
                setValue(
                  'user_type',
                  { value: e.value },
                  { shouldValidate: true },
                );

                setSelectedUserType(userTypes?.find(u_t => u_t.id === e.value));
              }}
            />
          </Wrapper>

          <Wrapper style={{ width: 220 }}>
            <DefaultInput
              name="erp_rep_code"
              labelFor="erp_rep_code"
              labelText="Código Rep. ERP"
              type="text"
              defaultValue={state?.erp_rep_code}
              error={repCodeError || errors?.erp_rep_code?.message}
              ref={register}
              disabled={selectedUserType?.type !== 'REP'}
              maxLength={6}
              onChange={e => validateRepCode(e.target.value)}
            />
          </Wrapper>

          <Wrapper style={{ width: 280 }}>
            <DefaultInput
              name="password"
              labelFor="password"
              labelText="Senha"
              isPassword
              ref={register}
              error={errors.password?.message}
              validateInput
              maxLength={30}
            />
          </Wrapper>
        </Content>

        <Divider mt={40} mb={20} title="Habilitação de Solicitações" />

        <Content>
          <ToggleWrapper>
            <Controller
              render={props => (
                <Toggle
                  onChange={e => props.onChange(e)}
                  checked={props.value}
                  label="Hab. Nova Sol. Display"
                />
              )}
              name="enable_new_display_req"
              defaultValue={
                state?.enable_new_display_req !== undefined
                  ? state.enable_new_display_req
                  : false
              }
              control={control}
            />
          </ToggleWrapper>

          <ToggleWrapper>
            <Controller
              render={props => (
                <Toggle
                  onChange={e => props.onChange(e)}
                  checked={props.value}
                  label="Hab. Nova Sol. Material"
                />
              )}
              name="enable_new_material_req"
              defaultValue={
                state?.enable_new_material_req !== undefined
                  ? state.enable_new_material_req
                  : false
              }
              control={control}
            />
          </ToggleWrapper>

          <ToggleWrapper>
            <Controller
              render={props => (
                <Toggle
                  onChange={e => props.onChange(e)}
                  checked={props.value}
                  label="Hab. Nova Sol. Pagamento"
                />
              )}
              name="enable_new_sponsorship_req"
              defaultValue={
                state?.enable_new_sponsorship_req !== undefined
                  ? state.enable_new_sponsorship_req
                  : false
              }
              control={control}
            />
          </ToggleWrapper>

          <ToggleWrapper>
            <Controller
              render={props => (
                <Toggle
                  onChange={e => props.onChange(e)}
                  checked={props.value}
                  label="Hab. Novo Rep. Problema"
                />
              )}
              name="enable_new_problem_rep"
              defaultValue={
                state?.enable_new_problem_rep !== undefined
                  ? state.enable_new_problem_rep
                  : false
              }
              control={control}
            />
          </ToggleWrapper>

          <ToggleWrapper>
            <Controller
              render={props => (
                <Toggle
                  onChange={e => props.onChange(e)}
                  checked={props.value}
                  label="Hab. Nova Sol. Pasta"
                />
              )}
              name="enable_new_folder_req"
              defaultValue={
                state?.enable_new_folder_req !== undefined
                  ? state.enable_new_folder_req
                  : false
              }
              control={control}
            />
          </ToggleWrapper>

          <ToggleWrapper>
            <Controller
              render={props => (
                <Toggle
                  onChange={e => props.onChange(e)}
                  checked={props.value}
                  label="Hab. Nova Sol. Amostra"
                />
              )}
              name="enable_new_sample_req"
              defaultValue={
                state?.enable_new_sample_req !== undefined
                  ? state.enable_new_sample_req
                  : false
              }
              control={control}
            />
          </ToggleWrapper>

          <ToggleWrapper>
            <Controller
              render={props => (
                <Toggle
                  onChange={e => props.onChange(e)}
                  checked={props.value}
                  label="Hab. Nova Sol. Evento"
                />
              )}
              name="enable_new_event_req"
              defaultValue={
                state?.enable_new_event_req !== undefined
                  ? state.enable_new_event_req
                  : false
              }
              control={control}
            />
          </ToggleWrapper>
        </Content>

        <div>
          {permissions &&
            modules
              ?.sort((a, b) => a.sequence - b.sequence)
              .map(mod => (
                <div key={mod.sequence}>
                  <Divider mt={40} mb={20} title={mod.description} />

                  {mod.groups
                    .sort((a, b) => a.sequence - b.sequence)
                    .map(group => (
                      <div key={`${mod.name}_${String(group.sequence)}`}>
                        {group.description && (
                          <div
                            style={{
                              marginTop: 30,
                              color: colors.grey,
                              fontSize: '15px',
                              fontStyle: 'italic',
                            }}
                          >
                            {group.description}
                          </div>
                        )}

                        <Content>
                          {group.features
                            .sort((a, b) => a.sequence - b.sequence)
                            .map(feature => (
                              <PermWrapper key={feature.name}>
                                <div>
                                  <Toggle
                                    onChange={e => {
                                      if (e) {
                                        setPermissions(prevPermissions =>
                                          prevPermissions !== undefined
                                            ? [
                                                ...prevPermissions,
                                                { feature_id: feature.id },
                                              ]
                                            : [{ feature_id: feature.id }],
                                        );
                                      } else {
                                        setPermissions(prevPermissions =>
                                          prevPermissions?.filter(
                                            p => p.feature_id !== feature.id,
                                          ),
                                        );
                                      }
                                    }}
                                    checked={
                                      permissions.find(
                                        p => p.feature_id === feature.id,
                                      ) !== undefined
                                    }
                                    label={feature.description}
                                  />
                                </div>

                                {feature.price_range &&
                                  permissions.find(
                                    p => p.feature_id === feature.id,
                                  ) !== undefined && (
                                    <>
                                      <InputWithTTWrapper>
                                        <InputWrapper style={{ width: 180 }}>
                                          <CurrencyInput
                                            name={`${feature.name}_min_price`}
                                            labelFor={`${feature.name}_min_price`}
                                            labelText="Valor Mín."
                                            defaultValue={
                                              permissions.find(
                                                p =>
                                                  p.feature_id === feature.id,
                                              )?.min_price || 0
                                            }
                                            onSet={e => {
                                              setPermissions(prevPermissions =>
                                                prevPermissions?.map(prevPerm =>
                                                  prevPerm.feature_id ===
                                                  feature.id
                                                    ? {
                                                        ...prevPerm,
                                                        min_price:
                                                          getCurrencyFormatValue(
                                                            e.target.value,
                                                          ),
                                                      }
                                                    : { ...prevPerm },
                                                ),
                                              );
                                            }}
                                          />
                                        </InputWrapper>

                                        <TooltipWrapper>
                                          <DefaultTooltip
                                            title={
                                              'Quando não for informado valor no campo, ' +
                                              'não terá restrição de valor mínimo.'
                                            }
                                            background={colors.darkGrayishBlue}
                                          >
                                            <FaExclamationCircle size={20} />
                                          </DefaultTooltip>
                                        </TooltipWrapper>
                                      </InputWithTTWrapper>

                                      <InputWithTTWrapper>
                                        <InputWrapper style={{ width: 180 }}>
                                          <CurrencyInput
                                            name={`${feature.name}_max_price`}
                                            labelFor={`${feature.name}_max_price`}
                                            labelText="Valor Máx."
                                            defaultValue={
                                              permissions.find(
                                                p =>
                                                  p.feature_id === feature.id,
                                              )?.max_price || 0
                                            }
                                            onSet={e => {
                                              setPermissions(prevPermissions =>
                                                prevPermissions?.map(prevPerm =>
                                                  prevPerm.feature_id ===
                                                  feature.id
                                                    ? {
                                                        ...prevPerm,
                                                        max_price:
                                                          getCurrencyFormatValue(
                                                            e.target.value,
                                                          ),
                                                      }
                                                    : { ...prevPerm },
                                                ),
                                              );
                                            }}
                                          />
                                        </InputWrapper>

                                        <TooltipWrapper>
                                          <DefaultTooltip
                                            title={
                                              'Quando não for informado valor no campo ' +
                                              'ou o valor for abaixo do valor mínimo, ' +
                                              'não terá restrição de valor máximo.'
                                            }
                                            background={colors.darkGrayishBlue}
                                          >
                                            <FaExclamationCircle size={20} />
                                          </DefaultTooltip>
                                        </TooltipWrapper>
                                      </InputWithTTWrapper>
                                    </>
                                  )}
                              </PermWrapper>
                            ))}
                        </Content>
                      </div>
                    ))}
                </div>
              ))}
        </div>

        <Divider mt={40} mb={20} title="Extras" />

        <Content>
          <ToggleWrapper>
            <Controller
              render={props => (
                <Toggle
                  onChange={e => props.onChange(e)}
                  checked={props.value}
                  label="Resp. Brindes Eventos"
                />
              )}
              name="event_gifts_responsable"
              defaultValue={
                state?.event_gifts_responsable !== undefined
                  ? state.event_gifts_responsable
                  : false
              }
              control={control}
            />
          </ToggleWrapper>
        </Content>

        <ButtonContainer>
          <SaveButton
            type="button"
            processing={isSaving}
            disabled={isSaving}
            onClick={() => {
              if (selectedUserType?.type === 'REP' && repCodeError !== '') {
                setIsOpenNotice(true);
              } else {
                setIsOpenConfirmProcedure(true);
              }
            }}
          />

          <ExitButton
            style={{ marginLeft: 10 }}
            type="button"
            disabled={isSaving}
            onClick={() => setIsOpenExit(true)}
          />
        </ButtonContainer>
      </form>

      <ConfirmProcedureDialog
        isOpen={isOpenConfirmProcedure}
        setIsOpen={setIsOpenConfirmProcedure}
        onConfirm={() => handleSubmit(submitForm)()}
        title="Confirma gravação do usuário?"
        subTitle=""
        opConfirm="Sim"
        type=""
      />

      <ConfirmExitDialog
        isOpen={isOpenExit}
        setIsOpen={setIsOpenExit}
        onExit={onExit}
      />

      <NoticeDialog
        isOpen={isOpenNotice}
        type="error"
        title="Código representante inválido!"
        subTitle={
          `Não é possível gravar usuário com ` +
          `código de representante inválido!`
        }
        setIsOpen={setIsOpenNotice}
      />
    </Container>
  );
};

export default New;
