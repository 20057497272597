import React, { useState, useEffect, useCallback } from 'react';
import { FaProjectDiagram, FaMoneyBillWave } from 'react-icons/fa';
import { PropagateLoader } from 'react-spinners';
import { Link } from 'react-router-dom';

import BreadCrumb from '~/components/BreadCrumb';
import ConfirmDeleteDialog from '~/components/Dialogs/ConfirmDelete';
import DefaultInput from '~/components/Inputs/Default';
import DefaultTooltip from '~/components/Tooltips/Default';
import DeleteButton from '~/components/Buttons/Delete';
import EditButton from '~/components/Buttons/Edit';
import NewButton from '~/components/Buttons/New';
import Pagination from '~/components/Pagination';
import ReportButton from '~/components/Buttons/Report';
import ReportDialog from '~/components/Dialogs/Report';
import ViewButton from '~/components/Buttons/View';

import { getEditProjPerm, getFormattedCnpj, getPermByName } from '~/utils';

import { useAuth } from '~/hooks/auth';
import { useToast } from '~/hooks/toast';

import api from '~/services/api';

import {
  Container,
  ContentSplit,
  FilterContent,
  ReportContent,
  Table,
  Td,
  RowButtonsContent,
  ButtonsContent,
} from './styles';

interface ISponsorshipProject {
  id: string;
  code: string;
  status: string;
  gross_discount_price: number;
  sponsorship: {
    name: string;
  };
  local_client: {
    company_name: string;
    document: string;
    city: string;
    state: string;
  };
  project_procedure: {
    id: string;
    sequence: number;
  };
  created_at: string;
}

const AMOUNT_PER_PAGE = 10;

const List: React.FC = () => {
  const { user } = useAuth();
  const { addToast } = useToast();
  const [page, setPage] = useState(0);
  const [count, setCount] = useState(0);
  const [filter, setFilter] = useState('');
  const [lastRefresh, setLastRefresh] = useState(new Date());
  const [sponsorshipProjects, setSponsorshipProjects] = useState<
    ISponsorshipProject[]
  >([]);
  const [sponsorshipProjectsIds, setSponsorshipProjectsIds] = useState<
    string[]
  >([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [isOpenReport, setIsOpenReport] = useState(false);
  const [projectIdDelete, setProjectIdDelete] = useState('');
  const [isOpenDelete, setIsOpenDelete] = useState(false);
  const [isNewReqEnabled, setIsNewReqEnabled] = useState(false);
  const [titleDelete, setTitleDelete] = useState('');
  const [subTitleDelete, setSubTitleDelete] = useState('');

  useEffect(() => {
    const ids: string[] = [];
    if (sponsorshipProjects) {
      sponsorshipProjects.map(sponsorshipProject =>
        ids.push(sponsorshipProject.id),
      );

      setSponsorshipProjectsIds(ids);
    }
  }, [sponsorshipProjects]);

  useEffect(() => {
    api.get('/generalsettings').then(response => {
      if (response.data) {
        setIsNewReqEnabled(response.data.new_sponsorship_req_enabled);
      }
    });
  }, []);

  useEffect(() => {
    const loadSponsorshipProjects = async () => {
      await api
        .get(
          `/projects?type=PATROCINIO` +
            `&page=${page}` +
            `&filter=${filter}` +
            `&amount=${AMOUNT_PER_PAGE}` +
            `&user_type=${user.user_type.type}` +
            `&erp_rep_code=${
              user.user_type.type === 'REP' ? user.erp_rep_code : ''
            }`,
        )
        .then(response => {
          setSponsorshipProjects(response.data[0]);
          setCount(response.data[1]);
          setLoading(false);
        });
    };

    loadSponsorshipProjects();
  }, [page, filter, user, lastRefresh]);

  function handleDeleteButton(
    sponsorshipProjectReceived: ISponsorshipProject,
  ): React.ReactNode | null {
    if (
      sponsorshipProjectReceived.project_procedure.sequence === 2 &&
      sponsorshipProjectReceived.status === 'Pendente' &&
      (user.user_type.type === 'REP' || user.user_type.type === 'ADM')
    ) {
      return (
        <DeleteButton
          onClick={() => {
            setProjectIdDelete(sponsorshipProjectReceived.id);
            setIsOpenDelete(true);
            setTitleDelete(
              `Deseja realmente excluir a solicitação ${sponsorshipProjectReceived.code} ` +
                `do cliente ${sponsorshipProjectReceived.local_client.company_name}, ` +
                `aberta no dia ${new Date(
                  sponsorshipProjectReceived.created_at,
                ).toLocaleDateString()}?`,
            );
            setSubTitleDelete('Esta operação não poderá ser desfeita!');
          }}
        />
      );
    }

    return null;
  }

  const onDelete = useCallback(
    async (id: string) => {
      try {
        await api.delete(`/projects/${id}`).then(() => {
          setPage(0);
          setLastRefresh(new Date());

          addToast({
            type: 'success',
            title: 'Solicitação excluída!',
            description: 'Solicitação excluída com sucesso.',
          });
        });
      } catch (error: any) {
        addToast({
          type: 'error',
          title: 'Ocorreu um erro!',
          description: error.response.data.message,
        });
      }
      setProjectIdDelete('');
      setTitleDelete('');
      setSubTitleDelete('');
    },
    [addToast],
  );

  function handleNewRequest(): React.ReactNode | null {
    if (getPermByName('proj_spons_proc_01', user)) {
      if (isNewReqEnabled) {
        return (
          <Link
            to={
              user.user_type.type === 'REP'
                ? '/projects/sponsorships/procedure1/client'
                : '/projects/sponsorships/procedure1/representative'
            }
          >
            <NewButton />
          </Link>
        );
      }

      return (
        <DefaultTooltip title="Função indisponível">
          <NewButton disabled />
        </DefaultTooltip>
      );
    }

    return null;
  }

  return (
    <Container style={{ minHeight: 600 }}>
      <BreadCrumb
        main="Solicitações"
        path="/projects/main"
        firstChild="Pagamentos"
        mainIcon={FaProjectDiagram}
        firstChildIcon={FaMoneyBillWave}
        mainColored
      />

      <ContentSplit>
        <FilterContent>
          <DefaultInput
            name="search"
            labelText="Pesquisar"
            type="text"
            onChange={event => {
              setFilter(event.target.value);
              setPage(0);
            }}
          />
        </FilterContent>

        <ReportContent>
          <ReportButton onClick={() => setIsOpenReport(true)} />
        </ReportContent>
      </ContentSplit>

      <Table>
        <thead>
          <tr>
            <th style={{ width: '10%', textAlign: 'left' }}>Data Abertura</th>
            <th style={{ width: '10%', textAlign: 'left' }}>Código</th>
            <th style={{ width: '20%', textAlign: 'left' }}>Cliente</th>
            <th style={{ width: '15%', textAlign: 'left' }}>Cidade</th>
            <th style={{ width: '5%', textAlign: 'left' }}>UF</th>
            <th style={{ width: '20%', textAlign: 'left' }}>Item</th>
            <th style={{ width: '10%', textAlign: 'left' }}>Situação</th>
            <th style={{ width: '10%', textAlign: 'center' }} />
          </tr>
        </thead>

        <tbody>
          {loading ? (
            <tr>
              <Td colSpan={8} style={{ padding: '3rem', textAlign: 'center' }}>
                <PropagateLoader color="#276c8d" />
              </Td>
            </tr>
          ) : sponsorshipProjects.length > 0 ? (
            sponsorshipProjects.map(sponsorshipProject => (
              <tr key={sponsorshipProject.id}>
                <Td
                  style={{ width: '10%', textAlign: 'left' }}
                  heading="DATA ABERTURA"
                >
                  {new Date(sponsorshipProject.created_at).toLocaleDateString()}
                </Td>
                <Td
                  style={{ width: '10%', textAlign: 'left' }}
                  heading="CÓDIGO"
                >
                  {sponsorshipProject.code}
                </Td>
                <Td
                  style={{ width: '20%', textAlign: 'left' }}
                  heading="CLIENTE"
                >
                  {`${
                    sponsorshipProject.local_client.company_name
                  } (${getFormattedCnpj(
                    sponsorshipProject.local_client.document,
                  )})`}
                </Td>
                <Td
                  style={{ width: '15%', textAlign: 'left' }}
                  heading="CIDADE"
                >
                  {sponsorshipProject.local_client.city}
                </Td>
                <Td style={{ width: '5%', textAlign: 'left' }} heading="UF">
                  {sponsorshipProject.local_client.state}
                </Td>
                <Td style={{ width: '20%', textAlign: 'left' }} heading="ITEM">
                  {sponsorshipProject.sponsorship.name}
                </Td>
                <Td
                  style={{ width: '10%', textAlign: 'left' }}
                  heading="SITUAÇÃO"
                >
                  {sponsorshipProject.status}
                </Td>
                <Td
                  style={{
                    width: '10%',
                    display: 'flex',
                    textAlign: 'center',
                  }}
                  heading={' '}
                >
                  <RowButtonsContent>
                    <div style={{ width: 35 }}>
                      {getEditProjPerm(
                        sponsorshipProject.status,
                        sponsorshipProject.project_procedure.id,
                        user,
                        sponsorshipProject.gross_discount_price,
                      ) && (
                        <Link
                          to={{
                            pathname:
                              `/projects/sponsorships/procedure` +
                              `${sponsorshipProject.project_procedure.sequence}`,
                            state: sponsorshipProject,
                          }}
                        >
                          <EditButton />
                        </Link>
                      )}
                    </div>

                    <div style={{ width: 35 }}>
                      <Link
                        to={{
                          pathname: `/projects/sponsorships/view`,
                          state: sponsorshipProject,
                        }}
                      >
                        <ViewButton />
                      </Link>
                    </div>

                    <div style={{ width: 35 }}>
                      {handleDeleteButton(sponsorshipProject)}
                    </div>
                  </RowButtonsContent>
                </Td>
              </tr>
            ))
          ) : (
            <tr>
              <Td colSpan={8} style={{ textAlign: 'center' }}>
                Nenhuma informação disponível
              </Td>
            </tr>
          )}
        </tbody>
      </Table>

      <ButtonsContent>
        <Pagination
          page={page}
          count={count}
          amountPerPage={AMOUNT_PER_PAGE}
          setPage={setPage}
        />

        {handleNewRequest()}
      </ButtonsContent>

      <ReportDialog
        isOpen={isOpenReport}
        setIsOpen={setIsOpenReport}
        projectType="PATROCINIO"
        ids={sponsorshipProjectsIds}
      />

      <ConfirmDeleteDialog
        isOpen={isOpenDelete}
        title={titleDelete}
        subTitle={subTitleDelete}
        setIsOpen={setIsOpenDelete}
        onClose={() => {
          onDelete(projectIdDelete);
        }}
      />
    </Container>
  );
};

export default List;
