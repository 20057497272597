import React, { useState, useEffect, useCallback } from 'react';
import { FaProjectDiagram, FaFolder } from 'react-icons/fa';
import { PropagateLoader } from 'react-spinners';
import { Link } from 'react-router-dom';

import BreadCrumb from '~/components/BreadCrumb';
import ConfirmDeleteDialog from '~/components/Dialogs/ConfirmDelete';
import DefaultInput from '~/components/Inputs/Default';
import DefaultTooltip from '~/components/Tooltips/Default';
import DeleteButton from '~/components/Buttons/Delete';
import EditButton from '~/components/Buttons/Edit';
import NewAltButton from '~/components/Buttons/NewAlt';
import NewButton from '~/components/Buttons/New';
import OptionButton from '~/components/Buttons/Option';
import Pagination from '~/components/Pagination';
import ReportButton from '~/components/Buttons/Report';
import ReportDialog from '~/components/Dialogs/Report';
import ViewButton from '~/components/Buttons/View';

import { getEditProjPerm, getFormattedCnpj, getPermByName } from '~/utils';

import { useAuth } from '~/hooks/auth';
import { useToast } from '~/hooks/toast';

import api from '~/services/api';

import {
  Container,
  ContentSplit,
  FilterContent,
  FilterOptionsContent,
  ReportContent,
  Table,
  Td,
  RowButtonsContent,
  ButtonsContent,
} from './styles';

interface IFolderProject {
  id: string;
  code: string;
  status: string;
  batch: string;
  folder: {
    name: string;
  };
  local_client: {
    company_name: string;
    document: string;
    city: string;
    state: string;
  };
  project_procedure: {
    id: string;
    sequence: number;
  };
  created_at: string;
}

const AMOUNT_PER_PAGE = 10;

const List: React.FC = () => {
  const { user } = useAuth();
  const { addToast } = useToast();
  const [page, setPage] = useState(0);
  const [count, setCount] = useState(0);
  const [filter, setFilter] = useState('');
  const [option, setOption] = useState('all');
  const [lastRefresh, setLastRefresh] = useState(new Date());
  const [folderProjects, setFolderProjects] = useState<IFolderProject[]>([]);
  const [folderProjectsIds, setFolderProjectsIds] = useState<string[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [isOpenReport, setIsOpenReport] = useState(false);
  const [projectIdDelete, setProjectIdDelete] = useState('');
  const [isOpenDelete, setIsOpenDelete] = useState(false);
  const [isNewReqEnabled, setIsNewReqEnabled] = useState(false);
  const [titleDelete, setTitleDelete] = useState('');
  const [subTitleDelete, setSubTitleDelete] = useState('');

  useEffect(() => {
    const ids: string[] = [];
    if (folderProjects) {
      folderProjects.map(folderProject => ids.push(folderProject.id));

      setFolderProjectsIds(ids);
    }
  }, [folderProjects]);

  useEffect(() => {
    api.get('/generalsettings').then(response => {
      if (response.data) {
        setIsNewReqEnabled(response.data.new_folder_req_enabled);
      }
    });
  }, []);

  useEffect(() => {
    const loadFolderProjects = async () => {
      await api
        .get(
          `/projects?type=PASTA` +
            `&page=${page}` +
            `&filter=${filter}` +
            `&amount=${AMOUNT_PER_PAGE}` +
            `&user_type=${user.user_type.type}` +
            `&erp_rep_code=${
              user.user_type.type === 'REP' ? user.erp_rep_code : ''
            }` +
            `&option=${option}`,
        )
        .then(response => {
          setFolderProjects(response.data[0]);
          setCount(response.data[1]);
          setLoading(false);
        });
    };

    loadFolderProjects();
  }, [page, filter, user, option, lastRefresh]);

  function getEditData(folderProjectReceived: IFolderProject): any {
    const editDataObject: any = folderProjectReceived;
    editDataObject.origin = 'project';

    return editDataObject;
  }

  function handleDeleteButton(
    folderProjectReceived: IFolderProject,
  ): React.ReactNode | null {
    if (
      folderProjectReceived.project_procedure.sequence === 2 &&
      folderProjectReceived.status === 'Pendente' &&
      (user.user_type.type === 'REP' || user.user_type.type === 'ADM')
    ) {
      return (
        <DeleteButton
          onClick={() => {
            setProjectIdDelete(folderProjectReceived.id);
            setIsOpenDelete(true);
            setTitleDelete(
              `Deseja realmente excluir a solicitação ${folderProjectReceived.code} ` +
                `do cliente ${folderProjectReceived.local_client.company_name}, ` +
                `aberta no dia ${new Date(
                  folderProjectReceived.created_at,
                ).toLocaleDateString()}?`,
            );
            setSubTitleDelete('Esta operação não poderá ser desfeita!');
          }}
        />
      );
    }

    return null;
  }

  const onDelete = useCallback(
    async (id: string) => {
      try {
        await api.delete(`/projects/${id}`).then(() => {
          setPage(0);
          setLastRefresh(new Date());

          addToast({
            type: 'success',
            title: 'Solicitação excluída!',
            description: 'Solicitação excluída com sucesso.',
          });
        });
      } catch (error: any) {
        addToast({
          type: 'error',
          title: 'Ocorreu um erro!',
          description: error.response.data.message,
        });
      }
      setProjectIdDelete('');
      setTitleDelete('');
      setSubTitleDelete('');
    },
    [addToast],
  );

  function getNewLinkPath(): any {
    if (user.user_type.type === 'REP') {
      return {
        pathname: `/projects/folders/procedure1/client`,
        state: {
          code: user.erp_rep_code,
        },
      };
    }

    return '/projects/folders/procedure1/representative';
  }

  function handleNewRequest(): React.ReactNode | null {
    if (getPermByName('proj_folder_proc_01', user)) {
      if (isNewReqEnabled) {
        return (
          <Link to={getNewLinkPath()}>
            <NewButton />
          </Link>
        );
      }

      return (
        <DefaultTooltip title="Função indisponível">
          <NewButton disabled />
        </DefaultTooltip>
      );
    }

    return null;
  }

  function handleNewBatches(): React.ReactNode | null {
    if (getPermByName('proj_folder_batches', user)) {
      if (isNewReqEnabled) {
        return (
          <div style={{ marginLeft: '1rem' }}>
            <Link to="/projects/folders/folderbatches">
              <NewAltButton label="Novos Lotes" />
            </Link>
          </div>
        );
      }

      return (
        <DefaultTooltip title="Função indisponível">
          <NewAltButton
            label="Novos Lotes"
            disabled
            style={{ marginLeft: '1rem' }}
          />
        </DefaultTooltip>
      );
    }

    return null;
  }

  return (
    <Container style={{ minHeight: 600 }}>
      <BreadCrumb
        main="Solicitações"
        path="/projects/main"
        firstChild="Pastas"
        mainIcon={FaProjectDiagram}
        firstChildIcon={FaFolder}
        mainColored
      />

      <FilterContent>
        <DefaultInput
          name="search"
          labelText="Pesquisar"
          type="text"
          onChange={event => {
            setFilter(event.target.value);
            setPage(0);
          }}
        />
      </FilterContent>

      <ContentSplit>
        <FilterOptionsContent>
          <OptionButton
            label="Todos"
            isActive={option === 'all'}
            onClick={() => {
              setOption('all');
              setPage(0);
            }}
          />

          <OptionButton
            label="Lotes"
            isActive={option === 'batches'}
            onClick={() => {
              setOption('batches');
              setPage(0);
            }}
          />

          <OptionButton
            label="Sem Lote"
            isActive={option === 'no_batch'}
            onClick={() => {
              setOption('no_batch');
              setPage(0);
            }}
          />
        </FilterOptionsContent>

        <ReportContent>
          <ReportButton onClick={() => setIsOpenReport(true)} />
        </ReportContent>
      </ContentSplit>

      <Table>
        <thead>
          <tr>
            <th style={{ width: '10%', textAlign: 'left' }}>Data Abertura</th>
            <th style={{ width: '10%', textAlign: 'left' }}>Código</th>
            <th style={{ width: '20%', textAlign: 'left' }}>Cliente</th>
            <th style={{ width: '10%', textAlign: 'left' }}>Cidade</th>
            <th style={{ width: '5%', textAlign: 'left' }}>UF</th>
            <th style={{ width: '15%', textAlign: 'left' }}>Item</th>
            <th style={{ width: '10%', textAlign: 'left' }}>Lote</th>
            <th style={{ width: '10%', textAlign: 'left' }}>Situação</th>
            <th style={{ width: '10%', textAlign: 'center' }} />
          </tr>
        </thead>

        <tbody>
          {loading ? (
            <tr>
              <Td colSpan={9} style={{ padding: '3rem', textAlign: 'center' }}>
                <PropagateLoader color="#276c8d" />
              </Td>
            </tr>
          ) : folderProjects.length > 0 ? (
            folderProjects.map(folderProject => (
              <tr key={folderProject.id}>
                <Td
                  style={{ width: '10%', textAlign: 'left' }}
                  heading="DATA ABERTURA"
                >
                  {new Date(folderProject.created_at).toLocaleDateString()}
                </Td>
                <Td
                  style={{ width: '10%', textAlign: 'left' }}
                  heading="CÓDIGO"
                >
                  {folderProject.code}
                </Td>
                <Td
                  style={{ width: '20%', textAlign: 'left' }}
                  heading="CLIENTE"
                >
                  {`${
                    folderProject.local_client.company_name
                  } (${getFormattedCnpj(folderProject.local_client.document)})`}
                </Td>
                <Td
                  style={{ width: '10%', textAlign: 'left' }}
                  heading="CIDADE"
                >
                  {folderProject.local_client.city}
                </Td>
                <Td style={{ width: '5%', textAlign: 'left' }} heading="UF">
                  {folderProject.local_client.state}
                </Td>
                <Td style={{ width: '15%', textAlign: 'left' }} heading="ITEM">
                  {folderProject.folder.name}
                </Td>
                <Td style={{ width: '10%', textAlign: 'left' }} heading="LOTE">
                  {folderProject.batch || 'Sem lote'}
                </Td>
                <Td
                  style={{ width: '10%', textAlign: 'left' }}
                  heading="SITUAÇÃO"
                >
                  {folderProject.status}
                </Td>
                <Td
                  style={{ width: '10%', display: 'flex', textAlign: 'center' }}
                  heading={' '}
                >
                  <RowButtonsContent>
                    <div style={{ width: 35 }}>
                      {getEditProjPerm(
                        folderProject.status,
                        folderProject.project_procedure.id,
                        user,
                      ) && (
                        <Link
                          to={{
                            pathname:
                              `/projects/folders/procedure` +
                              `${folderProject.project_procedure.sequence}`,
                            state: getEditData(folderProject),
                          }}
                        >
                          <EditButton />
                        </Link>
                      )}
                    </div>

                    <div style={{ width: 35 }}>
                      <Link
                        to={{
                          pathname: `/projects/folders/view`,
                          state: folderProject,
                        }}
                      >
                        <ViewButton />
                      </Link>
                    </div>

                    <div style={{ width: 35 }}>
                      {handleDeleteButton(folderProject)}
                    </div>
                  </RowButtonsContent>
                </Td>
              </tr>
            ))
          ) : (
            <tr>
              <Td colSpan={9} style={{ textAlign: 'center' }}>
                Nenhuma informação disponível
              </Td>
            </tr>
          )}
        </tbody>
      </Table>

      <ButtonsContent>
        <Pagination
          page={page}
          count={count}
          amountPerPage={AMOUNT_PER_PAGE}
          setPage={setPage}
        />

        {handleNewRequest()}

        {handleNewBatches()}
      </ButtonsContent>

      <ReportDialog
        isOpen={isOpenReport}
        setIsOpen={setIsOpenReport}
        projectType="PASTA"
        ids={folderProjectsIds}
      />

      <ConfirmDeleteDialog
        isOpen={isOpenDelete}
        title={titleDelete}
        subTitle={subTitleDelete}
        setIsOpen={setIsOpenDelete}
        onClose={() => {
          onDelete(projectIdDelete);
        }}
      />
    </Container>
  );
};

export default List;
