import React from 'react';

import { getPermByPath } from '~/utils';

import { useAuth } from '~/hooks/auth';

import Dashboard from '~/pages/Dashboard';

interface IRendererProps {
  path: string;
  component?: React.ComponentType;
}

const Renderer = ({
  path,
  component: Component,
}: IRendererProps): JSX.Element => {
  const { user } = useAuth();

  if (Component && getPermByPath(user.feature_paths, path)) {
    return <Component />;
  }

  return <Dashboard />;
};

export default Renderer;
