import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { FaProjectDiagram, FaBoxOpen } from 'react-icons/fa';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { v4 as uuidv4 } from 'uuid';

import BreadCrumb from '~/components/BreadCrumb';
import NewProjectInfo from '~/components/ProjectInfo/New';
import DefaultInput from '~/components/Inputs/Default';
import PhoneInput from '~/components/Inputs/Phone';
import TableSelect from '~/components/TableSelect';
import TableWholeNumberInput from '~/components/TableInputs/WholeNumber';
import TableDefaultInput from '~/components/TableInputs/Default';
import AddRowButton from '~/components/Buttons/AddRow';
import DeleteRowButton from '~/components/Buttons/DeleteRow';
import TextArea from '~/components/TextArea';
import UploadRequestFiles from '~/components/UploadRequestFiles';
import UploadFiles from '~/components/UploadFiles';
import SaveButton from '~/components/Buttons/Save';
import ExitButton from '~/components/Buttons/Exit';
import ConfirmProcedureDialog from '~/components/Dialogs/ConfirmProcedure';
import ConfirmExitDialog from '~/components/Dialogs/ConfirmExit';
import NoticeDialog from '~/components/Dialogs/Notice';

import { getFormattedCurrency, getNumberFormatValue } from '~/utils';

import { useAuth } from '~/hooks/auth';
import { useToast } from '~/hooks/toast';

import api from '~/services/api';

import {
  Container,
  Content,
  Wrapper,
  Label,
  BorderContent,
  FlexWrapper,
  ButtonContainer,
} from './styles';

interface IFormProcedure1 {
  note: string;
  client_contact_name: string;
  client_contact_phone: string;
  client_contact_email: string;
}

interface ILocation {
  erp_client_code?: string;
  erp_client_name?: string;
  erp_client_company_name?: string;
  erp_client_document?: string;
  erp_client_address?: string;
  erp_client_city?: string;
  erp_client_state?: string;
  erp_rep_code: string;
  rep_as_client: boolean;
}

const schema = Yup.object().shape({
  client_contact_name: Yup.string().required('Campo obrigatório'),
  client_contact_phone: Yup.string()
    .required('Campo obrigatório')
    .min(14, 'Telefone/celular inválido')
    .max(15, 'Telefone/celular inválido'),
  client_contact_email: Yup.string()
    .required('Campo obrigatório')
    .email('E-mail inválido'),
});

const Procedure1: React.FC = () => {
  const history = useHistory();
  const { user } = useAuth();
  const { addToast } = useToast();
  const { state } = useLocation<ILocation>();
  const [options, setOptions] = useState<
    {
      value: string;
      label: string;
      price: number;
      enabled: boolean;
    }[]
  >([]);
  const [projectSamples, setProjectSamples] = useState<
    {
      temp_id: string;
      total_amount: number;
      price: number;
      total_price: number;
      sample: {
        value: string;
      };
    }[]
  >([
    {
      temp_id: uuidv4(),
      total_amount: 0,
      price: 0,
      total_price: 0,
      sample: {
        value: '',
      },
    },
  ]);
  const projectTemp = useRef(uuidv4());
  const projectRequestTemp = useRef(uuidv4());
  const [isOpenConfirmProcedure, setIsOpenConfirmProcedure] = useState(false);
  const [isOpenExit, setIsOpenExit] = useState(false);
  const [notice, setNotice] = useState<{
    title: string;
    subtitle: string;
  }>({
    title: '',
    subtitle: '',
  });
  const [isOpenNotice, setIsOpenNotice] = useState(false);
  const [isSaving, setIsSaving] = useState(false);

  const { handleSubmit, register, setValue, errors } = useForm<IFormProcedure1>(
    { resolver: yupResolver(schema) },
  );

  useEffect(() => {
    const checkEnabledFunctionality = async () => {
      await api.get('/generalsettings').then(response => {
        if (response.data && !response.data.new_sample_req_enabled) {
          history.push('/');
        }
      });
    };

    checkEnabledFunctionality();
  }, [history]);

  useEffect(() => {
    if (state === undefined) {
      addToast({
        type: 'error',
        title: 'Operação inválida!',
        description: 'Cliente não selecionado',
      });

      history.push('/projects/samples');
    } else {
      api.get('/items/samples/valid').then(response => {
        setOptions(
          response.data.map((sample: any) => ({
            value: sample.id,
            label: sample.name,
            price: sample.price,
            enabled: true,
          })),
        );
      });
    }
  }, [addToast, history, state, user, register, setValue]);

  const submitForm = useCallback(
    async (data: IFormProcedure1) => {
      try {
        setIsSaving(true);

        await api.post('/projects', {
          ...data,
          price: projectSamples.reduce((acc, p_s) => acc + p_s.total_price, 0),
          project_request_temp_id: projectRequestTemp.current,
          project_samples: projectSamples,
          project_temp_id: projectTemp.current,
          erp_client_code: state.erp_client_code,
          erp_rep_code: state.erp_rep_code,
          record_note: data.note,
          record_description: 'Abertura de solicitação',
          rep_as_client: state.rep_as_client,
          type: 'AMOSTRA',
        });

        addToast({
          type: 'success',
          title: 'Projeto iniciado!',
          description: 'Abertura de projeto realizado com sucesso.',
        });

        history.push('/projects/samples');
      } catch (error: any) {
        setIsSaving(false);

        addToast({
          type: 'error',
          title: 'Ocorreu um erro!',
          description: error.response.data.message,
        });
      }
    },
    [addToast, history, projectRequestTemp, projectSamples, projectTemp, state],
  );

  const onExit = () => {
    api.delete(`projectfilesreceived/projecttempid/${projectTemp.current}`);
    api.delete(
      `projectrequestfilesreceived/projectrequesttempid/${projectRequestTemp.current}`,
    );

    history.push('/projects/samples');
  };

  function handleFiles(): React.ReactNode {
    if (user.user_type.type !== 'REP') {
      return (
        <Content>
          <UploadFiles
            type="anexo"
            project_temp_id={projectTemp.current}
            project_type="AMOSTRA"
            sequence={1}
            user_id={user.id}
          />
        </Content>
      );
    }

    return <></>;
  }

  return (
    <Container>
      <BreadCrumb
        main="Solicitações"
        path="/projects/main"
        firstChildPath="/projects/samples"
        firstChild="Amostras"
        secondChild="Nova Solicitação"
        mainIcon={FaProjectDiagram}
        firstChildIcon={FaBoxOpen}
        secondChildIcon={FaBoxOpen}
        mainColored
        firstChildColored
      />

      <NewProjectInfo
        rep_as_client={state?.rep_as_client}
        erp_rep_code={state?.erp_rep_code}
        erp_client_code={state?.erp_client_code}
      />

      <form onSubmit={handleSubmit(submitForm)}>
        <Label>Contato da Loja</Label>

        <BorderContent style={{ marginBottom: '1.8rem' }}>
          <FlexWrapper style={{ marginBottom: '2rem' }}>
            <Wrapper style={{ width: '60%', marginRight: '1rem' }}>
              <DefaultInput
                name="client_contact_name"
                labelFor="client_contact_name"
                labelText="Nome"
                type="text"
                error={errors?.client_contact_name?.message}
                ref={register}
                maxLength={60}
              />
            </Wrapper>

            <Wrapper style={{ width: '40%' }}>
              <PhoneInput
                name="client_contact_phone"
                labelFor="client_contact_phone"
                labelText="Telefone"
                type="text"
                error={errors?.client_contact_phone?.message}
                ref={register}
              />
            </Wrapper>
          </FlexWrapper>

          <FlexWrapper style={{ marginBottom: '2rem' }}>
            <Wrapper style={{ width: '100%' }}>
              <DefaultInput
                name="client_contact_email"
                labelFor="client_contact_email"
                labelText="E-mail"
                type="text"
                error={errors?.client_contact_email?.message}
                ref={register}
                maxLength={60}
              />
            </Wrapper>
          </FlexWrapper>
        </BorderContent>

        <div style={{ marginBottom: '0.5rem' }}>
          <Label>Amostras</Label>
        </div>

        <div>
          {options.length > 1 && (
            <>
              {projectSamples.map(projectSample => (
                <BorderContent
                  key={projectSample.temp_id}
                  style={{ marginBottom: 15 }}
                >
                  <div
                    style={{
                      display: 'flex',
                      flexWrap: 'wrap',
                      columnGap: 10,
                      rowGap: 10,
                    }}
                  >
                    <Wrapper style={{ width: 250 }}>
                      <TableSelect
                        name={`item_${projectSample.temp_id}`}
                        label="Item"
                        options={options.filter(op => op.enabled)}
                        onSet={(e: any) => {
                          const prevValue = projectSample.sample.value;

                          setProjectSamples(prevProjSamples =>
                            prevProjSamples.map(prevProjSample => {
                              if (
                                prevProjSample.temp_id === projectSample.temp_id
                              ) {
                                const findSample = options.find(
                                  op => op.value === e.value,
                                );

                                return {
                                  ...prevProjSample,
                                  sample: {
                                    value: e.value,
                                  },
                                  price: findSample?.price || 0,
                                  total_price:
                                    prevProjSample.total_amount *
                                    (findSample?.price || 0),
                                };
                              }

                              return { ...prevProjSample };
                            }),
                          );

                          setOptions(
                            options.map(op => {
                              if (op.value === prevValue) {
                                op.enabled = true;
                              }

                              if (op.value === e.value) {
                                op.enabled = false;
                              }

                              return op;
                            }),
                          );
                        }}
                      />
                    </Wrapper>

                    <Wrapper style={{ width: 150 }}>
                      <TableWholeNumberInput
                        name={`total_amount_${projectSample.temp_id}`}
                        labelText="Quantidade"
                        defaultValue={projectSample.total_amount}
                        onSet={e => {
                          setProjectSamples(prevProjSamples =>
                            prevProjSamples.map(prevProjSample => {
                              if (
                                prevProjSample.temp_id === projectSample.temp_id
                              ) {
                                const totalAmount = getNumberFormatValue(
                                  e.target.value,
                                );

                                return {
                                  ...prevProjSample,
                                  total_amount: totalAmount,
                                  total_price:
                                    totalAmount * prevProjSample.price,
                                };
                              }

                              return { ...prevProjSample };
                            }),
                          );
                        }}
                      />
                    </Wrapper>

                    <Wrapper style={{ width: 200 }}>
                      <TableDefaultInput
                        name={`price_${projectSample.temp_id}`}
                        labelText="Valor Unitário"
                        value={getFormattedCurrency(projectSample.price)}
                        disabled
                      />
                    </Wrapper>

                    <Wrapper style={{ width: 200 }}>
                      <TableDefaultInput
                        name={`total_price_${projectSample.temp_id}`}
                        labelText="Valor Total"
                        value={getFormattedCurrency(projectSample.total_price)}
                        disabled
                      />
                    </Wrapper>
                  </div>

                  <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <DeleteRowButton
                      type="button"
                      onClick={() => {
                        setOptions(
                          options.map(op => {
                            if (op.value === projectSample.sample.value) {
                              op.enabled = true;
                            }

                            return op;
                          }),
                        );

                        setProjectSamples(prevProjSamples =>
                          prevProjSamples.filter(
                            prevProjSample =>
                              prevProjSample.temp_id !== projectSample.temp_id,
                          ),
                        );
                      }}
                    />
                  </div>
                </BorderContent>
              ))}
            </>
          )}
        </div>

        <div
          style={{
            maxWidth: 680,
            display: 'flex',
            justifyContent: 'flex-end',
            margin: '1rem 0 2rem 0',
          }}
        >
          <AddRowButton
            type="button"
            onClick={() => {
              setProjectSamples(prevProjectSamples => [
                ...prevProjectSamples,
                {
                  temp_id: uuidv4(),
                  total_amount: 0,
                  price: 0,
                  total_price: 0,
                  sample: {
                    value: '',
                  },
                },
              ]);
            }}
          />
        </div>

        <Content>
          <Wrapper style={{ width: '100%', marginBottom: '2rem' }}>
            <TextArea
              name="note"
              labelFor="note"
              labelText="Observação"
              error={errors?.note?.message}
              ref={register}
              maxLength={1000}
            />
          </Wrapper>
        </Content>

        <Content>
          <UploadRequestFiles
            type="requisicao"
            project_request_temp_id={projectRequestTemp.current}
            project_type="AMOSTRA"
            sequence={1}
            user_id={user.id}
          />
        </Content>

        {handleFiles()}

        <ButtonContainer>
          <SaveButton
            type="button"
            processing={isSaving}
            disabled={isSaving}
            onClick={() => {
              if (
                projectSamples.filter(
                  projSample => projSample.sample.value === '',
                ).length > 0
              ) {
                setNotice({
                  title: 'Itens inválidos!',
                  subtitle: 'Necessário selecionar item de amostra!',
                });
                setIsOpenNotice(true);
              } else if (
                projectSamples.filter(
                  projSample => projSample.total_amount <= 0,
                ).length > 0
              ) {
                setNotice({
                  title: 'Existem amostras sem quantidade informada!',
                  subtitle:
                    'É necessário informar quantidade em todas as amostras.',
                });

                setIsOpenNotice(true);
              } else if (
                projectSamples.filter(projSample => projSample.price <= 0)
                  .length > 0
              ) {
                setNotice({
                  title: 'Existem amostras sem valor!',
                  subtitle:
                    'É necessário informar valor em cadastro de amostras.',
                });

                setIsOpenNotice(true);
              } else {
                setIsOpenConfirmProcedure(true);
              }
            }}
          />

          <ExitButton
            style={{ marginLeft: 10 }}
            type="button"
            disabled={isSaving}
            onClick={() => setIsOpenExit(true)}
          />
        </ButtonContainer>
      </form>

      <ConfirmProcedureDialog
        isOpen={isOpenConfirmProcedure}
        setIsOpen={setIsOpenConfirmProcedure}
        onConfirm={() => handleSubmit(submitForm)()}
        title="Confirma abertura do projeto?"
        subTitle="O projeto será enviado para o próximo procedimento"
        opConfirm="Sim"
      />

      <ConfirmExitDialog
        isOpen={isOpenExit}
        setIsOpen={setIsOpenExit}
        onExit={onExit}
      />

      <NoticeDialog
        isOpen={isOpenNotice}
        type="error"
        title={notice.title}
        subTitle={notice.subtitle}
        setIsOpen={setIsOpenNotice}
      />
    </Container>
  );
};

export default Procedure1;
