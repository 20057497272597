import React, { useState, useEffect, useCallback } from 'react';
import { FaProjectDiagram, FaCalendarAlt } from 'react-icons/fa';
import { PropagateLoader } from 'react-spinners';
import { Link } from 'react-router-dom';

import BreadCrumb from '~/components/BreadCrumb';
import ConfirmDeleteDialog from '~/components/Dialogs/ConfirmDelete';
import DateInput from '~/components/Inputs/Date';
import DefaultInput from '~/components/Inputs/Default';
import DefaultTooltip from '~/components/Tooltips/Default';
import DeleteButton from '~/components/Buttons/Delete';
import EditButton from '~/components/Buttons/Edit';
import FixButton from '~/components/Buttons/Fix';
import NewButton from '~/components/Buttons/New';
import Pagination from '~/components/Pagination';
import ReportButton from '~/components/Buttons/Report';
import ReportDialog from '~/components/Dialogs/Report';
import ViewButton from '~/components/Buttons/View';

import { getEditProjPerm, getFormattedCnpj, getPermByName } from '~/utils';

import { useAuth } from '~/hooks/auth';
import { useToast } from '~/hooks/toast';

import api from '~/services/api';

import {
  Container,
  ContentSplit,
  FilterContent,
  FilterDatesContent,
  FilterDateWrapper,
  ReportContent,
  Table,
  Td,
  RowButtonsContent,
  ButtonsContent,
} from './styles';

interface IEventProject {
  id: string;
  code: string;
  event_date: Date;
  status: string;
  material_delivery_status: string;
  event: {
    name: string;
  };
  local_client: {
    company_name: string;
    document: string;
    city: string;
    state: string;
  };
  local_representative: {
    name: string;
  };
  project_procedure: {
    id: string;
    sequence: number;
  };
}

const AMOUNT_PER_PAGE = 10;

const List: React.FC = () => {
  const { user } = useAuth();
  const { addToast } = useToast();
  const [page, setPage] = useState(0);
  const [count, setCount] = useState(0);
  const [filter, setFilter] = useState('');
  const [lastRefresh, setLastRefresh] = useState(new Date());
  const [initialEventDate, setInitialEventDate] = useState<Date>();
  const [endEventDate, setEndEventDate] = useState<Date>();
  const [eventProjects, setEventProjects] = useState<IEventProject[]>([]);
  const [eventProjectsIds, setEventProjectsIds] = useState<string[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [isOpenReport, setIsOpenReport] = useState(false);
  const [projectIdDelete, setProjectIdDelete] = useState('');
  const [isOpenDelete, setIsOpenDelete] = useState(false);
  const [isNewReqEnabled, setIsNewReqEnabled] = useState(false);
  const [titleDelete, setTitleDelete] = useState('');
  const [subTitleDelete, setSubTitleDelete] = useState('');

  useEffect(() => {
    const ids: string[] = [];
    if (eventProjects) {
      eventProjects.map(eventProject => ids.push(eventProject.id));

      setEventProjectsIds(ids);
    }
  }, [eventProjects]);

  useEffect(() => {
    api.get('/generalsettings').then(response => {
      if (response.data) {
        setIsNewReqEnabled(response.data.new_event_req_enabled);
      }
    });
  }, []);

  useEffect(() => {
    const loadEventProjects = async () => {
      await api
        .get(
          `/projects?type=EVENTO` +
            `&page=${page}` +
            `&filter=${filter}` +
            `&amount=${AMOUNT_PER_PAGE}` +
            `&user_type=${user.user_type.type}` +
            `&erp_rep_code=${
              user.user_type.type === 'REP' ? user.erp_rep_code : ''
            }` +
            `&initial_event_date=${initialEventDate}` +
            `&end_event_date=${endEventDate}`,
        )
        .then(response => {
          setEventProjects(response.data[0]);
          setCount(response.data[1]);
          setLoading(false);
        });
    };

    loadEventProjects();
  }, [page, filter, user, initialEventDate, endEventDate, lastRefresh]);

  function handleDeleteButton(
    eventProjectReceived: IEventProject,
  ): React.ReactNode | null {
    if (
      eventProjectReceived.project_procedure.sequence === 2 &&
      eventProjectReceived.status === 'Pendente' &&
      (user.user_type.type === 'REP' || user.user_type.type === 'ADM')
    ) {
      return (
        <DeleteButton
          onClick={() => {
            setProjectIdDelete(eventProjectReceived.id);
            setIsOpenDelete(true);
            setTitleDelete(
              `Deseja realmente excluir a solicitação ${eventProjectReceived.code} ` +
                `do cliente ${eventProjectReceived.local_client.company_name}, ` +
                `com data de evento em ${new Date(
                  eventProjectReceived.event_date,
                ).toLocaleDateString()}?`,
            );
            setSubTitleDelete('Esta operação não poderá ser desfeita!');
          }}
        />
      );
    }

    return null;
  }

  const onDelete = useCallback(
    async (id: string) => {
      try {
        await api.delete(`/projects/${id}`).then(() => {
          setPage(0);
          setLastRefresh(new Date());

          addToast({
            type: 'success',
            title: 'Solicitação excluída!',
            description: 'Solicitação excluída com sucesso.',
          });
        });
      } catch (error: any) {
        addToast({
          type: 'error',
          title: 'Ocorreu um erro!',
          description: error.response.data.message,
        });
      }
      setProjectIdDelete('');
      setTitleDelete('');
      setSubTitleDelete('');
    },
    [addToast],
  );

  function handleNewRequest(): React.ReactNode | null {
    if (getPermByName('proj_event_proc_01', user)) {
      if (isNewReqEnabled) {
        return (
          <Link
            to={
              user.user_type.type === 'REP'
                ? '/projects/events/procedure1/client'
                : '/projects/events/procedure1/representative'
            }
          >
            <NewButton />
          </Link>
        );
      }

      return (
        <DefaultTooltip title="Função indisponível">
          <NewButton disabled />
        </DefaultTooltip>
      );
    }

    return null;
  }

  return (
    <Container style={{ minHeight: 600 }}>
      <BreadCrumb
        main="Solicitações"
        path="/projects/main"
        firstChild="Eventos"
        mainIcon={FaProjectDiagram}
        firstChildIcon={FaCalendarAlt}
        mainColored
      />

      <FilterContent>
        <DefaultInput
          name="search"
          labelText="Pesquisar"
          type="text"
          onChange={event => {
            setFilter(event.target.value);
            setPage(0);
          }}
        />
      </FilterContent>

      <ContentSplit>
        <FilterDatesContent>
          <FilterDateWrapper>
            <DateInput
              name="initial_event_date"
              labelFor="initial_event_date"
              labelText="Data de Evento Inicial"
              type="date"
              min="2021-01-01"
              onSet={e => {
                setInitialEventDate(e);
                setPage(0);
              }}
            />
          </FilterDateWrapper>

          <FilterDateWrapper style={{ marginLeft: '1rem' }}>
            <DateInput
              name="end_event_date"
              labelFor="end_event_date"
              labelText="Data de Evento Final"
              type="date"
              min="2021-01-01"
              onSet={e => {
                setEndEventDate(e);
                setPage(0);
              }}
            />
          </FilterDateWrapper>
        </FilterDatesContent>

        <ReportContent>
          <ReportButton onClick={() => setIsOpenReport(true)} />
        </ReportContent>
      </ContentSplit>

      <Table>
        <thead>
          <tr>
            <th style={{ width: '10%', textAlign: 'left' }}>Data do Evento:</th>
            <th style={{ width: '10%', textAlign: 'left' }}>Código</th>
            <th style={{ width: '20%', textAlign: 'left' }}>Cliente</th>
            <th style={{ width: '20%', textAlign: 'left' }}>Representante</th>
            <th style={{ width: '10%', textAlign: 'left' }}>Tipo de Evento</th>
            <th style={{ width: '10%', textAlign: 'left' }}>Situação</th>
            <th style={{ width: '10%', textAlign: 'left' }}>Status Entrega</th>
            <th style={{ width: '10%', textAlign: 'center' }} />
          </tr>
        </thead>

        <tbody>
          {loading ? (
            <tr>
              <Td colSpan={9} style={{ padding: '3rem', textAlign: 'center' }}>
                <PropagateLoader color="#276c8d" />
              </Td>
            </tr>
          ) : eventProjects.length > 0 ? (
            eventProjects.map(eventProject => (
              <tr key={eventProject.id}>
                <Td
                  style={{ width: '10%', textAlign: 'left' }}
                  heading="DATA DO EVENTO"
                >
                  {new Date(eventProject.event_date).toLocaleDateString()}
                </Td>
                <Td
                  style={{ width: '10%', textAlign: 'left' }}
                  heading="CÓDIGO"
                >
                  {eventProject.code}
                </Td>
                <Td
                  style={{ width: '20%', textAlign: 'left' }}
                  heading="CLIENTE"
                >
                  {`${eventProject.local_client.company_name}` +
                    ` (${getFormattedCnpj(
                      eventProject.local_client.document,
                    )})` +
                    ` - (${eventProject.local_client.city} - ${eventProject.local_client.state})`}
                </Td>
                <Td
                  style={{ width: '20%', textAlign: 'left' }}
                  heading="REPRESENTANTE"
                >
                  {eventProject.local_representative.name}
                </Td>
                <Td
                  style={{ width: '10%', textAlign: 'left' }}
                  heading="TIPO DE EVENTO"
                >
                  {eventProject.event.name}
                </Td>
                <Td
                  style={{ width: '10%', textAlign: 'left' }}
                  heading="SITUAÇÃO"
                >
                  {eventProject.status}
                </Td>
                <Td
                  style={{ width: '10%', textAlign: 'left' }}
                  heading="STATUS ENTREGA"
                >
                  {eventProject.material_delivery_status}
                </Td>
                <Td
                  style={{
                    width: '10%',
                    display: 'flex',
                    textAlign: 'center',
                  }}
                  heading={' '}
                >
                  <RowButtonsContent>
                    <div style={{ width: 35 }}>
                      {getEditProjPerm(
                        eventProject.status,
                        eventProject.project_procedure.id,
                        user,
                      ) && (
                        <Link
                          to={{
                            pathname:
                              `/projects/events/procedure` +
                              `${eventProject.project_procedure.sequence}`,
                            state: eventProject,
                          }}
                        >
                          <EditButton />
                        </Link>
                      )}
                    </div>

                    <div style={{ width: 35 }}>
                      <Link
                        to={{
                          pathname: `/projects/events/view`,
                          state: eventProject,
                        }}
                      >
                        <ViewButton />
                      </Link>
                    </div>

                    <div style={{ width: 35 }}>
                      {eventProject.status !== 'Finalizado' &&
                        eventProject.status !== 'Cancelado' &&
                        getPermByName('mgmt_proj_editing', user) && (
                          <Link
                            to={{
                              pathname: `/projects/events/editing/chooseprocess`,
                              state: eventProject,
                            }}
                          >
                            <FixButton />
                          </Link>
                        )}
                    </div>

                    <div style={{ width: 35 }}>
                      {handleDeleteButton(eventProject)}
                    </div>
                  </RowButtonsContent>
                </Td>
              </tr>
            ))
          ) : (
            <tr>
              <Td colSpan={9} style={{ textAlign: 'center' }}>
                Nenhuma informação disponível
              </Td>
            </tr>
          )}
        </tbody>
      </Table>

      <ButtonsContent>
        <Pagination
          page={page}
          count={count}
          amountPerPage={AMOUNT_PER_PAGE}
          setPage={setPage}
        />

        {handleNewRequest()}
      </ButtonsContent>

      <ReportDialog
        isOpen={isOpenReport}
        setIsOpen={setIsOpenReport}
        projectType="EVENTO"
        ids={eventProjectsIds}
      />

      <ConfirmDeleteDialog
        isOpen={isOpenDelete}
        title={titleDelete}
        subTitle={subTitleDelete}
        setIsOpen={setIsOpenDelete}
        onClose={() => {
          onDelete(projectIdDelete);
        }}
      />
    </Container>
  );
};

export default List;
