interface IUser {
  permissions: {
    min_price?: number;
    max_price?: number;
    feature: {
      price_range?: boolean;
      project_procedure_id: string;
    };
  }[];
}

export default function getEditProjPerm(
  status: string,
  ft_proj_proc_id: string,
  user: IUser,
  price?: number,
): boolean {
  if (status !== 'Cancelado' && status !== 'Finalizado') {
    const perm = user.permissions.find(
      p => p.feature.project_procedure_id === ft_proj_proc_id,
    );

    if (perm) {
      if (perm.feature.price_range) {
        if (
          price &&
          (!perm.min_price || perm.min_price <= price) &&
          (!perm.max_price || perm.max_price >= price)
        ) {
          return true;
        }
      } else {
        return true;
      }
    }
  }

  return false;
}
