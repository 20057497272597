interface IUser {
  permissions: {
    feature: {
      name: string;
    };
  }[];
}

export default function getPermByName(ft_name: string, user: IUser): boolean {
  return user.permissions.some(p => p.feature.name === ft_name);
}
