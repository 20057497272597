import React, { useState, useEffect } from 'react';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';

import { FaAngleDown } from 'react-icons/fa';

import api from '~/services/api';

import InfoInput from '~/components/Inputs/Info';
import TextArea from '~/components/TextArea';

import { MainContainer, Label, Container, Content, Wrapper } from './styles';

interface ISpecific01Props {
  project_id: string;
  type: string;
  code: string;
  erp_client_code: string;
  item: string;
  gross_discount_price?: number;
  net_discount_price?: number;
  note: string;
  default_expanded?: boolean;
}

interface IClient {
  company_name: string;
  city: string;
  state: string;
}

interface IStoreRelationship {
  company_name: string;
  city: string;
  state: string;
}

const Specific01: React.FC<ISpecific01Props> = ({
  project_id,
  type,
  code,
  erp_client_code,
  item,
  gross_discount_price,
  net_discount_price,
  note,
  default_expanded,
}) => {
  const [client, setClient] = useState<IClient>();
  const [storeRelationship, setStoreRelationship] =
    useState<IStoreRelationship>();
  const [mktApprover, setMktApprover] = useState<string | undefined>('');
  const [mktManagementApprover, setMktManagementApprover] = useState<
    string | undefined
  >('');

  useEffect(() => {
    if (erp_client_code !== '') {
      api.get(`/clients/code?code=${erp_client_code}`).then(response => {
        setClient(response.data);
      });

      api
        .get(`/storerelationships/client?erp_client_code=${erp_client_code}`)
        .then(response => {
          setStoreRelationship(response.data);
        });
    }
  }, [erp_client_code]);

  useEffect(() => {
    api
      .get(
        `/projectrecords/statusprojectprocedure?` +
          `status=Aprovado&type=${type}&sequence=2&project_id=${project_id}`,
      )
      .then(response => {
        setMktApprover(response.data.user?.name);
      });

    api
      .get(
        `/projectrecords/statusprojectprocedure?` +
          `status=Aprovado&type=${type}&sequence=3&project_id=${project_id}`,
      )
      .then(response => {
        setMktManagementApprover(response.data.user?.name);
      });
  }, [project_id, type]);

  return (
    <MainContainer>
      <Accordion defaultExpanded={default_expanded}>
        <AccordionSummary expandIcon={<FaAngleDown />}>
          <Label>Informações da Solicitação</Label>
        </AccordionSummary>

        <AccordionDetails>
          <Container>
            <Content>
              <Wrapper style={{ width: '30%', marginRight: '1rem' }}>
                <InfoInput
                  name="code"
                  labelFor="code"
                  labelText="Código Solicitação"
                  type="text"
                  defaultValue={code}
                  disabled
                />
              </Wrapper>

              <Wrapper style={{ width: '70%' }}>
                <InfoInput
                  name="client_company_name"
                  labelFor="client_company_name"
                  labelText="Cliente"
                  type="text"
                  defaultValue={
                    storeRelationship?.company_name &&
                    storeRelationship?.company_name !== ''
                      ? storeRelationship.company_name
                      : client?.company_name
                  }
                  disabled
                />
              </Wrapper>
            </Content>

            <Content>
              <Wrapper style={{ width: '50%', marginRight: '1rem' }}>
                <InfoInput
                  name="city"
                  labelFor="city"
                  labelText="Cidade"
                  type="text"
                  defaultValue={
                    storeRelationship?.city && storeRelationship?.city !== ''
                      ? storeRelationship.city
                      : client?.city
                  }
                  disabled
                />
              </Wrapper>

              <Wrapper style={{ width: '50%' }}>
                <InfoInput
                  name="state"
                  labelFor="state"
                  labelText="Estado"
                  type="text"
                  defaultValue={
                    storeRelationship?.state && storeRelationship?.state !== ''
                      ? storeRelationship.state
                      : client?.state
                  }
                  disabled
                />
              </Wrapper>
            </Content>

            <Content>
              <Wrapper style={{ width: '100%' }}>
                <InfoInput
                  name="item"
                  labelFor="item"
                  labelText="Item"
                  type="text"
                  defaultValue={item}
                  disabled
                />
              </Wrapper>
            </Content>

            <Content>
              <Wrapper style={{ width: '50%', marginRight: '1rem' }}>
                <InfoInput
                  name="gross_discount_price"
                  labelFor="gross_discount_price"
                  labelText="Valor Desconto Bruto"
                  type="text"
                  defaultValue={
                    gross_discount_price
                      ? new Intl.NumberFormat('pt-BR', {
                          style: 'currency',
                          currency: 'BRL',
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        }).format(gross_discount_price)
                      : new Intl.NumberFormat('pt-BR', {
                          style: 'currency',
                          currency: 'BRL',
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        }).format(0)
                  }
                  disabled
                />
              </Wrapper>

              <Wrapper style={{ width: '50%' }}>
                <InfoInput
                  name="net_discount_price"
                  labelFor="net_discount_price"
                  labelText="Valor Desconto Líquido"
                  type="text"
                  defaultValue={
                    net_discount_price
                      ? new Intl.NumberFormat('pt-BR', {
                          style: 'currency',
                          currency: 'BRL',
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        }).format(net_discount_price)
                      : new Intl.NumberFormat('pt-BR', {
                          style: 'currency',
                          currency: 'BRL',
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        }).format(0)
                  }
                  disabled
                />
              </Wrapper>
            </Content>

            <Content>
              <Wrapper style={{ width: '100%' }}>
                <TextArea
                  name="note"
                  labelFor="note"
                  labelText="Observação Geral"
                  defaultValue={note}
                  maxLength={1000}
                  disabled
                />
              </Wrapper>
            </Content>

            <Content>
              <Wrapper style={{ width: '50%', marginRight: '1rem' }}>
                <InfoInput
                  name="mkt_approver"
                  labelFor="mkt_approver"
                  labelText="Aprovador Marketing"
                  type="text"
                  defaultValue={mktApprover}
                  disabled
                />
              </Wrapper>

              <Wrapper style={{ width: '50%' }}>
                <InfoInput
                  name="mkt_management_approver"
                  labelFor="mkt_management_approver"
                  labelText="Aprovador Gestão Marketing"
                  type="text"
                  defaultValue={mktManagementApprover}
                  disabled
                />
              </Wrapper>
            </Content>
          </Container>
        </AccordionDetails>
      </Accordion>
    </MainContainer>
  );
};

export default Specific01;
