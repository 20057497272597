import React, { useCallback, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useForm, Controller } from 'react-hook-form';
import { FaProjectDiagram, FaBox } from 'react-icons/fa';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { v4 as uuidv4 } from 'uuid';

import BreadCrumb from '~/components/BreadCrumb';
import NewProjectInfo from '~/components/ProjectInfo/New';
import Select from '~/components/Select';
import WholeNumberInput from '~/components/Inputs/WholeNumber';
import DefaultInput from '~/components/Inputs/Default';
import PhoneInput from '~/components/Inputs/Phone';
import TextArea from '~/components/TextArea';
import UploadRequestFiles from '~/components/UploadRequestFiles';
import UploadFiles from '~/components/UploadFiles';
import SaveButton from '~/components/Buttons/Save';
import ExitButton from '~/components/Buttons/Exit';
import ConfirmProcedureDialog from '~/components/Dialogs/ConfirmProcedure';
import ConfirmExitDialog from '~/components/Dialogs/ConfirmExit';

import { useAuth } from '~/hooks/auth';
import { useToast } from '~/hooks/toast';

import api from '~/services/api';

import {
  Container,
  Content,
  ContentSplit,
  Wrapper,
  DownloadButtonWrapper,
  DownloadButton,
  DownloadLink,
  Label,
  BorderContent,
  FlexWrapper,
  ButtonContainer,
} from './styles';

interface IFormProcedure1 {
  type: string;
  item_amount: number;
  rep_as_client: boolean;
  erp_client_code: string;
  erp_rep_code: string;
  note: string;
  client_contact_name: string;
  client_contact_phone: string;
  client_contact_email: string;
  display: {
    value: string;
  };
  record_description: string;
  record_note: string;
  project_temp_id: string;
  project_request_temp_id: string;
}

interface ILocation {
  erp_client_code?: string;
  erp_client_name?: string;
  erp_client_company_name?: string;
  erp_client_document?: string;
  erp_client_address?: string;
  erp_client_city?: string;
  erp_client_state?: string;
  erp_rep_code: string;
  rep_as_client: boolean;
}

const schema = Yup.object().shape({
  display: Yup.object().shape({
    value: Yup.string().required('Campo obrigatório'),
  }),
  item_amount: Yup.string().required('Campo obrigatório'),
  client_contact_name: Yup.string().required('Campo obrigatório'),
  client_contact_phone: Yup.string()
    .required('Campo obrigatório')
    .min(14, 'Telefone/celular inválido')
    .max(15, 'Telefone/celular inválido'),
  client_contact_email: Yup.string()
    .required('Campo obrigatório')
    .email('E-mail inválido'),
});

const Procedure1: React.FC = () => {
  const history = useHistory();
  const { user } = useAuth();
  const { addToast } = useToast();
  const { state } = useLocation<ILocation>();
  const [displays, setDisplays] = useState<
    {
      id: string;
      name: string;
    }[]
  >();
  const [loading, setLoading] = useState<boolean>(true);
  const [projectTempFilled, setProjectTempFilled] = useState(false);
  const [projectTemp, setProjectTemp] = useState('');
  const [projectRequestTempFilled, setProjectRequestTempFilled] =
    useState(false);
  const [projectRequestTemp, setProjectRequestTemp] = useState('');
  const [isOpenConfirmProcedure, setIsOpenConfirmProcedure] = useState(false);
  const [isOpenExit, setIsOpenExit] = useState(false);
  const [isSaving, setIsSaving] = useState(false);

  const { handleSubmit, register, getValues, setValue, control, errors } =
    useForm<IFormProcedure1>({ resolver: yupResolver(schema) });

  useEffect(() => {
    const checkEnabledFunctionality = async () => {
      await api.get('/generalsettings').then(response => {
        if (response.data && !response.data.new_display_req_enabled) {
          history.push('/');
        }
      });
    };

    checkEnabledFunctionality();
  }, [history]);

  useEffect(() => {
    if (state === undefined) {
      addToast({
        type: 'error',
        title: 'Operação inválida!',
        description: 'Cliente não selecionado',
      });

      history.push('/projects/displays');
    } else {
      register('project_temp_id');
      register('project_request_temp_id');
      register('type');
      register('rep_as_client');
      register('erp_client_code');
      register('erp_rep_code');
      register('record_description');
      register('record_note');

      setValue('type', 'DISPLAY');
      setValue('rep_as_client', state.rep_as_client);
      setValue('erp_client_code', state.erp_client_code);
      setValue('erp_rep_code', state.erp_rep_code);
      setValue('record_description', 'Abertura de projeto');

      api.get('/items/displays/valid').then(response => {
        setDisplays(response.data);
        setLoading(false);
      });
      if (!projectTempFilled) {
        setProjectTempFilled(true);
        setProjectTemp(uuidv4());
      }

      if (!projectRequestTempFilled) {
        setProjectRequestTempFilled(true);
        setProjectRequestTemp(uuidv4());
      }
    }
  }, [
    addToast,
    history,
    state,
    user,
    projectTempFilled,
    projectRequestTempFilled,
    register,
    setValue,
  ]);

  const options = displays?.map(display => ({
    value: display.id,
    label: display.name,
  }));

  useEffect(() => {
    setValue('project_temp_id', projectTemp);
  }, [projectTemp, setValue]);

  useEffect(() => {
    setValue('project_request_temp_id', projectRequestTemp);
  }, [projectRequestTemp, setValue]);

  const submitForm = useCallback(
    async (data: IFormProcedure1) => {
      try {
        setIsSaving(true);

        await api.post('/projects', data);

        addToast({
          type: 'success',
          title: 'Projeto iniciado!',
          description: 'Abertura de projeto realizado com sucesso.',
        });

        history.push('/projects/displays');
      } catch (error: any) {
        setIsSaving(false);

        addToast({
          type: 'error',
          title: 'Ocorreu um erro!',
          description: error.response.data.message,
        });
      }
    },
    [addToast, history],
  );

  function onConfirmProcedure(): void {
    const noteValue = getValues('note');
    setValue('record_note', noteValue);
    handleSubmit(submitForm)();
  }

  const onExit = useCallback(() => {
    api.delete(`projectfilesreceived/projecttempid/${projectTemp}`);
    api.delete(
      `projectrequestfilesreceived/projectrequesttempid/${projectRequestTemp}`,
    );

    history.push('/projects/displays');
  }, [history, projectTemp, projectRequestTemp]);

  function handleFiles(): React.ReactNode {
    if (user.user_type.type !== 'REP') {
      return (
        <Content>
          <UploadFiles
            type="anexo"
            project_temp_id={projectTemp}
            project_type="DISPLAY"
            sequence={1}
            user_id={user.id}
          />
        </Content>
      );
    }

    return <></>;
  }

  function getDownloadLink(): string {
    return (
      `${process.env.REACT_APP_API_URL}/downloads/` +
      `projects/displays/Procedimento_PDV_Atualizado.pdf`
    );
  }

  return (
    <Container>
      <BreadCrumb
        main="Solicitações"
        path="/projects/main"
        firstChildPath="/projects/displays"
        firstChild="Displays"
        secondChild="Nova Solicitação"
        mainIcon={FaProjectDiagram}
        firstChildIcon={FaBox}
        secondChildIcon={FaBox}
        mainColored
        firstChildColored
      />

      <NewProjectInfo
        rep_as_client={state?.rep_as_client}
        erp_rep_code={state?.erp_rep_code}
        erp_client_code={state?.erp_client_code}
      />

      <form onSubmit={handleSubmit(submitForm)}>
        <ContentSplit>
          <Wrapper style={{ width: '50%', marginRight: '1rem' }}>
            <Controller
              name="display"
              control={control}
              options={options}
              label="Display"
              error={errors?.display?.value?.message}
              as={Select}
              loading={loading}
            />
          </Wrapper>

          <Wrapper style={{ width: '50%' }}>
            <WholeNumberInput
              name="item_amount"
              labelFor="item_amount"
              labelText="Quantidade"
              defaultValue={1}
              error={errors?.item_amount?.message}
              ref={register}
            />
          </Wrapper>
        </ContentSplit>

        <Content>
          <DownloadButtonWrapper>
            <DownloadButton type="button">
              <DownloadLink href={getDownloadLink()} target="_blank" download>
                BAIXE O ARQUIVO COM AS INFORMAÇÕES PARA SOLICITAÇÃO DE
                MOSTRUÁRIOS
              </DownloadLink>
            </DownloadButton>
          </DownloadButtonWrapper>
        </Content>

        <Label>Contato da Loja</Label>

        <BorderContent>
          <FlexWrapper>
            <Wrapper style={{ width: '60%', marginRight: '1rem' }}>
              <DefaultInput
                name="client_contact_name"
                labelFor="client_contact_name"
                labelText="Nome"
                type="text"
                error={errors?.client_contact_name?.message}
                ref={register}
                maxLength={60}
              />
            </Wrapper>

            <Wrapper style={{ width: '40%' }}>
              <PhoneInput
                name="client_contact_phone"
                labelFor="client_contact_phone"
                labelText="Telefone"
                type="text"
                error={errors?.client_contact_phone?.message}
                ref={register}
              />
            </Wrapper>
          </FlexWrapper>

          <FlexWrapper>
            <Wrapper style={{ width: '100%' }}>
              <DefaultInput
                name="client_contact_email"
                labelFor="client_contact_email"
                labelText="E-mail"
                type="text"
                error={errors?.client_contact_email?.message}
                ref={register}
                maxLength={60}
              />
            </Wrapper>
          </FlexWrapper>
        </BorderContent>

        <Content>
          <Wrapper style={{ width: '100%' }}>
            <TextArea
              name="note"
              labelFor="note"
              labelText="Observações"
              compLabelText="Para solicitações de Displays de Parede e Projetos Especiais, informar medidas exatas"
              error={errors?.note?.message}
              ref={register}
              maxLength={1000}
            />
          </Wrapper>
        </Content>

        <Content>
          <UploadRequestFiles
            type="requisicao"
            project_request_temp_id={projectRequestTemp}
            project_type="DISPLAY"
            sequence={1}
            user_id={user.id}
          />
        </Content>

        {handleFiles()}

        <ButtonContainer>
          <SaveButton
            type="button"
            processing={isSaving}
            disabled={isSaving}
            onClick={() => {
              setIsOpenConfirmProcedure(true);
            }}
          />

          <ExitButton
            style={{ marginLeft: 10 }}
            type="button"
            disabled={isSaving}
            onClick={() => setIsOpenExit(true)}
          />
        </ButtonContainer>
      </form>

      <ConfirmProcedureDialog
        isOpen={isOpenConfirmProcedure}
        setIsOpen={setIsOpenConfirmProcedure}
        onConfirm={onConfirmProcedure}
        title="Confirma abertura do projeto?"
        subTitle="O projeto será enviado para o próximo procedimento"
        opConfirm="Sim"
      />

      <ConfirmExitDialog
        isOpen={isOpenExit}
        setIsOpen={setIsOpenExit}
        onExit={onExit}
      />
    </Container>
  );
};

export default Procedure1;
