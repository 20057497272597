import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useForm, Controller } from 'react-hook-form';
import { FaProjectDiagram, FaCalendarAlt } from 'react-icons/fa';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { string, number } from 'yup';
import { v4 as uuidv4 } from 'uuid';

import BreadCrumb from '~/components/BreadCrumb';
import NewProjectInfo from '~/components/ProjectInfo/New';
import Select from '~/components/Select';
import DefaultInput from '~/components/Inputs/Default';
import CurrencyInput from '~/components/Inputs/Currency';
import PhoneInput from '~/components/Inputs/Phone';
import DateInput from '~/components/Inputs/Date';
import WholeNumberInput from '~/components/Inputs/WholeNumber';
import TableSelect from '~/components/TableSelect';
import TableDefaultInput from '~/components/TableInputs/Default';
import AddRowButton from '~/components/Buttons/AddRow';
import DeleteRowButton from '~/components/Buttons/DeleteRow';
import Toggle from '~/components/Toggle';
import TextArea from '~/components/TextArea';
import UploadRequestFiles from '~/components/UploadRequestFiles';
import UploadFiles from '~/components/UploadFiles';
import SaveButton from '~/components/Buttons/Save';
import ExitButton from '~/components/Buttons/Exit';
import ConfirmProcedureDialog from '~/components/Dialogs/ConfirmProcedure';
import ConfirmExitDialog from '~/components/Dialogs/ConfirmExit';
import NoticeDialog from '~/components/Dialogs/Notice';

import {
  getCurrencyFormatValue,
  getFormattedCurrency,
  getFormattedNumber,
  getNumberFormatValue,
} from '~/utils';

import { useAuth } from '~/hooks/auth';
import { useToast } from '~/hooks/toast';

import api from '~/services/api';

import {
  Container,
  Content,
  Wrapper,
  LabelContainer,
  Label,
  BorderContent,
  FlexWrapper,
  ButtonContainer,
} from './styles';

interface IClient {
  code: string;
  name: string;
  company_name: string;
  document: string;
  address: string;
  city: string;
  state: string;
}

interface IFormProcedure1 {
  note: string;
  client_contact_name: string;
  client_contact_phone: string;
  client_contact_email: string;
  estimated_event_participants_number: number;
  event_date: Date;
  price_req_by_client: number;
  generates_sponsorship: boolean;
  event: {
    value: string;
  };
  erp_invoice_client_code: {
    value: string;
  };
  record_note: string;
}

interface ILocation {
  erp_client_code?: string;
  erp_client_name?: string;
  erp_client_company_name?: string;
  erp_client_document?: string;
  erp_client_address?: string;
  erp_client_city?: string;
  erp_client_state?: string;
  erp_rep_code: string;
}

const schema = Yup.object().shape({
  event: Yup.object().shape({
    value: Yup.string().required('Campo obrigatório'),
  }),
  erp_invoice_client_code: Yup.object().shape({
    value: Yup.string().required('Campo obrigatório'),
  }),
  client_contact_name: Yup.string().required('Campo obrigatório'),
  client_contact_phone: Yup.string()
    .required('Campo obrigatório')
    .min(14, 'Telefone/celular inválido')
    .max(15, 'Telefone/celular inválido'),
  client_contact_email: Yup.string()
    .required('Campo obrigatório')
    .email('E-mail inválido'),
  event_date: Yup.date()
    .min(
      new Date(new Date().getTime() - 1000 * 60 * 60 * 24 * 1),
      'Data inválida',
    )
    .max(
      new Date(new Date().getTime() + 1000 * 60 * 60 * 24 * 1825),
      'Data inválida',
    )
    .typeError('Campo obrigatório'),
  estimated_event_participants_number: Yup.string().when((value: string) => {
    if (Number.isNaN(Number(parseFloat(value)))) {
      return string().required('Quantidade inválida');
    }
    if (parseFloat(value) <= 0) {
      return number()
        .min(1, 'Quantidade inválida')
        .required('Quantidade inválida')
        .typeError('Quantidade inválida');
    }
    return string().notRequired();
  }),
});

const Procedure1: React.FC = () => {
  const history = useHistory();
  const { user } = useAuth();
  const { addToast } = useToast();
  const { state } = useLocation<ILocation>();
  const [events, setEvents] = useState<
    {
      id: string;
      name: string;
    }[]
  >();
  const [clients, setClients] = useState<IClient[]>();
  const [selectedClient, setSelectedClient] = useState<IClient>();
  const [giftTypeOptions, setGiftTypeOptions] = useState<
    {
      value: string;
      label: string;
      price: number;
      enabled: boolean;
    }[]
  >([]);
  const [projectGifts, setProjectGifts] = useState<
    {
      temp_id: string;
      amount: number;
      unit_price: number;
      total_price: number;
      gift_type: {
        value: string;
      };
    }[]
  >([
    {
      temp_id: uuidv4(),
      amount: 0,
      unit_price: 0,
      total_price: 0,
      gift_type: {
        value: '',
      },
    },
  ]);
  const projectTemp = useRef(uuidv4());
  const projectRequestTemp = useRef(uuidv4());
  const [isOpenConfirmProcedure, setIsOpenConfirmProcedure] = useState(false);
  const [isOpenExit, setIsOpenExit] = useState(false);
  const [notice, setNotice] = useState<{
    title: string;
    subtitle: string;
  }>({
    title: '',
    subtitle: '',
  });
  const [isOpenNotice, setIsOpenNotice] = useState(false);
  const [isSaving, setIsSaving] = useState(false);

  const { handleSubmit, register, getValues, setValue, control, errors } =
    useForm<IFormProcedure1>({
      resolver: yupResolver(schema),
      defaultValues: {
        generates_sponsorship: false,
      },
    });

  useEffect(() => {
    const checkEnabledFunctionality = async () => {
      await api.get('/generalsettings').then(response => {
        if (response.data && !response.data.new_event_req_enabled) {
          history.push('/');
        }
      });
    };

    checkEnabledFunctionality();
  }, [history]);

  useEffect(() => {
    if (clients) {
      if (clients.length === 0 && state) {
        if (state.erp_client_code !== undefined) {
          setValue('erp_invoice_client_code', {
            value: state.erp_client_code,
          });
        }

        setSelectedClient({
          code: state.erp_client_code || '',
          name: state.erp_client_name || '',
          company_name: state.erp_client_company_name || '',
          document: state.erp_client_document || '',
          address: state.erp_client_address || '',
          city: state.erp_client_city || '',
          state: state.erp_client_state || '',
        });
      }
    }
  }, [clients, state, setValue]);

  useEffect(() => {
    if (state === undefined) {
      addToast({
        type: 'error',
        title: 'Operação inválida!',
        description: 'Cliente não selecionado',
      });

      history.push('/projects/events');
    } else {
      register('erp_invoice_client_code');
      register('record_note');

      api.get('/items/events/valid').then(response => {
        setEvents(response.data);
      });

      api
        .get(`/clients/clientgroup?code=${state.erp_client_code}`)
        .then(response => {
          setClients(response.data);
        });

      api.get('/configurations/gifttypes/valid').then(response => {
        setGiftTypeOptions(
          response.data.map((g_t: any) => ({
            value: g_t.id,
            label: g_t.name,
            price: g_t.price || 0,
            enabled: true,
          })),
        );
      });
    }
  }, [addToast, history, register, state]);

  const eventOptions = events?.map(event => ({
    value: event.id,
    label: event.name,
  }));

  const clientOptions = clients?.map(client => ({
    value: client.code,
    label: `(${client.code} - ${client.document}) - ${client.company_name}`,
  }));

  const submitForm = useCallback(
    async (data: IFormProcedure1) => {
      try {
        setIsSaving(true);

        await api.post('/projects', {
          ...data,
          erp_client_code: state.erp_client_code,
          erp_rep_code: state.erp_rep_code,
          project_gifts: projectGifts,
          project_request_temp_id: projectRequestTemp.current,
          project_temp_id: projectTemp.current,
          record_description: 'Abertura de solicitação',
          rep_as_client: false,
          type: 'EVENTO',
        });

        addToast({
          type: 'success',
          title: 'Solicitação iniciada!',
          description: 'Abertura de solicitação realizada com sucesso.',
        });

        history.push('/projects/events');
      } catch (error: any) {
        setIsSaving(false);

        addToast({
          type: 'error',
          title: 'Ocorreu um erro!',
          description: error.response.data.message,
        });
      }
    },
    [addToast, history, projectGifts, projectRequestTemp, projectTemp, state],
  );

  function onConfirmProcedure(): void {
    setValue(
      'estimated_event_participants_number',
      getNumberFormatValue(getValues('estimated_event_participants_number')),
    );

    setValue(
      'price_req_by_client',
      getCurrencyFormatValue(getValues('price_req_by_client')),
    );

    setValue('record_note', getValues('note'));

    handleSubmit(submitForm)();
  }

  const onExit = () => {
    api.delete(`projectfilesreceived/projecttempid/${projectTemp.current}`);
    api.delete(
      `projectrequestfilesreceived/` +
        `projectrequesttempid/${projectRequestTemp.current}`,
    );

    history.push('/projects/events');
  };

  function handleInvoiceClient(): React.ReactNode | null {
    if (clients) {
      if (clients.length > 0) {
        return (
          <Content>
            <Wrapper style={{ width: '50%' }}>
              <Select
                label="Cliente Faturamento"
                options={clientOptions}
                loading={!clients}
                error={errors?.erp_invoice_client_code?.value?.message}
                onSet={(e: any) => {
                  setValue(
                    'erp_invoice_client_code',
                    {
                      value: e.value,
                    },
                    {
                      shouldValidate: true,
                    },
                  );

                  const selClient = clients.find(cli => cli.code === e.value);

                  if (selClient) {
                    setSelectedClient({
                      code: selClient.code,
                      name: selClient.name,
                      company_name: selClient.company_name,
                      document: selClient.document,
                      address: selClient.address,
                      city: selClient.city,
                      state: selClient.state,
                    });
                  }
                }}
              />
            </Wrapper>
          </Content>
        );
      }

      return (
        <Content>
          <Wrapper style={{ width: '70%' }}>
            <DefaultInput
              name="erp_invoice_client_code_def"
              labelText="Cliente Faturamento"
              type="text"
              value={`(${selectedClient?.code}) - ${selectedClient?.company_name}`}
              disabled
            />
          </Wrapper>
        </Content>
      );
    }

    return null;
  }

  function handleFiles(): React.ReactNode {
    if (user.user_type.type !== 'REP') {
      return (
        <Content>
          <UploadFiles
            type="anexo"
            project_temp_id={projectTemp.current}
            project_type="EVENTO"
            sequence={1}
            user_id={user.id}
          />
        </Content>
      );
    }

    return <></>;
  }

  return (
    <Container>
      <BreadCrumb
        main="Solicitações"
        path="/projects/main"
        firstChildPath="/projects/events"
        firstChild="Eventos"
        secondChild="Nova Solicitação"
        mainIcon={FaProjectDiagram}
        firstChildIcon={FaCalendarAlt}
        secondChildIcon={FaCalendarAlt}
        mainColored
        firstChildColored
      />

      <NewProjectInfo
        rep_as_client={false}
        erp_rep_code={state?.erp_rep_code}
        erp_client_code={state?.erp_client_code}
      />

      <form onSubmit={handleSubmit(submitForm)}>
        <Content>
          <Wrapper style={{ width: 300 }}>
            <Controller
              name="event"
              control={control}
              options={eventOptions}
              label="Tipo de Evento"
              error={errors?.event?.value?.message}
              as={Select}
              loading={!events}
            />
          </Wrapper>
        </Content>

        {handleInvoiceClient()}

        <LabelContainer>
          <Label>Informações Cliente Faturamento</Label>
        </LabelContainer>

        <BorderContent>
          <FlexWrapper>
            <Wrapper style={{ width: '60%', marginRight: '1rem' }}>
              <DefaultInput
                name="erp_invoice_client_name"
                labelFor="erp_invoice_client_name"
                labelText="Razão Social"
                type="text"
                value={selectedClient?.company_name}
                disabled
              />
            </Wrapper>

            <Wrapper style={{ width: '40%' }}>
              <DefaultInput
                name="erp_invoice_client_document"
                labelFor="erp_invoice_client_document"
                labelText="CNPJ"
                type="text"
                value={selectedClient?.document}
                disabled
              />
            </Wrapper>
          </FlexWrapper>

          <FlexWrapper>
            <Wrapper style={{ width: '50%', marginRight: '1rem' }}>
              <DefaultInput
                name="erp_invoice_client_address"
                labelFor="erp_invoice_client_address"
                labelText="Endereço"
                type="text"
                value={selectedClient?.address}
                disabled
              />
            </Wrapper>

            <Wrapper style={{ width: '35%', marginRight: '1rem' }}>
              <DefaultInput
                name="erp_invoice_client_city"
                labelFor="erp_invoice_client_city"
                labelText="Cidade"
                type="text"
                value={selectedClient?.city}
                disabled
              />
            </Wrapper>

            <Wrapper style={{ width: '15%' }}>
              <DefaultInput
                name="erp_invoice_client_state"
                labelFor="erp_invoice_client_state"
                labelText="Estado"
                type="text"
                value={selectedClient?.state}
                disabled
              />
            </Wrapper>
          </FlexWrapper>
        </BorderContent>

        <LabelContainer>
          <Label>Contato da Loja</Label>
        </LabelContainer>

        <BorderContent>
          <FlexWrapper>
            <Wrapper style={{ width: '60%', marginRight: '1rem' }}>
              <DefaultInput
                name="client_contact_name"
                labelFor="client_contact_name"
                labelText="Nome"
                type="text"
                error={errors?.client_contact_name?.message}
                ref={register}
                maxLength={60}
              />
            </Wrapper>

            <Wrapper style={{ width: '40%' }}>
              <PhoneInput
                name="client_contact_phone"
                labelFor="client_contact_phone"
                labelText="Telefone"
                type="text"
                error={errors?.client_contact_phone?.message}
                ref={register}
              />
            </Wrapper>
          </FlexWrapper>

          <FlexWrapper>
            <Wrapper style={{ width: '100%' }}>
              <DefaultInput
                name="client_contact_email"
                labelFor="client_contact_email"
                labelText="E-mail"
                type="text"
                error={errors?.client_contact_email?.message}
                ref={register}
                maxLength={60}
              />
            </Wrapper>
          </FlexWrapper>
        </BorderContent>

        <div
          style={{
            maxWidth: 680,
            display: 'flex',
            flexWrap: 'wrap',
            columnGap: 20,
            rowGap: 12,
          }}
        >
          <Wrapper style={{ width: 250, margin: 0 }}>
            <DateInput
              name="event_date"
              labelFor="event_date"
              labelText="Data Evento"
              type="date"
              error={errors?.event_date?.message}
              ref={register}
            />
          </Wrapper>

          <Wrapper style={{ width: 250, margin: 0 }}>
            <WholeNumberInput
              name="estimated_event_participants_number"
              labelFor="estimated_event_participants_number"
              labelText="Número Estimado de Participantes"
              defaultValue={0}
              error={errors?.estimated_event_participants_number?.message}
              ref={register}
              onSet={e => {
                setProjectGifts(prevProjGifts =>
                  prevProjGifts.map(prevProjGift => ({
                    ...prevProjGift,
                    amount: getNumberFormatValue(e.target.value),
                    total_price:
                      getNumberFormatValue(e.target.value) *
                      prevProjGift.unit_price,
                  })),
                );
              }}
            />
          </Wrapper>

          <Wrapper style={{ width: 250, margin: 0 }}>
            <CurrencyInput
              name="price_req_by_client"
              labelFor="price_req_by_client"
              labelText="Cota Solicitada Pelo Cliente"
              defaultValue={0}
              error={errors?.price_req_by_client?.message}
              ref={register}
            />
          </Wrapper>

          <Wrapper style={{ width: 250 }}>
            <Controller
              render={props => (
                <Toggle
                  onChange={e => props.onChange(e)}
                  checked={props.value}
                  label="Gera Solicitação de Pagamento?"
                />
              )}
              name="generates_sponsorship"
              defaultValue
              control={control}
            />
          </Wrapper>
        </div>

        <div style={{ margin: '1rem 0 0.5rem 0' }}>
          <Label>Brindes</Label>
        </div>

        <div>
          {giftTypeOptions.length > 0 && (
            <>
              {projectGifts.map(projectGift => (
                <BorderContent
                  key={projectGift.temp_id}
                  style={{ marginBottom: 15 }}
                >
                  <div
                    style={{
                      display: 'flex',
                      flexWrap: 'wrap',
                      columnGap: 10,
                      rowGap: 10,
                    }}
                  >
                    <Wrapper style={{ width: 250, margin: 0 }}>
                      <TableSelect
                        name={`gift_type_${projectGift.temp_id}`}
                        label="Tipo de Brinde"
                        options={giftTypeOptions.filter(op => op.enabled)}
                        onSet={(e: any) => {
                          const prevValue = projectGift.gift_type.value;

                          setProjectGifts(prevProjGifts =>
                            prevProjGifts.map(prevProjGift => {
                              if (
                                prevProjGift.temp_id === projectGift.temp_id
                              ) {
                                const findGiftType = giftTypeOptions.find(
                                  op => op.value === e.value,
                                );

                                return {
                                  ...prevProjGift,
                                  gift_type: {
                                    value: e.value,
                                  },
                                  unit_price: findGiftType?.price || 0,
                                  total_price:
                                    prevProjGift.amount *
                                    (findGiftType?.price || 0),
                                };
                              }

                              return { ...prevProjGift };
                            }),
                          );

                          setGiftTypeOptions(prevGiftTypeOptions =>
                            prevGiftTypeOptions.map(op => {
                              if (op.value === prevValue) {
                                return { ...op, enabled: true };
                              }

                              if (op.value === e.value) {
                                return { ...op, enabled: false };
                              }

                              return { ...op };
                            }),
                          );
                        }}
                      />
                    </Wrapper>

                    <Wrapper style={{ width: 150, margin: 0 }}>
                      <TableDefaultInput
                        name={`amount_${projectGift.temp_id}`}
                        labelText="Quantidade"
                        value={getFormattedNumber(projectGift.amount, 0)}
                        disabled
                      />
                    </Wrapper>

                    <Wrapper style={{ width: 200, margin: 0 }}>
                      <TableDefaultInput
                        name={`unit_price_${projectGift.temp_id}`}
                        labelText="Valor Unitário"
                        value={getFormattedCurrency(projectGift.unit_price)}
                        disabled
                      />
                    </Wrapper>

                    <Wrapper style={{ width: 200, margin: 0 }}>
                      <TableDefaultInput
                        name={`total_price_${projectGift.temp_id}`}
                        labelText="Valor Total"
                        value={getFormattedCurrency(projectGift.total_price)}
                        disabled
                      />
                    </Wrapper>
                  </div>

                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'flex-end',
                      marginTop: 10,
                    }}
                  >
                    <DeleteRowButton
                      type="button"
                      onClick={() => {
                        setGiftTypeOptions(prevGiftTypeOptions =>
                          prevGiftTypeOptions.map(op =>
                            op.value === projectGift.gift_type.value
                              ? { ...op, enabled: true }
                              : { ...op },
                          ),
                        );

                        setProjectGifts(prevProjGifts =>
                          prevProjGifts.filter(
                            prevProjGift =>
                              prevProjGift.temp_id !== projectGift.temp_id,
                          ),
                        );
                      }}
                    />
                  </div>
                </BorderContent>
              ))}
            </>
          )}
        </div>

        <div
          style={{
            maxWidth: 680,
            display: 'flex',
            justifyContent: 'flex-end',
            margin: '1rem 0 2rem 0',
          }}
        >
          <AddRowButton
            type="button"
            onClick={() => {
              setProjectGifts(prevProjectGifts => [
                ...prevProjectGifts,
                {
                  temp_id: uuidv4(),
                  amount: getNumberFormatValue(
                    getValues('estimated_event_participants_number'),
                  ),
                  unit_price: 0,
                  total_price: 0,
                  gift_type: {
                    value: '',
                  },
                },
              ]);
            }}
          />
        </div>

        <Content>
          <Wrapper style={{ width: '100%', marginTop: 20 }}>
            <TextArea
              name="note"
              labelFor="note"
              labelText="Observação"
              error={errors?.note?.message}
              ref={register}
              maxLength={1000}
            />
          </Wrapper>
        </Content>

        <Content>
          <UploadRequestFiles
            type="requisicao"
            project_request_temp_id={projectRequestTemp.current}
            project_type="EVENTO"
            sequence={1}
            user_id={user.id}
          />
        </Content>

        {handleFiles()}

        <ButtonContainer>
          <SaveButton
            type="button"
            processing={isSaving}
            disabled={isSaving}
            onClick={() => {
              if (
                projectGifts.filter(projGift => projGift.gift_type.value === '')
                  .length > 0
              ) {
                setNotice({
                  title: 'Brindes inválidos!',
                  subtitle: 'Necessário selecionar tipo de brinde!',
                });
                setIsOpenNotice(true);
              } else if (
                projectGifts.filter(projGift => projGift.amount <= 0).length > 0
              ) {
                setNotice({
                  title: 'Existem brindes sem quantidade informada!',
                  subtitle:
                    'É necessário informar quantidade em todos os brindes.',
                });

                setIsOpenNotice(true);
              } else if (
                projectGifts.filter(projGift => projGift.unit_price <= 0)
                  .length > 0
              ) {
                setNotice({
                  title: 'Existem brindes sem valor!',
                  subtitle:
                    'É necessário informar valor em cadastro de tipos de brindes.',
                });

                setIsOpenNotice(true);
              } else {
                setIsOpenConfirmProcedure(true);
              }
            }}
          />

          <ExitButton
            style={{ marginLeft: 10 }}
            type="button"
            disabled={isSaving}
            onClick={() => setIsOpenExit(true)}
          />
        </ButtonContainer>
      </form>

      <ConfirmProcedureDialog
        isOpen={isOpenConfirmProcedure}
        setIsOpen={setIsOpenConfirmProcedure}
        onConfirm={onConfirmProcedure}
        title="Confirma abertura da solicitação?"
        subTitle="A solicitação será enviada para o próximo procedimento"
        opConfirm="Sim"
      />

      <ConfirmExitDialog
        isOpen={isOpenExit}
        setIsOpen={setIsOpenExit}
        onExit={onExit}
      />

      <NoticeDialog
        isOpen={isOpenNotice}
        type="error"
        title={notice.title}
        subTitle={notice.subtitle}
        setIsOpen={setIsOpenNotice}
      />
    </Container>
  );
};

export default Procedure1;
