import React, { useState, useEffect } from 'react';
import { useHistory, useLocation, Link } from 'react-router-dom';
import { FaProjectDiagram, FaBox } from 'react-icons/fa';
import { PropagateLoader } from 'react-spinners';

import BreadCrumb from '~/components/BreadCrumb';
import DefaultInput from '~/components/Inputs/Default';
import SelectButton from '~/components/Buttons/Select';
import Paginator from '~/components/Paginator';
import PrevButton from '~/components/Paginator/PrevButton';
import NextButton from '~/components/Paginator/NextButton';
import CenterButton from '~/components/Paginator/CenterButton';
import NewButton from '~/components/Buttons/New';

import { useAuth } from '~/hooks/auth';

import api from '~/services/api';

import {
  Container,
  FilterContent,
  Table,
  Thead,
  Tbody,
  Th,
  TbodyTr,
  Td,
  ButtonsContent,
} from './styles';

interface IClient {
  code: string;
  name: string;
  company_name: string;
  document: string;
  address: string;
  city: string;
  state: string;
}

interface ILocation {
  code: string;
  name: string;
}

const ListClients: React.FC = () => {
  const history = useHistory();
  const { user } = useAuth();
  const { state } = useLocation<ILocation>();
  const [page, setPage] = useState(1);
  const [pageFilter, setPageFilter] = useState(1);
  const [filter, setFilter] = useState('');
  const [filterHelper, setFilterHelper] = useState('');
  const [refresh, setRefresh] = useState(true);
  const [clients, setClients] = useState<IClient[]>();
  const [loading, setLoading] = useState<boolean>(true);
  const [nextDisabled, setNextDisabled] = useState(true);

  useEffect(() => {
    const checkEnabledFunctionality = async () => {
      await api.get('/generalsettings').then(response => {
        if (response.data && !response.data.new_display_req_enabled) {
          history.push('/');
        }
      });
    };

    checkEnabledFunctionality();
  }, [history]);

  useEffect(() => {
    let erpRepCode = '';
    let userType = user.user_type.type;

    if (state !== undefined) {
      erpRepCode = state.code;
      userType = 'REP';
    } else if (user.user_type.type === 'REP') {
      erpRepCode = user.erp_rep_code;
    }

    if (refresh) {
      setRefresh(false);
      let pageSearch = 1;
      let foundData = false;

      if (filter !== filterHelper) {
        setPage(1);
        setPageFilter(1);
      }

      setFilterHelper(filter);

      if (filter !== '') {
        pageSearch = pageFilter;
      } else {
        pageSearch = page;
      }

      api
        .get(
          `/clients?page=${pageSearch}&filter=${filter}` +
            `&user_type=${userType}&erp_rep_code=${erpRepCode}`,
        )
        .then(firstResponse => {
          if (firstResponse.data.length > 0) {
            foundData = true;
            setClients(firstResponse.data);
            setLoading(false);
          }

          if (!foundData) {
            if (pageSearch - 1 >= 1) {
              pageSearch -= 1;
              if (filter !== '') {
                setPageFilter(pageFilter - 1);
              } else {
                setPage(page - 1);
              }
            }

            api
              .get(
                `/clients?page=${pageSearch}&filter=${filter}` +
                  `&user_type=${userType}&erp_rep_code=${erpRepCode}`,
              )
              .then(secondResponse => {
                setClients(secondResponse.data);
                setLoading(false);

                api
                  .get(
                    `/clients?page=${pageSearch + 1}&filter=${filter}` +
                      `&user_type=${userType}&erp_rep_code=${erpRepCode}`,
                  )
                  .then(thirdResponse => {
                    setNextDisabled(false);
                    if (thirdResponse.data.length === 0) {
                      setNextDisabled(true);
                    }
                  });
              });
          } else {
            api
              .get(
                `/clients?page=${pageSearch + 1}&filter=${filter}` +
                  `&user_type=${userType}&erp_rep_code=${erpRepCode}`,
              )
              .then(fourthResponse => {
                setNextDisabled(false);
                if (fourthResponse.data.length === 0) {
                  setNextDisabled(true);
                }
              });
          }
        });
    }
  }, [page, pageFilter, filter, filterHelper, refresh, user, state]);

  function handlePage(type: string): void {
    if (type === 'backward') {
      if (filter !== '') {
        setPageFilter(pageFilter - 1);
      } else {
        setPage(page - 1);
      }
    } else if (type === 'forward') {
      if (filter !== '') {
        setPageFilter(pageFilter + 1);
      } else {
        setPage(page + 1);
      }
    } else if (filter !== '') {
      setPageFilter(1);
    } else {
      setPage(1);
    }
    setRefresh(true);
  }

  function handlePrevButtonDisabled(): boolean {
    let validationPage = 1;

    if (filter !== '') {
      validationPage = pageFilter;
    } else {
      validationPage = page;
    }

    if (validationPage === 1) {
      return true;
    }

    return false;
  }

  function handleFilterChange(value: string): void {
    setFilter(value);
    setRefresh(true);
  }

  function handleTable(): React.ReactNode {
    if (clients) {
      if (clients.length > 0) {
        return (
          <Table>
            <Thead>
              <tr>
                <Th width="10%" textAlign="left">
                  Código
                </Th>
                <Th width="20%" textAlign="left">
                  Nome Fantasia
                </Th>
                <Th width="25%" textAlign="left">
                  Razão Social
                </Th>
                <Th width="15%" textAlign="left">
                  CNPJ
                </Th>
                <Th width="15%" textAlign="left">
                  Cidade
                </Th>
                <Th width="10%" textAlign="left">
                  Estado
                </Th>
                <Th width="5%" textAlign="center" />
              </tr>
            </Thead>
            <Tbody>
              {loading && (
                <tr>
                  <Td colSpan={4} padding="1rem" textAlign="center">
                    <PropagateLoader color="#276c8d" />
                  </Td>
                </tr>
              )}
              {clients?.map(client => (
                <TbodyTr key={client.code}>
                  <Td width="10%" textAlign="left" heading="CÓDIGO">
                    {client.code}
                  </Td>
                  <Td width="20%" textAlign="left" heading="NOME FANTASIA">
                    {client.name}
                  </Td>
                  <Td width="25%" textAlign="left" heading="RAZÃO SOCIAL">
                    {client.company_name}
                  </Td>
                  <Td width="15%" textAlign="left" heading="CNPJ">
                    {client.document}
                  </Td>
                  <Td width="15%" textAlign="left" heading="CIDADE">
                    {client.city}
                  </Td>
                  <Td width="10%" textAlign="left" heading="ESTADO">
                    {client.state}
                  </Td>
                  <Td
                    width="5%"
                    textAlign="center"
                    display="flex"
                    heading={' '}
                  >
                    <Link
                      to={{
                        pathname: `/projects/displays/procedure1/new`,
                        state: {
                          erp_client_code: client.code,
                          erp_client_name: client.name,
                          erp_client_company_name: client.company_name,
                          erp_client_document: client.document,
                          erp_client_address: client.address,
                          erp_client_city: client.city,
                          erp_client_state: client.state,
                          erp_rep_code:
                            state !== undefined
                              ? state.code
                              : user.erp_rep_code,
                          rep_as_client: false,
                        },
                      }}
                    >
                      <SelectButton />
                    </Link>
                  </Td>
                </TbodyTr>
              ))}
            </Tbody>
          </Table>
        );
      }
    }

    return (
      <Table>
        <Thead>
          <tr>
            <Th width="100%" textAlign="center" />
          </tr>
        </Thead>
        <Tbody>
          <TbodyTr>
            <Td width="100%" textAlign="center">
              Nenhuma informação disponível
            </Td>
          </TbodyTr>
        </Tbody>
      </Table>
    );
  }

  return (
    <Container style={{ minHeight: 600 }}>
      <BreadCrumb
        main="Solicitações"
        path="/projects/main"
        firstChildPath="/projects/displays"
        firstChild="Displays"
        secondChild="Nova Solicitação"
        mainIcon={FaProjectDiagram}
        firstChildIcon={FaBox}
        secondChildIcon={FaBox}
        mainColored
        firstChildColored
      />

      <FilterContent>
        <DefaultInput
          name="search"
          labelText="Pesquisar"
          type="text"
          onChange={event => handleFilterChange(event.target.value)}
        />
      </FilterContent>

      {handleTable()}

      <ButtonsContent>
        <Paginator>
          <PrevButton
            disabled={handlePrevButtonDisabled()}
            onClick={() => handlePage('backward')}
          />
          <CenterButton onClick={() => handlePage('reset')} />
          <NextButton
            disabled={nextDisabled}
            onClick={() => handlePage('forward')}
          />
        </Paginator>

        <Link
          to={{
            pathname: `/projects/displays/procedure1/new`,
            state: {
              erp_rep_code:
                state !== undefined ? state.code : user.erp_rep_code,
              rep_as_client: true,
            },
          }}
        >
          <NewButton
            label="Solicitação Representante"
            style={{ padding: '1.2rem 2rem', fontSize: '1.4rem' }}
          />
        </Link>
      </ButtonsContent>
    </Container>
  );
};

export default ListClients;
