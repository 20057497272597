import React from 'react';
import { Link } from 'react-router-dom';
import { FaArrowRight, FaCogs } from 'react-icons/fa';

import { getPermByName } from '~/utils';

import { useAuth } from '~/hooks/auth';

import BreadCrumb from '~/components/BreadCrumb';

import {
  AnimatedContainer,
  Configurations,
  ConfigurationCardWrapper,
  ConfigurationCard,
  ConfigurationIcon,
  ConfigurationName,
  GoIcon,
} from './styles';

import { CONFIGURATIONS_NAVIGATION_ANIMATION } from './animations';

import { CONFIGURATIONS_NAVIGATION } from '~/constants';

const MainContent: React.FC = () => {
  const { user } = useAuth();

  return (
    <AnimatedContainer variants={CONFIGURATIONS_NAVIGATION_ANIMATION}>
      <BreadCrumb
        main="Configurações"
        path="/configurations/main"
        mainIcon={FaCogs}
      />
      <Configurations>
        {Object.entries(CONFIGURATIONS_NAVIGATION).map(
          ([key, value]) =>
            getPermByName(value.perm, user) && (
              <ConfigurationCardWrapper key={`wrapper-${key}`}>
                <Link to={value.link}>
                  <ConfigurationCard
                    key={`card-${key}`}
                    backgroundColor="#747474"
                  >
                    <ConfigurationIcon>{value.icon}</ConfigurationIcon>

                    <ConfigurationName>{key}</ConfigurationName>

                    <GoIcon>
                      <FaArrowRight size={20} />
                    </GoIcon>
                  </ConfigurationCard>
                </Link>
              </ConfigurationCardWrapper>
            ),
        )}
      </Configurations>
    </AnimatedContainer>
  );
};

export default MainContent;
