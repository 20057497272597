import React, { useCallback, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useForm, Controller } from 'react-hook-form';
import { FaProjectDiagram, FaBox } from 'react-icons/fa';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { v4 as uuidv4 } from 'uuid';

import BreadCrumb from '~/components/BreadCrumb';
import ProjectInfo from '~/components/ProjectInfo/Default';
import Select from '~/components/Select';
import CurrencyInput from '~/components/Inputs/Currency';
import TextArea from '~/components/TextArea';
import UploadFiles from '~/components/UploadFiles';
import SaveButton from '~/components/Buttons/Save';
import ExitButton from '~/components/Buttons/Exit';
import ConfirmProcedureDialog from '~/components/Dialogs/ConfirmProcedure';
import ConfirmExitDialog from '~/components/Dialogs/ConfirmExit';

import { useAuth } from '~/hooks/auth';
import { useToast } from '~/hooks/toast';

import api from '~/services/api';

import {
  Container,
  Content,
  ContentSplit,
  Wrapper,
  ButtonContainer,
} from './styles';

interface IFormProcedure3 {
  type: string;
  code: string;
  price: number;
  shipping_price: number;
  validation_type: string;
  project_procedure_id: string;
  third_party_logistics_provider: {
    value: string;
  };
  record_description: string;
  record_note: string;
  record_reason: string;
  project_temp_id: string;
}

interface ILocation {
  id: string;
  type: string;
  code: string;
  price: number;
  shipping_price: number;
  erp_rep_code: string;
  status: string;
  project_procedure_id: string;
}

const schema = Yup.object().shape({
  third_party_logistics_provider: Yup.object().shape({
    value: Yup.string().required('Campo obrigatório'),
  }),
  shipping_price: Yup.string().required('Campo obrigatório'),
});

const Procedure3: React.FC = () => {
  const history = useHistory();
  const { user } = useAuth();
  const { addToast } = useToast();
  const { state } = useLocation<ILocation>();
  const [loading, setLoading] = useState<boolean>(true);
  const [thirdPartyLogisticsProviders, setThirdPartyLogisticsProviders] =
    useState<
      {
        id: string;
        name: string;
      }[]
    >();
  const [projectTempFilled, setProjectTempFilled] = useState(false);
  const [projectTemp, setProjectTemp] = useState('');
  const [isOpenConfirmProcedure, setIsOpenConfirmProcedure] = useState(false);
  const [titleConfirmProcedure, setTitleConfirmProcedure] = useState('');
  const [isOpenExit, setIsOpenExit] = useState(false);
  const [isSaving, setIsSaving] = useState(false);

  const { handleSubmit, register, getValues, setValue, control, errors } =
    useForm<IFormProcedure3>({ resolver: yupResolver(schema) });

  useEffect(() => {
    if (state === undefined) {
      addToast({
        type: 'error',
        title: 'Operação inválida!',
        description: 'Projeto não localizado',
      });

      history.push('/projects/displays');
    } else if (state.status.toUpperCase() === 'CANCELADO') {
      addToast({
        type: 'error',
        title: 'Operação inválida!',
        description: 'Projeto Cancelado',
      });

      history.push('/projects/displays');
    } else if (state.status.toUpperCase() === 'FINALIZADO') {
      addToast({
        type: 'error',
        title: 'Operação inválida!',
        description: 'Projeto Finalizado',
      });

      history.push('/projects/displays');
    } else {
      register('validation_type');
      register('record_reason');
      register('project_temp_id');
      register('type');
      register('code');
      register('record_description');
      register('price');
      register('project_procedure_id');

      setValue('type', 'DISPLAY');
      setValue('code', state.code);
      setValue('price', state.price);
      setValue('project_procedure_id', state.project_procedure_id);

      api
        .get('/configurations/thirdpartylogisticsproviders/valid')
        .then(response => {
          setThirdPartyLogisticsProviders(response.data);
          setLoading(false);
        });

      if (!projectTempFilled) {
        setProjectTempFilled(true);
        setProjectTemp(uuidv4());
      }
    }
  }, [addToast, history, state, user, projectTempFilled, register, setValue]);

  const options = thirdPartyLogisticsProviders?.map(
    thirdPartyLogisticsProvider => ({
      value: thirdPartyLogisticsProvider.id,
      label: thirdPartyLogisticsProvider.name,
    }),
  );

  useEffect(() => {
    setValue('project_temp_id', projectTemp);
  }, [projectTemp, setValue, register]);

  const submitForm = useCallback(
    async (data: IFormProcedure3) => {
      try {
        setIsSaving(true);

        await api.put(`/projects/${state.id}`, data);

        addToast({
          type: 'success',
          title: 'Projeto atualizado!',
          description: 'Projeto atualizado com sucesso.',
        });

        history.push('/projects/displays');
      } catch (error: any) {
        setIsSaving(false);

        addToast({
          type: 'error',
          title: 'Ocorreu um erro!',
          description: error.response.data.message,
        });
      }
    },
    [addToast, history, state],
  );

  function onConfirmProcedure(): void {
    const shippingPriceValue = getValues('shipping_price');

    let shippingPriceConv = shippingPriceValue.toString();
    shippingPriceConv = shippingPriceConv.replaceAll('R$', '');
    shippingPriceConv = shippingPriceConv.replaceAll('.', '');
    shippingPriceConv = shippingPriceConv.replaceAll(',', '.');

    setValue('shipping_price', parseFloat(shippingPriceConv));
    setValue('validation_type', 'APROVADO');
    setValue(
      'record_description',
      `Cotação de Frete no valor de ${shippingPriceValue}`,
    );

    handleSubmit(submitForm)();
  }

  const onExit = useCallback(() => {
    api.delete(`projectfilesreceived/projecttempid/${projectTemp}`);

    history.push('/projects/displays');
  }, [history, projectTemp]);

  function getShippingPrice(): string {
    const shippingPriceValue = getValues('shipping_price');

    let shippingPriceConv = shippingPriceValue.toString();
    shippingPriceConv = shippingPriceConv.replaceAll('R$', '');
    shippingPriceConv = shippingPriceConv.replaceAll('.', '');
    shippingPriceConv = shippingPriceConv.replaceAll(',', '.');

    return new Intl.NumberFormat('pt-BR', {
      style: 'currency',
      currency: 'BRL',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }).format(Number(shippingPriceConv));
  }

  return (
    <Container>
      <BreadCrumb
        main="Solicitações"
        path="/projects/main"
        firstChildPath="/projects/displays"
        firstChild="Displays"
        secondChild="Atualização Solicitação"
        mainIcon={FaProjectDiagram}
        firstChildIcon={FaBox}
        secondChildIcon={FaBox}
        mainColored
        firstChildColored
      />

      <ProjectInfo project_id={state.id} />

      <form onSubmit={handleSubmit(submitForm)}>
        <ContentSplit>
          <Wrapper style={{ width: '50%', marginRight: '1rem' }}>
            <Controller
              name="third_party_logistics_provider"
              control={control}
              options={options}
              label="Operador Logístico"
              error={errors?.third_party_logistics_provider?.value?.message}
              as={Select}
              loading={loading}
            />
          </Wrapper>

          <Wrapper style={{ width: '50%' }}>
            <CurrencyInput
              name="shipping_price"
              labelFor="shipping_price"
              labelText="Valor Frete"
              defaultValue={state?.shipping_price}
              error={errors?.shipping_price?.message}
              ref={register}
            />
          </Wrapper>
        </ContentSplit>

        <Content>
          <Wrapper style={{ width: '100%' }}>
            <TextArea
              name="record_note"
              labelFor="record_note"
              labelText="Observação"
              error={errors?.record_note?.message}
              ref={register}
              maxLength={1000}
            />
          </Wrapper>
        </Content>

        <Content>
          <UploadFiles
            type="anexo"
            project_temp_id={projectTemp}
            project_type="DISPLAY"
            sequence={3}
            user_id={user.id}
          />
        </Content>

        <ButtonContainer>
          <SaveButton
            type="button"
            processing={isSaving}
            disabled={isSaving}
            onClick={() => {
              setIsOpenConfirmProcedure(true);
              setTitleConfirmProcedure(
                `Confirma aprovação do projeto ${state.code}, ` +
                  `com inclusão de Frete no valor de ${getShippingPrice()}?`,
              );
            }}
          />

          <ExitButton
            style={{ marginLeft: 10 }}
            type="button"
            disabled={isSaving}
            onClick={() => setIsOpenExit(true)}
          />
        </ButtonContainer>
      </form>

      <ConfirmProcedureDialog
        isOpen={isOpenConfirmProcedure}
        setIsOpen={setIsOpenConfirmProcedure}
        onConfirm={onConfirmProcedure}
        title={titleConfirmProcedure}
        subTitle="O projeto será enviado para o próximo procedimento"
        opConfirm="Sim"
      />

      <ConfirmExitDialog
        isOpen={isOpenExit}
        setIsOpen={setIsOpenExit}
        onExit={onExit}
      />
    </Container>
  );
};

export default Procedure3;
