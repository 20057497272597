import React, { useCallback, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useForm, Controller } from 'react-hook-form';
import { FaCogs, FaMoneyCheckAlt } from 'react-icons/fa';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';

import BreadCrumb from '~/components/BreadCrumb';
import DefaultInput from '~/components/Inputs/Default';
import Toggle from '~/components/Toggle';
import SaveButton from '~/components/Buttons/Save';
import ExitButton from '~/components/Buttons/Exit';
import ConfirmProcedureDialog from '~/components/Dialogs/ConfirmProcedure';
import ConfirmExitDialog from '~/components/Dialogs/ConfirmExit';

import { useToast } from '~/hooks/toast';

import api from '~/services/api';

import {
  Container,
  Content,
  OneFourthWrapper,
  HalfWrapper,
  ButtonContainer,
} from './styles';

interface IFormEventCostType {
  name: string;
  active: boolean;
}

interface ILocation {
  id: string;
  name: string;
  active: boolean;
}

const schema = Yup.object().shape({
  name: Yup.string().required('Campo obrigatório'),
});

const New: React.FC = () => {
  const history = useHistory();
  const { addToast } = useToast();
  const { state } = useLocation<ILocation>();
  const [isOpenConfirmProcedure, setIsOpenConfirmProcedure] = useState(false);
  const [isOpenExit, setIsOpenExit] = useState(false);
  const [isSaving, setIsSaving] = useState(false);

  const { handleSubmit, register, control, errors } =
    useForm<IFormEventCostType>({
      resolver: yupResolver(schema),
      defaultValues: {
        active: state?.active,
      },
    });

  const submitForm = useCallback(
    async (data: IFormEventCostType) => {
      try {
        setIsSaving(true);

        if (state === undefined) {
          await api.post('/configurations/eventcosttypes', data);

          addToast({
            type: 'success',
            title: 'Cadastro realizado!',
            description: 'Tipo de custo de evento cadastrado com sucesso.',
          });
        } else {
          await api.put(`/configurations/eventcosttypes`, {
            id: state.id,
            ...data,
          });

          addToast({
            type: 'success',
            title: 'Cadastro atualizado!',
            description: 'Tipo de custo de evento atualizado com sucesso.',
          });
        }

        history.push('/configurations/eventcosttypes');
      } catch (error: any) {
        setIsSaving(false);

        addToast({
          type: 'error',
          title: 'Ocorreu um erro!',
          description: error.response.data.message,
        });
      }
    },
    [addToast, history, state],
  );

  const onExit = useCallback(() => {
    history.push('/configurations/eventcosttypes');
  }, [history]);

  return (
    <Container>
      <BreadCrumb
        main="Configurações"
        path="/configurations/main"
        firstChildPath="/configurations/eventcosttypes"
        firstChild="Tipos de Custos de Eventos"
        secondChild={
          state === undefined
            ? 'Novo Tipo de Custo de Evento'
            : 'Edição de Tipo de Custo de Evento'
        }
        mainIcon={FaCogs}
        firstChildIcon={FaMoneyCheckAlt}
        secondChildIcon={FaMoneyCheckAlt}
        mainColored
        firstChildColored
      />

      <form onSubmit={handleSubmit(submitForm)}>
        <Content style={{ maxWidth: 700 }}>
          <HalfWrapper>
            <DefaultInput
              name="name"
              labelFor="name"
              labelText="Nome"
              type="text"
              defaultValue={state?.name}
              error={errors?.name?.message}
              ref={register}
              maxLength={60}
            />
          </HalfWrapper>

          <OneFourthWrapper>
            <Controller
              render={props => (
                <Toggle
                  onChange={e => props.onChange(e)}
                  checked={props.value}
                />
              )}
              name="active"
              defaultValue
              control={control}
            />
          </OneFourthWrapper>
        </Content>

        <ButtonContainer>
          <SaveButton
            type="button"
            processing={isSaving}
            disabled={isSaving}
            onClick={() => {
              setIsOpenConfirmProcedure(true);
            }}
          />

          <ExitButton
            style={{ marginLeft: 10 }}
            type="button"
            disabled={isSaving}
            onClick={() => setIsOpenExit(true)}
          />
        </ButtonContainer>
      </form>

      <ConfirmProcedureDialog
        isOpen={isOpenConfirmProcedure}
        setIsOpen={setIsOpenConfirmProcedure}
        onConfirm={() => handleSubmit(submitForm)()}
        title="Confirma gravação do tipo de custo de evento?"
        subTitle=""
        opConfirm="Sim"
        type=""
      />

      <ConfirmExitDialog
        isOpen={isOpenExit}
        setIsOpen={setIsOpenExit}
        onExit={onExit}
      />
    </Container>
  );
};

export default New;
